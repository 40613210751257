import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocationService } from '../services/location.service';
import { cSessionService } from 'src/app/services/session.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-locations-list',
    templateUrl: './locations-list.component.html',
    styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent implements OnInit, AfterViewInit {
    @ViewChild("locationListAutoComplete", { read: MatAutocompleteTrigger })
    autoCompleteLocation: MatAutocompleteTrigger;
    @ViewChild('searchLocationField') searchLocationField: ElementRef;
    modelLocationSearchChanged = new BehaviorSubject<string>('');
    locationSearchList: any = [];
    isLocationSearchLoading: boolean = false;
    searchQuery: any;
    @Input() isLocalizeVideo: boolean = false;
    @Output() lEmittedLocations = new EventEmitter<{ lSelectedLocations: any, AllLocaleSelected: boolean }>();
    defaultLocationFormField = undefined;
    sSessionID: string = '';
    sUserEmail: string = '';
    oTotalCount: any;
    num_items: number = 20;
    num_skip = 0;
    currentPage = 1;
    listPages: number[] = [];
    lLocalesGroup: any = [];
    order_by = 'name';
    sSelectedSort_by = 'Name';
    sort_type = -1;
    lSelectedLocations: any = [];
    totalSelectedLocals: any = [];
    public AllLocaleSelected: boolean = false;
    public title: string = "";
    search_term: string = "";
    sSelectedGroup: string = 'Year Created';
    sSelectedGroupValue: string = 'year_created';
    lSortBy: any = [
        { name: 'Name', value: 'name' },
        { name: 'Date Created', value: 'created' },
        { name: "Date Modified", value: "date_modified" },
    ];
    lGroupBy: any = [
        { name: 'Month Created', value: 'month_created' },
        { name: 'Year Created', value: 'year_created' },
        { name: 'Month Modified', value: 'month_last_modified' },
        { name: 'Year Modified', value: 'year_last_modified' },
    ];
    constructor(
        private oSessionService: cSessionService,
        public loader: LoaderService,
        private locationService: LocationService,) { }
    ngAfterViewInit(): void {
        console.log("chk trigger");

        this.modelLocationSearchChanged
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(async (value) => {
                if (value && value != "" && value.length > 1) {
                    console.log("chk trigger value", value);
                    this.locationSearchList = await this.locationService
                        .search_locales_ac(this.sUserEmail, value)
                        .then((resList) => {
                            return (this.locationSearchList = resList);
                        });

                    this.isLocationSearchLoading = false;
                    return this.locationSearchList;
                } else {
                    return [];
                }
            });
        // throw new Error('Method not implemented.');
    }
    loadPage(page: number) {
        console.log('pagee', page, this.listPages.length);

        if (page < 1) {
            page = 1;
        } else {
            if (page > this.listPages.length) {
                page = 1;
            }
            this.lLocalesGroup = [];
            this.currentPage = page;
            this.num_skip = page == 1 ? 0 : (page - 1) * this.num_items - 1;
            console.log('nume_sktip', page, this.num_skip, this.num_items);

            this.reloadLocals();
        }
    }
    populatePagination() {
        if (this.oTotalCount && this.oTotalCount > 0) {
            const maxPage = Math.ceil(this.oTotalCount / this.num_items) || 1;
            if (maxPage > 1) {
                this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);
            } else {
                this.listPages = [1];
            }
        }
    }

    ngOnInit(): void {
        this.sSessionID = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        if (!(this.lLocalesGroup.length > 0)) {

            this.locationService.getLocationOptions().then((res) => {
                this.defaultLocationFormField = res;
            });
            const data = {
                search_by: 'name',
                search_term: this.search_term,
                offset: this.num_skip,
                limit: this.num_items,
                sort_by: this.sort_type == -1 ? this.order_by : this.order_by,
                group_by: this.sSelectedGroupValue,
                asc: this.sort_type,
                email: this.sUserEmail,
            };
            this.locationService.getlocations(data).then((res: any) => {
                let response = res["result"];
                if (response && typeof response === 'object') {
                    this.lLocalesGroup = Object.keys(response).map(key => ({
                        key: key,
                        locations: response[key]
                    }));
                } else {
                    this.lLocalesGroup = [];
                }
                if (this.lLocalesGroup.length > 0) {
                    this.lLocalesGroup[0].active = true;
                }

                this.oTotalCount = res['total_count'] ? res['total_count'] : 0;
                this.populatePagination();
            });
        }
    }
    CreateVideoOverviewComponent_SelectedLocation(event, mainItem) {
        this.AllLocaleSelected = false;
        let oData = Object.assign({}, mainItem);
        let nameField = oData.name ? oData.name : '';
        if (event.target.checked) {
            // Pushing the object into array
            oData.id = oData._id.$oid ? oData._id.$oid : oData._id;
            // console.log(' Checking entries ');
            let localeData = Object.entries(this.defaultLocationFormField);
            let objList: any[] = undefined;
            // console.log(" -- select location ..", localeData);
            localeData.map((itemObj) => {
                let item = itemObj[1];
                if (item['parent'] && item['parent'] != '') {
                    if (objList) {
                        let fildFil = objList.filter((fil) => {
                            return fil.name == item['parent'];
                        });
                        if (fildFil.length > 0) {

                            fildFil[0].data[item['name']] = item['value'];
                            // console.log(" item['name'] ", item['name'], " - " , item['value']);
                        }
                    } else {
                        let nameKey = item['name'];
                        objList = [];
                        objList.push({ name: item['parent'], data: { name: item['value'] } });
                    }
                }
            });

            let oldoData = Object.entries(oData);
            oldoData.map((itemObj) => {
                let keyN = itemObj[0];
                let item = itemObj[1];
                if (item && typeof item != 'object') {
                    if (objList) {
                        objList.map((fil) => {
                            if (fil.data && fil.data[keyN] != undefined) {
                                fil.data[itemObj[0]] = item;
                            }
                        });
                    }
                }
            });

            if (objList) {
                objList.map((fil) => {
                    Object.entries(fil.data).map((item) => {
                        let key = item[0];
                        delete oData[key];
                    });
                });
            }
            if (objList) {
                objList.map((fil) => {
                    oData[fil.name] = fil.data;
                });
            }

            if (oData['_id']) {
                oData['_id'] = oData.id;
            }

            if (!oData.name) {
                oData['name'] = nameField;
            }
            let newLocale = oData;
            if (newLocale['voiceover'] && (!newLocale['voiceover'].value || newLocale['voiceover'].value == '')) {
                delete newLocale['voiceover'];
            }
            if (newLocale['logo'] != undefined && newLocale['logo'] == '') {

                delete newLocale['logo'];
            }
            delete newLocale['created'];
            delete newLocale['updated'];
            this.lSelectedLocations.push(newLocale);
            // console.log( "  location pushed ", oData);
            // this.lSelectedLocations.push(oData);
        } else {
            let idSelected = oData._id.$oid ? oData._id.$oid : oData._id;
            let removeIndex = this.lSelectedLocations.findIndex((itm) => itm._id === idSelected);

            if (removeIndex !== -1) this.lSelectedLocations.splice(removeIndex, 1);
            if (this.lSelectedLocations.length == 0) {
                this.totalSelectedLocals = [];
            }
        }
        if (this.isLocalizeVideo == true) {
            this.passSelectedLocale();
        }
    }

    CreateVideoOverViewComponent_SelectAllLocations(event) {
        if (event.target.checked) {
            this.AllLocaleSelected = true;
            this.lSelectedLocations = [];

            this.lLocalesGroup.map((lLocales) => {
                lLocales.locations.forEach((e) => {
                    e.selected = true;
                    e.id = e._id && e._id.$oid ? e._id.$oid : e.id;
                    // console.log('  E :: ', e);

                    this.lSelectedLocations.push(e);
                });
            });


            this.totalSelectedLocals = this.oTotalCount;
        } else {
            this.lSelectedLocations = [];
            this.AllLocaleSelected = false;
            this.lLocalesGroup.map((lLocales) => {
                lLocales.locations.forEach((e) => {
                    e.selected = false;
                });
            });

            this.totalSelectedLocals = [];
        }
        if (this.isLocalizeVideo == true) {
            this.passSelectedLocale();
        }
    }
    loadPageEvent($event) {
        // console.log('event', $event);

        if ($event.pageSize != this.num_items) {
            this.num_items = $event.pageSize;
            this.loadPage(1);
        } else {
            this.loadPage($event.pageIndex + 1);
        }
    }
    reloadLocals() {
        const data = {
            search_by: 'name',
            search_term: this.search_term,
            offset: this.num_skip,
            limit: this.num_items,
            sort_by: this.sort_type == -1 ? this.order_by : this.order_by,
            group_by: this.sSelectedGroupValue,
            asc: this.sort_type,
            email: this.sUserEmail,
        };

        this.locationService.getlocations(data).then((res: any) => {
            let response = res["result"];
            if (response && typeof response === 'object') {
                this.lLocalesGroup = Object.keys(response).map(key => ({
                    key: key,
                    locations: response[key]
                }));
            } else {
                this.lLocalesGroup = [];
            }
            if (this.lLocalesGroup.length > 0) {
                this.lLocalesGroup[0].active = true;
            }

            this.oTotalCount = res['total_count'] ? res['total_count'] : 0;
            this.lLocalesGroup.map((lLocales) => {
                lLocales.locations.forEach((ele) => {
                    if (this.AllLocaleSelected) {
                        ele.selected = true;
                    } else {
                        this.lSelectedLocations.forEach((ee) => {
                            if (ele.id == ee.id) {
                                ele.selected = true;
                            }
                        });
                    }
                });
            });

        });
    }

    passSelectedLocale() {
        this.lEmittedLocations.emit({ lSelectedLocations: this.lSelectedLocations, AllLocaleSelected: this.AllLocaleSelected });

    } sortBySearchResult(sortingValue) {
        if (this.sSelectedSort_by != sortingValue.name) {
            this.sSelectedSort_by = sortingValue.name;
            this.order_by = sortingValue.value;
            this.num_skip = 0;
            this.reloadLocals();
        }
    }
    groupBySearchResult(groupingValue) {
        if (this.sSelectedGroup != groupingValue.name) {
            this.sSelectedGroup = groupingValue.name;
            this.sSelectedGroupValue = groupingValue.value;
            this.num_skip,
                this.reloadLocals();
        }
    }
    sortTypeSearch(val: number) {
        this.sort_type = val;
        this.num_skip = 0;
        this.reloadLocals();
    }
    clearSearchTerm() {
        this.search_term = "";
        this.title = "";
        this.reloadLocals();
    }
    SearchLocation(loc) {
        // this.title = this.title.replace(/^\s+|\s+$/g, '');
        console.log("chk title", this.title);

        this.search_term = loc.name;
        this.reloadLocals();
    }
    floatLabel(): 'auto' | 'always' | 'never' {
        // You can conditionally return 'auto', 'always', or 'never' here
        return 'auto';  // Default: it floats when the input has focus or value
    }
}
