import { aspectRatio } from './../../models/template';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    HostListener,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, Params } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CreateVideoManagerService } from './../../Managers/create-video-manager.service';
import { VideoManagerService } from '../../Managers/video-manager.service';
import { video } from '../../models/video';
import { cDataService } from '../../services/data.service';
import { cSessionService } from '../../services/session.service';

import { ErrorService } from './../../services/error.service';
import { LoaderService } from 'src/app/services/loader.service';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { SubscriptionplanManagerService } from 'src/app/Managers/subscriptionplan-manager.service';
import { ConfigManagerService } from 'src/app/Managers/config-manager.service';
import { DialogPosition, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AspectRatioPopupComponent } from '../components/aspect-ratio-popup/aspect-ratio-popup.component';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { SuccessMessageComponent } from '../components/success-message/success-message.component';
import { SuccessMessageModalService } from 'src/app/services/success-message-modal.service';
import { VideoService } from 'src/app/services/dataServices/video.service';
import { VideoDataStore } from 'src/app/stores/video.store';
import { environment } from 'src/environments/environment';
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'created-videos',
    templateUrl: './created-videos.component.html',
    styleUrls: ['./created-videos.component.scss'],
})
export class CreatedVideosComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("videoListAutoComplete", { read: MatAutocompleteTrigger })
    autoCompleteVideo: MatAutocompleteTrigger;
    @ViewChild('searchVideoField')  searchVideoField: ElementRef;
    modelVideoSearchChanged = new BehaviorSubject<string>('');
    videoSearchList: any = [];
    isVideoSearchLoading: boolean = false;
    searchQuery : any;
    @Input() sInputVideoStatus: string;
    @Input() status: string;
    @Input() thumbsPerLine: number = 4;
    @Input() isLocations: boolean = false;
    @Input() title: string;
    @Input() displayCountSection: boolean = true;
    @Input() dashboardPublished: boolean = false;
    @Input() myaccountPublished: boolean = false;
    @Input() numGridCol: number = 4;
    @ViewChild('videoListContainer') videoListContainer: ElementRef;

    @Output() bEmittedDisplayLoader = new EventEmitter<boolean>();
    lPublishedVideos: any = [];

    public videoList: video[] = [];
    videoListForm: FormGroup;
    lVideoList: any = [];
    sSessionId: string;
    sUserEmail: string;
    orderBySearch = 'created';
    sortType = -1;
    selectedCreatedBy = ''; 
    selectedCreatedByValue = ''; 
    sSelectedVideoId: string;
    bDisplayCreateVideoSection: boolean = false;
    lTemplateCategory: any = [];
    lTemplateNames: any = [];
    dummyArray: any = [];
    bAlert: boolean = false;
    oSetTime: any;
    bIsPublish: boolean = false;
    sSelectedSortingCategory: string = 'Date Created';
    sSelectedSortingCategoryValue: string = 'created';
    sSelectedGroup: string = 'Month Created';
    sSelectedGroupValue: string = 'month_created';
    lSortBy: any = [
        { name: 'Date Created', value: 'created' },
        { name: "Last Modified", value: "last_modified" },
        { name: 'Name', value: 'video.title' },
        { name: 'Aspect Ratio', value: 'video.aspect_ratio' },
        { name: 'FPS', value: 'video.fps' },
        { name: 'Duration', value: 'video.duration' },
    ];
    lCreatedBy: any = [
        // { name: "Last Modified", value: "last_modified" },
        { name: 'Today', value: 'today' },
        { name: 'Last 7 Days', value: 'lastSevenDays' },
        { name: 'Last Month', value: 'lastMonth' },
        { name: 'This Year', value: 'thisyear' },
        { name: 'Last Year', value: 'lastyear' },
    ];
    selectedVideoList: any = [
        { name: 'All', value: 'all' },
        { name: 'None', value: 'none' },
    ];
    lGroupBy: any = [
        { name: 'Month Created', value: 'month_created' },
        { name: 'Year Created', value: 'year_created' },
        { name: 'Month Modified', value: 'month_last_modified' },
        { name: 'Year Modified', value: 'year_last_modified' },
        { name: 'Aspect Ratio', value: 'aspect_ratio' },
        // { name: 'Drafts', value: 'publish_status' },
        // { name: 'Published', value: 'publish_status' },
    ];
    sSelectVideoOption : any
    sSelectVideoIds : any = [];
    sSelectedVideosForDownload : any = [];
    batchPopup = false;
    isAllowDownload = false;
    isAllowDelete = false;
    isPopupHeading : string ;
    isPopupText : string ;


    oSearchVideoSubscription: any;
    bPublishedEmptyScreenView: boolean = false;
    customGridLayout: string;
    addCustomClass: string;
    thumbListView: boolean = false;
    showHeaderMenu = true;
    duplicatePopup = false;
    isSearchboxOpen = false;
    fSearchVideo: FormGroup;

    session: cSessionService;
    totalGridColumn = 0;
    widthThumbs = 100;
    heightThumbs = 100;
    videoContainerHeight = 500;
    isDataLoaded = false;
    searchAspectRatio = '';
    headerHeight = 180;
    videoAspectRatio = [
        { title: 'All', ratio: 'all', ratio_value: 'all', isSelected: true, class_name: 'all' },
        { title: 'Landscape', ratio: '16:9', ratio_value: '16x9', isSelected: false, class_name: 'Landscape' },
        { title: 'Square', ratio_value: '1x1', ratio: '1:1', isSelected: false, class_name: 'square' },
        { title: 'Story/Reels', ratio_value: '9x16', ratio: '9:16', isSelected: false, class_name: 'reel' },
        { title: 'Portrait', ratio: '4:5', ratio_value: '4x5', isSelected: false, class_name: 'portrait' },
    ];
    louderOrg = false;
    constructor(
        public videoManager: VideoManagerService,
        public createVideoManger: CreateVideoManagerService,
        public oSessionService: cSessionService,
        public ConfigManager: ConfigManagerService,
        private oFormBuilder: FormBuilder,
        private oRouter: Router,
        public loader: LoaderService,
        private oDataService: cDataService,
        public errorService: ErrorService,
        public onboardingManager: OnboardingManagerService,
        public subscriptionPlan: SubscriptionplanManagerService,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        public pronto: ProntoSettingService,
        private successService: SuccessMessageModalService,
        private videoService: VideoService,
        private videoStore : VideoDataStore
    ) {
        this.sSelectedVideoId = undefined;
        this.session = oSessionService;
        if (this.oRouter.url === '/dashboard/myvideos') {
            this.bPublishedEmptyScreenView = true;
            this.addCustomClass = 'custom-empty-state';
        }
        if (this.oRouter.url === '/account-setting/published-videos') {
            this.customGridLayout = 'custom-grid-list-view';
        } else {
            this.customGridLayout = '';
        }
    }

    @Input() set aspectRatio(value: string) {
        if (value !== undefined) {
            this.searchAspectRatio = value == 'all' ? '' : value;
            this.fetchVideos(0);
        }
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.resizeThumbs();
    }

    searchTermText = '';
    search: string = '';
    sub: Subscription;

    resizeThumbs() {
        let containerVideo = document.querySelectorAll('.accountSecInner');
        let wtermarklogo = document.querySelectorAll('.wtermarklogo');

        let containerVideoHeader = document.querySelectorAll('.video-header');
        if (containerVideo && containerVideo.length > 0 && containerVideoHeader && containerVideoHeader.length > 0) {
            let heightParent = containerVideo[0].getBoundingClientRect().height;
            let diffTop = wtermarklogo[0].getBoundingClientRect().top - containerVideo[0].getBoundingClientRect().top - this.headerHeight;
            this.videoContainerHeight = diffTop;
        }
        let marginPanel = 7;
        if (this.numGridCol < 5) {
            marginPanel = 9;
        }
        if (!this.session.dashboardVideoListView) {
            let widthSideBar = document.querySelector('#content');
            let windowWidth = window.innerWidth;

            if (widthSideBar) {
                windowWidth = widthSideBar.clientWidth;
            } else {
                let widthPageContainer = document.querySelectorAll('.accountSecContent');
                if (widthPageContainer && widthPageContainer.length > 0) {
                    windowWidth = widthPageContainer[0].clientWidth + 20;
                }
            }
            this.widthThumbs = windowWidth / this.numGridCol - this.numGridCol * marginPanel;
            this.heightThumbs = (this.widthThumbs * 56) / 100;
            if (this.heightThumbs < 200) {
                this.heightThumbs = 200;
            }
        } else {
            this.heightThumbs = 67;
        }
    }
    ngAfterViewInit(): void {
        console.log("chk trigger");
        
      this.modelVideoSearchChanged
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe(async (value) => {
          if (value && value != "" && value.length > 1) {
            console.log("chk trigger value", value);
            this.videoSearchList = await this.videoService
              .uv_search_ac(this.sUserEmail, value, this.isLocations? true : false)
              .then((resList) => {
                return (this.videoSearchList = resList);
              });
  
            this.isVideoSearchLoading = false;
            return this.videoSearchList;
          } else {
            return [];
          }
        });
    }
  
    ngOnInit(): void {
        this.checkLouderOrgStatus();
        if (this.onboardingManager.profile?.user_status && this.onboardingManager.profile.user_status == 'confirmed_not_subscribed') {
            this.showHeaderMenu = false;
        }
        this.search = '';
        this.sub = this.videoManager.search.subscribe((search) => {
            this.search = search;
            this.searchVideo(search, 20, 0);
        });
        this.session.dashboardVideoGridView = true;
        this.session.dashboardVideoListView = false;
        // this.InitializeJqueryCode()
        //this.oWebSocketService.WebSocket_CreateWebSocket();
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

        //this.getvideoCount();
        this.fetchVideos(0);

        this.fSearchVideo = this.oFormBuilder.group({ title: [''] });
        this.resizeThumbs();
        this.pronto.videoDataView.subscribe((val) => {
            this.resizeThumbs();
        });
    }
    widthLimit = 280;
    heightLimit = 148;

    itemNewWidth = this.widthLimit;
    itemNewHeight = this.heightLimit;
    resetVideoThums() {
        // if(this.numGridCol){
        //   // console.log("resize function called...", this.videoListContainer.nativeElement);
        //   let widthContainer = 0;
        //   let widthContainerItem = 0;
        //   let diffPadding = 30;
        //   let showNumColumns = this.numGridCol;
        //   if(showNumColumns == 5 ){
        //     diffPadding = 40;
        //   }
        //   if(this.videoListContainer.nativeElement){
        //     widthContainer = (this.videoListContainer.nativeElement.offsetWidth - diffPadding) ;
        //     if( widthContainer < 1000){
        //       showNumColumns = 4;
        //     }
        //     if( widthContainer < 700){
        //       showNumColumns = 3;
        //     }
        //     if( widthContainer < 470){
        //       showNumColumns = 2;
        //     }
        //     widthContainerItem = (widthContainer) / showNumColumns;
        //   }
        //   if(widthContainerItem > 280 ) {
        //     let diffW = ((widthContainerItem - this.widthLimit) / this.widthLimit ) * 100;
        //     this.itemNewWidth = this.widthLimit + (this.widthLimit * diffW ) / 100;
        //     this.itemNewHeight = this.heightLimit + (this.heightLimit * diffW ) / 100;
        //   } else {
        //     let diffW = ((this.widthLimit - widthContainerItem) / widthContainerItem ) * 100;
        //     this.itemNewWidth = this.widthLimit - (this.widthLimit * diffW ) / 100;
        //     this.itemNewHeight = this.heightLimit - (this.heightLimit * diffW ) / 100;
        //   }
        //   if( this.itemNewWidth > 200){
        //     this.itemNewWidth = this.itemNewWidth - 20;
        //   }
        // }
    }
    // @HostListener('window:resize', ['$event'])
    //   sizeChange(event) {
    //     // console.log('size changed.', event);
    //     this.resetVideoThums();
    // }

    calculateRatio(num_1, num_2) {
        for (let num = num_2; num > 1; num--) {
            if (num_1 % num == 0 && num_2 % num == 0) {
                num_1 = num_1 / num;
                num_2 = num_2 / num;
            }
        }
        var ratio = num_1 + ':' + num_2;
        return { first: num_1, second: num_2 };
    }

    @Input() set orderBySelected(value: string) {
        if (value != undefined && value !== '') {
            this.orderBySearch = value.indexOf('name') > -1 ? 'video.title' : value;
            if (this.sSessionId && this.sSessionId != '' && this.sUserEmail && this.sUserEmail != '') {
                this.fetchVideos(0);
                this.deselectAll();
                this.sSelectVideoOption = 'none';
            }
        }
    }
    @Input() set groupBySelected(value: string) {
        console.log("chk group by from dashboard", value);
        
        if (value != undefined && value !== '') {
            this.sSelectedGroupValue = value;
            if (this.sSessionId && this.sSessionId != '' && this.sUserEmail && this.sUserEmail != '') {
                this.fetchVideos(0);
                this.deselectAll();
                this.sSelectVideoOption = 'none';
            }
        }
    }
    videoCount = 0;
    getvideoCount() {
        this.videoManager
            .getUserVideoCount(this.sUserEmail, this.sSessionId)
            .then((res) => {
                if (this.title.toLowerCase() == 'published') {
                    this.videoCount = res['published_video_count'];
                } else if (this.title.toLowerCase() == 'draft') {
                    this.videoCount = res['draft_video_count'];
                } else if (this.title.toLowerCase() == 'scheduled') {
                    this.videoCount = res['scheduled_video_count'];
                } else if (this.title.toLowerCase() == 'location') {
                } else {
                    this.videoCount = res['scheduled_video_count'] + res['scheduled_video_count'] + res['published_video_count'];
                }
                this.createPagination();
            })
            .catch((err) => {
                this.errorService.handleError(err.errorMessage, err.errorType, true);
            });
    }

    private fetchVideos(num_skip: number = 0) {
        if(!this.sUserEmail || this.sUserEmail == ''){
            return;
        }
        this.sSelectedVideoId = undefined;
        this.isDataLoaded = false;
        this.videoStore.clearGenerationInterval = true;
        // console.log(" Reset clear this.videoStore.clearGenerationInterval... ", this.videoStore.clearGenerationInterval);
        // console.log(this.isLocations);
        const searchType = this.status ? this.status : this.sInputVideoStatus;
        this.videoManager
            .getUserVideos(
                this.sUserEmail,
                this.sSessionId,
                searchType?.split(','),
                // (this.orderBySearch == 'created') ? -1 : 1,
                this.sortType,
                this.nPageSize,
                num_skip,
                this.orderBySearch,
                this.searchTermText,
                'video.title',
                this.sSelectedGroupValue,
                this.isLocations,
                { aspect_ratio: this.searchAspectRatio },
            )
            .then((res) => {
                console.log("video list", res);
                
                this.isDataLoaded = true;
                // console.log("call resize function");
                this.resetVideoThums();
                this.bPublishedEmptyScreenView = false;

                this.videoCount = res['total_count'];
                this.createPagination();
                this.checkVideoGenerationStatus();

            })
            .catch((err) => {
                console.log("video list error", err);
                this.isDataLoaded = true;
                this.errorService.handleError(err.errorMessage, err.errorType, true);
            });
    }

    private searchVideo(search, num_items: number = 20, num_skip: number = 0, order_by = 'created') {
        this.searchTermText = search;
        this.fetchVideos(0);
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        //  this.oSearchVideoSubscription.unsubscribe();
    }

    checkVideoGenerationStatus(){
        this.videoStore.clearGenerationInterval = false;
        let inProgressVideos = this.videoManager.videos.filter( (item : any) => {
            if( item.status == 'in-progress' || item.status == 'waiting') {
                return item;
            }
        });
        inProgressVideos.map( (item : any) => {
            this.videoService.checkVideoStatus(this.sUserEmail, this.sSessionId, item.fpath, this.videoManager.videos);
        });
    }
    CreatedVideosComponent_SortVideos(sortingValue: any) {
        if (this.sSelectedSortingCategory != sortingValue.name) {
            this.sSelectedSortingCategory = sortingValue.name;
            this.sSelectedSortingCategoryValue = sortingValue.value;

            this.orderBySearch = sortingValue.value == 'name' ? '_id' : sortingValue.value;
            this.fetchVideos(0);
            this.deselectAll();
            this.sSelectVideoOption = 'none';
        }
    }
    CreatedVideosComponent_GroupVideos(groupingValue: any) {
        if (this.sSelectedGroup != groupingValue.name) {
            this.sSelectedGroup = groupingValue.name;
            this.sSelectedGroupValue = groupingValue.value;
            this.fetchVideos(0);
            this.deselectAll();
            this.sSelectVideoOption = 'none';
        }
    }
    // CreatedVideosComponent_PlayVideo(videoItem) {
    //     videoItem.show_player = true;
    //     this.sSelectedVideoId = videoItem.fpath;
    //     let __this = this;
    //     setTimeout(() => {
    //         __this.videoManager.videos.forEach((element) => {
    //             var video = <HTMLVideoElement>document.getElementById(element.fpath);
    //             const exists = this.dummyArray.some(item => item === __this.sSelectedVideoId);
    //             if ((element.fpath == __this.sSelectedVideoId) && !exists) {
    //                     this.getStreamVideo(element,element.fpath);
    //                     this.dummyArray.push(element.fpath);
    //             }
    //             else if(element.fpath == __this.sSelectedVideoId && exists){
    //                 video.play();
    //             }
    //             else{
    //                 video.pause();
    //             }
    //         });
    //     }, 100);

    // }

                CreatedVideosComponent_PlayVideo(videoItem) {
                videoItem.show_player = true;
                this.sSelectedVideoId = videoItem.fpath;
                let __this = this;
                setTimeout(() => {
                __this.videoManager.videos.forEach((element) => {
                var video = <HTMLVideoElement>document.getElementById(element.fpath);
                if (element.fpath == __this.sSelectedVideoId) {
                    video.play();
                }
                });
                }, 100);

                }

    editVideo(video: video) {
        this.videoManager.selectedVideo = video;
        // this.createVideoManger.setEditVideo(
        //   this.sUserEmail,
        //   this.sSessionId,
        //   this.videoManager.selectedVideo
        // );

        this.createVideoManger.selectedVideo = video;

        this.createVideoManger.isEditMode = true;
        this.createVideoManger.isFirstTime = false;

        // let result = video.hasOwnProperty('sap_video_id');

        // console.log("vid edit", video);
        if (video.sap_video_id && video.sap_video_id != '') {
            // this.oRouter.navigate(['mv/overview']);
            this.oRouter.navigate(['mv/timeline-view']);
        } else {
            this.oRouter.navigate(['wizard/timeline-view']);
            // this.oRouter.navigate(['wizard/overview']);
        }
    }
    nCurrentPage: number = 1;
    lPagination: number[] = [];
    nPageSize: number = 20;
    loadPage(pageNumber: number) {
        if (pageNumber !== this.nCurrentPage) {
            this.nCurrentPage = pageNumber;
            let skipVideos = (pageNumber - 1) * this.nPageSize;
            this.fetchVideos(skipVideos);
            this.deselectAll();
            this.sSelectVideoOption = 'none';
        }
    }

    // private getVideos(skipVideos: number) {
    //     // console.log("GET VIDEOS BY SEARCH ....", this.search);
    //     if (this.search == '') {
    //         this.videoManager
    //             .getVideos(
    //                 this.sUserEmail,
    //                 this.sSessionId,
    //                 this.isLocations,
    //                 this.sInputVideoStatus,
    //                 this.sSelectedSortingCategory,
    //                 this.nPageSize,
    //                 skipVideos,
    //                 `-${this.sSelectedSortingCategoryValue}`,
    //             )
    //             .then((res) => {
    //                 this.videoCount = res['count'];
    //                 this.createPagination();
    //             })
    //             .catch((err) => {
    //                 this.errorService.handleError(err.errorMessage, err.errorType, true);
    //             });
    //     } else {
    //         this.videoManager
    //             .searchVideos(
    //                 this.sUserEmail,
    //                 this.sSessionId,
    //                 this.search,
    //                 this.isLocations,
    //                 this.sInputVideoStatus,
    //                 this.sSelectedSortingCategory,
    //                 this.nPageSize,
    //                 skipVideos,
    //                 this.orderBySearch,
    //             )
    //             .then((res) => {
    //                 this.videoCount = res['count'];
    //                 this.createPagination();
    //             })
    //             .catch((err) => {
    //                 this.errorService.handleError(err.errorMessage, err.errorType, true);
    //             });
    //     }
    // }

    createPagination() {
        this.lPagination = [];
        let pages = Math.ceil(this.videoCount / this.nPageSize);
        for (let nPaginationIndex = 1; nPaginationIndex <= pages; nPaginationIndex++) {
            this.lPagination.push(nPaginationIndex);
        }
        this.resizeThumbs();
        // console.log("Pagination List : ==> ",this.lPagination);
    }

    navigateToCreateVideo() {
        this.videoManager.selectedVideo = undefined;
        this.createVideoManger.newVideo();
        this.oRouter.navigate(['wizard/overview']);
    }
    navigateToWebPlayer(video: video){
        this.oRouter.navigate(['/webplayer/'], {queryParams : {id : video.fpath} });
    }
    navigateToOverview(video: video) {
        //console.log("QUERY PARAMS ", video.fpath, {id: video.fpath});

         //this.oRouter.navigate(['/dashboard/video/'], {state : {id : video.fpath} });

        var encodedFpath = encodeURIComponent(video.fpath);
        this.videoManager
            .getVideoById(this.sUserEmail, this.sSessionId, encodedFpath)
            .then((res) => {
                // console.log("get Video response ", res);
                this.videoManager.selectedVideo = res;

                this.videoManager.selectedVideo.video_url = video.video_url;
                this.videoManager.selectedVideo.thumbnail = video.thumbnail;
                // console.log("Selected Video ", this.videoManager.selectedVideo);
                this.createVideoManger.selectedVideo = this.videoManager.selectedVideo;
                // this.createVideoManger.setEditVideo(
                //   this.sUserEmail,
                //   this.sSessionId,
                //   this.videoManager.selectedVideo
                // );
                this.oSessionService.cSessionService_SetVideoId(this.videoManager?.selectedVideo?.fpath);
                this.pronto.previousUrl = this.oRouter.url;
                this.oRouter.navigate(['/dashboard/overview']);
            })
            .catch(() => {
                this.loader.hideLoader();
            });
    }
    loadVideoDataAndRedirect(video: video) {
        this.loader.showLoader();
        // console.log(video.fpath);
        this.videoManager
            .getVideoById(this.sUserEmail, this.sSessionId, video.fpath)
            .then((res: any) => {
                // console.log("******get Video information ... ", res);
                this.createVideoManger.isEditMode = true;
                this.createVideoManger.isFirstTime = false;
                this.videoManager.selectedVideo = res;
                this.videoManager.selectedVideo.video_url = video.video_url;
                this.videoManager.selectedVideo.thumbnail = video.thumbnail;
                this.createVideoManger.selectedVideo = this.videoManager.selectedVideo;

                // console.log("Selected Video ", this.createVideoManger.isEditMode,  this.videoManager.selectedVideo);

                // localStorage.setItem('lsVideoId', video._id);
                // this.createVideoManger.setEditVideo(
                //   this.sUserEmail,
                //   this.sSessionId,
                //   this.videoManager.selectedVideo
                // );
                setTimeout(() => {
                    this.loader.hideLoader();
                    if (this.videoManager.selectedVideo.sap_video_id && this.videoManager.selectedVideo.sap_video_id != '') {
                        this.createVideoManger.isEditMode = true;
                        // console.log(this.createVideoManger);
                        this.oRouter.navigate(['mv/timeline-view']);
                    } else {
                        this.oRouter.navigate(['wizard/timeline-view']);
                    }
                }, 1000);
            })
            .catch(() => {
                this.loader.hideLoader();
            });
    }

    navigateToShare(video: video) {
        this.videoManager.selectedVideo = video;
        this.oRouter.navigate(['/dashboard/socialmedia']);
    }

    selectedDuplicateVideo: any;
    selectDuplicateVideo(video: any) {
        if (!this.showHeaderMenu) {
            this.subscriptionPlan.showPlanDialogue();
        } else {
            this.duplicatePopup = true;
            this.selectedDuplicateVideo = video;
        }
    }

    handleCancel(): void {
        // console.log('Button cancel clicked!');
        this.duplicatePopup = false;
    }
    duplicateVideo() {
        this.duplicatePopup = false;
        this.videoManager
            .cloneVideo(this.sUserEmail, this.sSessionId, this.selectedDuplicateVideo.fpath)
            .then((rs) => {
                this.videoCount++;
            })
            .catch((err) => {
                this.errorService.handleError(err.errorMessage, err.errorType, true);
            });
    }

    CreateDuplicateVideoResponse = (InComingMessage) => {
        // console.log(InComingMessage);
    };

    gridView() {
        this.thumbListView = false;
        // console.log("grid click");
        this.resizeThumbs();
        document.querySelector('#container-view div.row').classList.remove('list');
        document.querySelector('#container-view div.row').classList.add('grid');
        document.getElementById('gridView').classList.add('active');
        document.getElementById('listView').classList.remove('active');
        this.session.dashboardVideoGridView = true;
        this.session.dashboardVideoListView = false;
        this.pronto.videoDataView.next({ data: this.session.dashboardVideoListView });
    }
    listView() {
        this.thumbListView = true;
        // console.log("list click");

        document.getElementById('cardListing').classList.remove('grid');
        document.getElementById('cardListing').classList.add('list');
        document.getElementById('listView').classList.add('active');
        document.getElementById('gridView').classList.remove('active');
        this.session.dashboardVideoListView = true;
        this.session.dashboardVideoGridView = false;
    }
    buttonUp() {
        this.isSearchboxOpen = true;
    }
    searchPublishVideo() {
        this.searchTermText = this.title;
        this.searchTermText = this.searchTermText.replace(/^\s+|\s+$/g, '');
        this.search = this.searchTermText;
        this.fetchVideos(0);
        this.searchTermText = '';
        // this.searchTermText = "";
    }

    clearSearchTerm() {
        this.search = '';
        this.searchTermText = '';
        this.fSearchVideo.controls['title'].setValue('');
        this.fetchVideos(0);
    }

    selectedVideoResize = undefined;
    openExpectRationModel($event, indexVideo) {
        let video = this.videoManager.videos[indexVideo];
        let targetObj = $event.target.getBoundingClientRect();
        let popupPositionLeft = targetObj.left;
        if (window.innerWidth - targetObj.left < 300) {
            popupPositionLeft = popupPositionLeft - 250;
        }

        let __this = this;
        this.selectedVideoResize = video;

        let dialogConf = {
            maxWidth: this.pronto.settings.aspect_popup.max_width,
            maxHeight: this.pronto.settings.aspect_popup.max_height,
            width: this.pronto.settings.aspect_popup.width,
            height: this.pronto.settings.aspect_popup.height,
            autoFocus: false,
            position: { left: popupPositionLeft + 25 + 'px', top: targetObj.top - 120 + 'px' },
            panelClass: 'pronto-aspect-dialog',
            data: { video: video },
        };

        let showUploadModal = true;
        if (showUploadModal) {
            let dialogModal = this.dialog.open(AspectRatioPopupComponent, dialogConf);

            dialogModal.afterClosed().subscribe((data: any) => {
                if (data && data.success) {
                    __this.updateVideoAspects(data?.aspect, indexVideo, data?.video);
                    this.cd.detectChanges();
                }
            });
        } else {
            this.errorService.handleError('Brand does not have any Media Library', 'Logo Update', true, false, true);
        }
    }

    updateVideoAspects(ars, indexVideo, video) {
        if (ars && this.videoManager.videos[indexVideo]) {
            if (!this.videoManager.videos[indexVideo].ars) {
                this.videoManager.videos[indexVideo].ars = [video.aspect_ratio];
            }
            this.videoManager.videos[indexVideo].ars.push(...ars);
            // this.videoManager.videos[indexVideo].ars.push(ars);
        }
        this.successService.showInfoToaster('Resizing in progress. Find your new resized video(s) in your drafts.', true, true);
        this.cd.detectChanges();
    }

    selectAspectRatio(ratio: string) {
        this.searchAspectRatio = ratio;
        this.searchAspectRatio = this.searchAspectRatio == 'all' ? '' : this.searchAspectRatio;
        this.deselectAll();
        this.fetchVideos(0);
        this.sSelectVideoOption = 'none';
    }
    editTitle(video, i) {
        this.videoManager.videos.map((item) => {
            if (item.fpath != video.fpath) {
                item.edit_title = false;
            }
        })
        video.edit_title = !video.edit_title;

        if (!video.edit_title) {
            if (video.title_temp == '') {
                video.title_temp = video.title;
                this.errorService.handleError('Video Title can not be empty.', 'Edit Video Title', true);
            } else {
                this.updateVideoTitle(video);
            }
        } else {
            video.title_temp = video.title;
            let __this = this;
            setTimeout(() => {
                var titleField = <HTMLVideoElement>document.getElementById(`edit-video-${i}`);
                titleField.focus();
            }, 0);
        }
    }

    updateTitle(keycode, video) {
        if (keycode == 13) {
            this.updateVideoTitle(video)
            video.edit_title = !video.edit_title;
        }
    }
    updateVideoTitle(video) {
        let data = {
            video_id: video.fpath,
            title: video.title_temp,
            session_id: this.sSessionId,
            email: this.sUserEmail,
        };
        let promise = new Promise((resolve, reject) => {
            this.videoService
                .updateVideoTitle(data)
                .then((res) => {
                    video.title = video.title_temp;
                    this.successService.showInfoToaster('Video name has been updated.', true, true);
                    resolve(res);
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    reject(err);
                });
        });

    }

    goToSubscribe() {
        this.subscriptionPlan.showPlanDialogue();
    }


    getSafePath(video){
        return video.fpath;
        // return encodeURIComponent(video.fpath);
    }
    setSortType(type){
        this.sortType = type;
        this.deselectAll();
        this.fetchVideos(0);
        this.sSelectVideoOption = 'none';
    }
    resetCreated(){
        this.selectedCreatedBy = '';
    }
    CreatedVideosComponent_CreatedByVideos(CreatedValue: any) {
        if (this.selectedCreatedBy != CreatedValue.name) {
            this.selectedCreatedBy = CreatedValue.name;
            this.selectedCreatedByValue = CreatedValue.value;

            // this.fetchVideos(0);
        }
    }
    checkLouderOrgStatus() {
        
        if( this.onboardingManager.profile.organization_id == environment.LouderId){
            this.louderOrg = true;
             }
        if(!this.louderOrg) {
            this.lSortBy = this.lSortBy.filter(item => item.value !== 'fps');
        }
    }
  getStreamVideo(video, sVideoId) { 
    console.log("video streaming called");
    
    this.videoService.getVideoStream(this.sUserEmail, this.sSessionId, sVideoId).subscribe({
      next: (videoBlob) => {
        const videoURL = URL.createObjectURL(videoBlob); // Create an object URL for the Blob
        const videoElement = <HTMLVideoElement>document.getElementById(video.fpath);
        console.log("url stream",videoURL);
        
        videoElement.src = videoURL; // Set the video source
        videoElement?.load();         // Load the new video

        // Optional: Add another listener for when the video is ready to play
      videoElement.addEventListener('canplay', () => {
        console.log('Video is ready to play');
        this.videoManager.videos.forEach((element, i) => {
            if (element.fpath == sVideoId) {
                this.videoManager.videos[i].canPlay = true;
                videoElement?.play();         // Play the video automatically (optional)
            }
        });
      });
      },
      error: (error) => {
        console.error('Error streaming video:', error);
      }
    });
  }
  deleteVideos(){
    if(this.sSelectVideoIds.length > 0){
    this.batchPopup = true;
    this.isAllowDelete = true;
    this.isPopupHeading = 'Are you sure you want to delete these video?';
    this.isPopupText = 'These video will be permanently deleted from your account.';
  }}
  downloadVideos(){
    if(this.sSelectVideoIds.length > 0){
    this.batchPopup = true;
    this.isAllowDownload = true;
    if(this.sInputVideoStatus == 'published' && this.isLocations == false){ 
        this.isPopupHeading = 'Are you sure you want to download these videos?';
        this.isPopupText = 'The selected videos will be downloaded to your device.';
    }
    else if(this.isLocations == true){
        this.isPopupHeading = "Confirm Video Download";
        this.isPopupText = "Downloading localised videos will publish it and count towards your plan's total published videos.";
    }
    else{
        this.isPopupHeading = "Confirm Video Download";
        this.isPopupText = "Downloading draft videos will publish it and count towards your plan's total published videos.";

    }
  }}
  downloadBatch(){
    this.batchPopup = false;
    this.isAllowDownload = false;
    if(this.sInputVideoStatus == 'published' && this.isLocations == false){
        
    this.sSelectedVideosForDownload.map((video, i)=>{
        this.DashboardHeaderComponent_DownloadVideo(video);
        this.deselectAll();
    });
    }
    else{
        this.videoService.downloadVisuals(this.sUserEmail, this.sSessionId, this.sSelectVideoIds).subscribe((res) => {
            console.log("download call response", res); 
            if(res.status){
                let rVideos = res.status ;
                rVideos.map((video, i)=>{
                this.DashboardHeaderComponent_DownloadVideo(video);
            });
            
        this.deselectAll();
        this.fetchVideos(0);
            }
        },
        (error) => {
            console.error('Error downloading visuals:', error);
        });
    }
  }
  deleteBatch(){
    this.batchPopup = false;
    this.isAllowDelete = false;

    this.videoService.deleteVisuals(this.sUserEmail, this.sSessionId, this.sSelectVideoIds).subscribe((res) => {
        console.log("delete response", res);
        this.deselectAll();
        this.fetchVideos(0);
    },
    (error) => {
        console.error('Error deleting visuals:', error);
    });
  }
  selectVideoOption(svideo){
    this.sSelectVideoOption = svideo.name
    if(svideo.value == 'all'){
        this.videoManager.videosGroup.map((group, gi)=>{
            group.videos.map((video, vi)=>{
                this.videoManager.videosGroup[gi].videos[vi].selected_video = true;
                if (!this.sSelectVideoIds.includes(video.fpath)) {
                    this.sSelectVideoIds.push(video.fpath);
                }
                if (!this.sSelectedVideosForDownload.includes(video)) {
                    this.sSelectedVideosForDownload.push(video);
                }
            })
        })
    }
    else{
        this.videoManager.videosGroup.map((group, gi)=>{
            group.videos.map((video, vi)=>{
                this.videoManager.videosGroup[gi].videos[vi].selected_video = false;
                this.sSelectVideoIds = [];
                this.sSelectedVideosForDownload = [];
            });
        })
    }
    console.log("selected video options", this.sSelectVideoIds);
    console.log("selected video options download", this.sSelectedVideosForDownload);
  }
  selectVideo(sVideo, groupIndex , videoIndex){
    console.log("selected video ids 1", this.sSelectVideoIds);
    this.videoManager.videosGroup.map((group, gi)=>{
        group.videos.map((video, vi)=>{
            if(groupIndex == gi && videoIndex == vi){
                this.videoManager.videosGroup[gi].videos[vi].selected_video = !this.videoManager.videosGroup[gi].videos[vi].selected_video;
                if(this.videoManager.videosGroup[gi].videos[vi].selected_video == true){
                    this.sSelectVideoIds.push(sVideo.fpath); 
                    this.sSelectedVideosForDownload.push(sVideo);
                }
                else{
                    this.sSelectVideoIds = this.sSelectVideoIds.filter(item => item !== sVideo.fpath);
                    this.sSelectedVideosForDownload = this.sSelectedVideosForDownload.filter(item => item.fpath !== sVideo.fpath);
                }
            }
        })
    })
        console.log("selected video ids ", this.sSelectVideoIds);
        console.log("selected video for download ", this.sSelectedVideosForDownload);
    
  }
  changePanel(event, panel) {
    console.log(event);
    console.log(panel);
    panel.isActive = event;
  }
  getVideoId(text: any) {
    console.log("text", text);
    this.searchTermText = text;
    this.deselectAll();
    this.fetchVideos(0);
  }
  floatLabel(): 'auto' | 'always' | 'never' {
    // You can conditionally return 'auto', 'always', or 'never' here
    return 'auto';  // Default: it floats when the input has focus or value
  }

  DashboardHeaderComponent_DownloadVideo(incommingVideo) {

    console.log("incommingVideo for download", incommingVideo);
    // console.log(this.videoManager.selectedVideo.video_url);
    let videoName = incommingVideo.title;


    var videoURL = incommingVideo.fpath? incommingVideo.fpath : incommingVideo.visual_id;
    let lastIndex = videoURL.lastIndexOf("/");
    // let nameofFile =  videoURL.substring(lastIndex+1, videoURL.length);
    let nameofFile = incommingVideo.title + ".mp4";
    console.log(" ...video title ", nameofFile);
    console.log("  video ", lastIndex, nameofFile);

    let sSignedURL  = incommingVideo.video_url? incommingVideo.video_url : incommingVideo.public_url;
    if (!sSignedURL) {
      sSignedURL = incommingVideo.video_url? incommingVideo.video_url : incommingVideo.public_url;
    }
    console.log("signed Url", sSignedURL);
    


    this.loader.showLoader();
    fetch(sSignedURL)
      .then(response => response.blob())
      .then(blob => {
        // console.log("signed Url", this.sSignedURL);
        // console.log("blob",  blob);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = (nameofFile && nameofFile != '') ? nameofFile : "pronto.mp4";
        link.click();
        this.loader.hideLoader();
      })
      .catch(error => {
        this.loader.hideLoader();
        this.errorService.handleError(error.errorMessage, error.errorType, true);
        console.error("DashboardHeaderComponent_DownloadVideo : Error ==>", error);
      });
  }
  deselectAll(){
    this.videoManager.videosGroup.map((group, gi)=>{
        group.videos.map((video, vi)=>{
            this.videoManager.videosGroup[gi].videos[vi].selected_video = false;
            this.sSelectVideoIds = [];
            this.sSelectedVideosForDownload = [];
        });
    })
  }
}

