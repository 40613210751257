import { Component, OnInit } from '@angular/core';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cDataService } from './../../../services/data.service';
import { cEditVideoService } from './../../../services/edit-video.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { ErrorService } from './../../../services/error.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';

@Component({
  selector: 'app-dashboard-download',
  templateUrl: './dashboard-download.component.html',
  styleUrls: ['./dashboard-download.component.scss']
})
export class DashboardDownloadComponent implements OnInit {

  oVideoDetails: any;
  sUserEmail: string;
  sSessionId: string;
  sSignedURL: string;
  isConfirmDownload = false;
  constructor(private oSessionService: cSessionService, private loader: LoaderService, private oWebSocketService: cWebSocketService, private oDataService: cDataService, public videoManager: VideoManagerService, public errorService: ErrorService,
    public pronto: ProntoSettingService,) {

  }

  ngOnInit() {

    // this.sSessionId = this.oSessionService.cSessionService_GetGuestSession();
    // this.sUserEmail = this.oSessionService.cSessionService_GetGuestEmail();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.sSignedURL = this.videoManager?.selectedVideo?.video_url;


    if (!this.sSignedURL) {
      this.videoManager
        .getVideoSignedURL(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo?.fpath)
        .then((data) => {
          console.log("signed url ", data);
          this.videoManager.selectedVideo.video_url = data.url;
          this.sSignedURL = this.videoManager.selectedVideo.video_url;
          console.log("Signed url found ...", this.sSignedURL);
        });
    }
  }

  DashboardHeaderComponent_DownloadVideo() {

    console.log(this.videoManager.selectedVideo);
    // console.log(this.videoManager.selectedVideo.video_url);
    let videoName = this.videoManager.selectedVideo?.title;


    var videoURL = this.videoManager.selectedVideo?.fpath;
    let lastIndex = videoURL.lastIndexOf("/");
    // let nameofFile =  videoURL.substring(lastIndex+1, videoURL.length);
    let nameofFile = this.videoManager.selectedVideo?.title + ".mp4";
    console.log(" ...video title ", nameofFile);
    console.log("  video ", lastIndex, nameofFile);


    if (!this.sSignedURL) {
      this.sSignedURL = this.videoManager.selectedVideo?.video_url;
    }


    this.loader.showLoader();
    fetch(this.sSignedURL)
      .then(response => response.blob())
      .then(blob => {
        // console.log("signed Url", this.sSignedURL);
        // console.log("blob",  blob);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = (nameofFile && nameofFile != '') ? nameofFile : "pronto.mp4";
        link.click();
        this.loader.hideLoader();
      })
      .catch(error => {
        this.loader.hideLoader();
        this.errorService.handleError(error.errorMessage, error.errorType, true);
        console.error("DashboardHeaderComponent_DownloadVideo : Error ==>", error);
      });
  }

  async downloadnPublish() {
    this.isConfirmDownload = false;

    await this.videoManager
      .updateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManager.selectedVideo.fpath, 'published')
      .then((res) => {
        if (res) {
          this.pronto.sVideoStatus = 'published';
          this.DashboardHeaderComponent_DownloadVideo();
        }
      })
      .catch((err) => {
        if (err.errorMessage.includes('Monthly quota')) {
          this.errorService.handleError(err.errorMessage, 'Monthly Quota Exceeded', true, false, true);
        }
        else {
          this.errorService.handleError(err.errorMessage, err.errorType, true, false, true);
        }
      });


  }
  confirmDownload() {
    this.isConfirmDownload = true;
  }
}
