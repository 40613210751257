import { visual } from './../../models/media';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { locale } from "../../../app/models/account";
import { mediaLib } from "../../../app/models/media";
import { Observable } from "rxjs";
import { SearchData } from "src/app/models/admin";
import { ApiURL } from "src/app/conf/api-path";
import { OnboardingManagerService } from "src/app/Managers/onboarding-manager.service";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  constructor(
    private http: HttpClient,
    public onboardinManger: OnboardingManagerService
  ) {}

  getvisual(
    email,
    session_id,
    visual_type,
    media_lib,
    num_items,
    num_skip,
    sort_by,
    group_by,
    search_keyword,
    isBrand = false,
    libsArray = [],
    asc = -1
  ) {
    if (search_keyword === "") {
      return this.getVisualListByMediaLib(
        email,
        session_id,
        visual_type,
        media_lib,
        num_items,
        num_skip,
        sort_by,
        group_by,
        isBrand,
        libsArray,
        asc
      );
    } else {
      return this.searchVisualListByMediaLib(
        email,
        session_id,
        visual_type,
        media_lib,
        num_items,
        num_skip,
        sort_by,
        group_by,
        search_keyword,
        isBrand,
        libsArray,
        asc
      );
    }
  }
  getVisualListByMediaLib(
    email,
    session_id,
    visual_type,
    media_lib,
    num_items,
    num_skip,
    sort_by,
    group_by,
    isBrand = false,
    libsArray = [],
    asc = -1
  ) {
    // if(sort_by == "name"){
    //   sort_by = "fpath";
    // }
    let searchStr = {
      email: email,
      visual_type: visual_type,
      limit: num_items,
      offset: num_skip,
      asc: asc,
      sort_by: sort_by,
      group_by: group_by,
      visual_id: "",
      search_by:"name",
      media_lib_ids: media_lib && media_lib != "" ? [media_lib] : undefined,
    };
    if (libsArray && libsArray.length > 0) {
      searchStr.media_lib_ids = libsArray;
    }
    if (isBrand) {
      delete searchStr.email;
    }
    return this.http.post(environment.API_HOST_ADMIN +  ApiURL.admin.visual.getAll, searchStr);
  }

  getBrandVisuals(
    data
  ) {
    if(data.sort_by == "name"){
      data.sort_by = "fpath";
    }
    return this.http.post(environment.API_HOST_ADMIN +  ApiURL.admin.visual.getAll, data);
  }

  getMyLibraryVisuals(
    data
  ) {
    if(data.sort_by == "name"){
      data.sort_by = "fpath";
    }
    return this.http.post(environment.API_HOST_ADMIN +  ApiURL.admin.visual.getAll, data);
  }
  getThumbBatchSignedURL(email, session_id, urls: string[]) {
    let formData = new FormData();
    formData.append("session_id", session_id);
    formData.append("email", email);
    formData.append("fpaths", JSON.stringify(urls));
    return this.http
      .post(environment.API_HOST + "get_thumbnails", {
        session_id: session_id,
        email: email,
        fpaths: urls,
      })
      .toPromise();
  }
  searchVisualListByMediaLib(
    email,
    session_id,
    visual_type,
    media_lib,
    num_items,
    num_skip,
    sort_by,
    group_by,
    search_keyword,
    isBrand = false,
    libsArray = [],
    asc = -1
  ) {
    // if(sort_by == "name"){
    //   sort_by = "fpath";
    // }
    let searchStr = {
      email: email,
      visual_type: visual_type,
      limit: num_items,
      offset: num_skip,
      asc: asc,
      sort_by: sort_by,
      group_by: group_by,
      visual_id: search_keyword,
      media_lib_ids: media_lib && media_lib != "" ? [media_lib] : undefined,
    };

    if (libsArray && libsArray.length > 0) {
      searchStr.media_lib_ids = libsArray;
    }

    if (isBrand) {
      delete searchStr.email;
    }
    return this.http.post(environment.API_HOST_ADMIN + "visuals", searchStr);
  }

  getVisualCountBy(email, session_id, visual_type, media_lib) {
    return this.http.get(
      environment.API_HOST +
        "visuals?email=" +
        email +
        "&session_id=" +
        session_id +
        "&visual_type=" +
        visual_type +
        "&media_lib=" +
        media_lib
    );
  }

  deleteVisual(email, session_id, visual_id) {
    return this.http.delete(
      environment.API_HOST +
        "delete_visual?email=" +
        email +
        "&session_id=" +
        session_id +
        "&visual_id=" +
        visual_id
    );
  }

  getVisual(email, session_id, visual_id) {
    return this.http
      .get(
        environment.API_HOST +
          "get_visual?email=" +
          email +
          "&session_id=" +
          session_id +
          "&visual_id=" +
          visual_id
      )
      .toPromise();
  }

  getReplaceClips(email, session_id, visual_id, replace_id) {
    //return this.http.request('get',environment.API_HOST +'replacement_clips',{body: {email:email,session_id:session_id,video_id:visual_id,replace_id:replace_id}}).toPromise();

    return this.http
      .get(
        environment.API_HOST +
          "replacement_clips?email=" +
          email +
          "&session_id=" +
          session_id +
          "&video_id=" +
          visual_id +
          "&replace_id=" +
          replace_id
      )
      .toPromise();
  }
  getReplaceClipsV2(email, session_id, visual_id, keywords : [], limit, target_library) {
    //return this.http.request('get',environment.API_HOST +'replacement_clips',{body: {email:email,session_id:session_id,video_id:visual_id,replace_id:replace_id}}).toPromise();

    return this.http
      .post(
        environment.API_HOST +
          "search_clips", {email, session_id,video_id:visual_id,key_terms: keywords, limit : limit, target_library})
      .toPromise();
  }

  getPronotoaudio(email, session_id, num_items, num_skip, category = '') {
    //return this.http.request('get',environment.API_HOST +'replacement_clips',{body: {email:email,session_id:session_id,video_id:visual_id,replace_id:replace_id}}).toPromise();
    if(!category || category == ''){
      category = undefined;
    }
    let data = {
      email,
      session_id,
      num_items,
      num_skip,
      category
    }
    return this.http
      .post(
        environment.API_HOST + "audios", data
      )
      .toPromise();
  }

  getduration(email, session_id, visual_id) {
    //return this.http.request('get',environment.API_HOST +'replacement_clips',{body: {email:email,session_id:session_id,video_id:visual_id,replace_id:replace_id}}).toPromise();

    return this.http
      .get(
        environment.API_HOST +
          "video_duration?email=" +
          email +
          "&session_id=" +
          session_id +
          "&visual_id=" +
          visual_id
      )
      .toPromise();
  }
  updateVisual(email, session_id, visual_id, keyterms) {
    return this.http.post(environment.API_HOST + "update_visual", {
      email: email,
      session_id: session_id,
      visual_id: visual_id,
      key_terms: keyterms,
    });
  }

  getMediaLibrary(email, session_id, mediaLibStatus) {
    return this.http.get(
      environment.API_HOST +
        "media_libs?email=" +
        email +
        "&session_id=" +
        session_id +
        "&filter_status=" +
        mediaLibStatus
    );
  }
  getMyMediaLibrary(data) {
    console.log("load user media lib data ..");
    return this.http.post(
      environment.API_HOST_ADMIN + "user_media_libs" , data )}

  updateMediaLibrary(
    mediaLibName,
    libActivationStatus,
    sUserEmail,
    sSessionId,
    mediaLibId
  ) {
    return this.http.post(environment.API_HOST + "update_media_lib", {
      email: sUserEmail,
      session_id: sSessionId,
      media_lib_id: mediaLibId,
      media_lib: mediaLibId,
      lib_name: mediaLibName,
      activation_status: libActivationStatus,
    });
  }

  getSignedURL(email, session_id, video_id) {
    let formData = new FormData();
    formData.append("session_id", session_id);
    formData.append("email", email);
    formData.append("fpath", video_id);
    return this.http
      .post(environment.API_HOST + "signed_url_for_download", formData)
      .toPromise();
  }
  getBatchSignedURL(email, session_id, urls: string[]) {
    let formData = new FormData();
    formData.append("session_id", session_id);
    formData.append("email", email);
    formData.append("fpaths", JSON.stringify(urls));
    if (!urls || urls.length == 0) {
      return new Promise((resolve, reject) => {
        reject({ errorMessage: "Fpath list is empty" });
      });
    } else {
      return this.http
        .post(environment.API_HOST + "signed_url_multi_download", {
          session_id: session_id,
          email: email,
          fpaths: urls,
        })
        .toPromise();
    }
  }

  addMediaLibrary(
    email,
    session_id,
    lib_name,
    keyterms,
    description,
    category
  ) {
    return this.http.post(environment.API_HOST + "media_lib", {
      email: email,
      session_id: session_id,
      lib_name: lib_name,
      key_terms: keyterms,
      description: description,
      category: category,
    });
  }

  updloadVisualToMedia(email, session_id, file, is_logo, key_terms, media_lib) {
    console.log(file);

    let formda = new FormData();
    formda.append("email", email);
    formda.append("session_id", session_id);
    formda.append("file", file);
    formda.append("is_logo", is_logo);
    formda.append("key_terms", key_terms);
    formda.append("media_lib", media_lib);
    return this.http.post(environment.API_HOST + "user_file", formda);
  }

  //BulkUploadvisuals
  DataService_BulkUpload(email, session_id, media_lib, file, content_type) {
    // const orgnization = this.onboardinManger.profile.organization._id.$oid;
    const orgnization = this.onboardinManger.profile.organization_id;
    if (orgnization) {
      console.log(" orgnization", orgnization);
      let formda = new FormData();
      formda.append("email", email);
      formda.append("session_id", session_id);
      formda.append("filename", file);
      formda.append("media_lib_id", media_lib);
      formda.append("content_type", content_type);
      formda.append("owner_type", "user");
      formda.append("organization_id", orgnization);

      return this.http
        .post(environment.API_HOST_ADMIN + "signed_url_for_upload", formda)
        .toPromise();
    } else {
      return new Promise((resolve, reject) => {
        reject({ errorMessage: "orgnaization not found" });
      });
    }

    //return this.oClientService.ClientService_GetSignedUrl('signed_url_for_upload', sUploadedVisualsData);
  }
  //bulk upload
  DataService_UploadFileOnSignedUrl(file: any, sSignedURL: string) {
    // console.log( " is Azure upload ", environment.IS_AZURE);
    if(environment.IS_AZURE && environment.IS_AZURE == true){
      return this.http.put(sSignedURL, file,
        { headers:{
        "x-ms-blob-type": "BlockBlob"
      }}).toPromise();
    } else {
      return this.http.put(sSignedURL, file).toPromise();
    }
  }

  AddUserMedia(email, session_id, file, media_lib, fpath = undefined) {
    const orgnization = this.onboardinManger.profile.organization_id;
    if (orgnization) {
      let formda = new FormData();
      formda.append("email", email);
      // formda.append("session_id", session_id);
      formda.append("filename", file);
      formda.append("media_lib_id", media_lib);
      formda.append("organization_id", orgnization);
      if (fpath) {
        formda.append("fpath", fpath);
      }
      return this.http
        .post(environment.API_HOST_ADMIN + "add_user_media", formda)
        .toPromise();
    } else {
      return new Promise((resolve, reject) => {
        console.log(" orgnaization not found ...");
        reject({ errorMessage: "orgnaization not found" });
      });
    }
  }

  getAdminMediaList(search: string) {
    return this.http
      .get(environment.API_HOST + ApiURL.admin.visual.getAll + "?" + search)
      .toPromise();
  }

  getDefaultFontLists() {
    return this.http
      .post(environment.API_HOST_ADMIN + ApiURL.admin.fonts.getAll,{})
      .toPromise();
  }

  getListGenres(params) {
    return this.http
      .post(environment.API_HOST + ApiURL.user.generes,params)
      .toPromise();
  }

  getExtractedClips(videoId: string): Observable<any> {
    let apiUrl = environment.API_HOST_ADMIN + ApiURL.admin.media.get_extracted_clips; // Replace with your actual API URL
      const payload = { video_id: videoId };
      return this.http.post<any>(apiUrl, payload);
    }
    visuals_search_ac(email, search_term: string,visual_type, media_lib_ids) {
      const data = {
          email,
          search_term,
          visual_type,
          media_lib_ids
      }
      return this.http
          .post(environment.API_HOST_ADMIN + ApiURL.admin.media.visuals_search_ac, data)
          .toPromise();
  }
}
