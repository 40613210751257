<!-- <div class="overlay sidebarOverlay"></div> -->
<nav class="pt-3">
    <div class="sidebar-header pt-2">
        <div *ngIf="!this.isLocalizeVideo" class="closeRightSideBar" (click)="passSelectedLocale()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_6)">
                    <path
                        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                        fill="#F2F2F5" />
                    <path
                        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                        fill="#A7ABB6" />
                </g>
                <defs>
                    <clipPath id="clip0_1_6">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
    <div class="sidebar-content p-0" style="
overflow: auto;">
        <div class="locations d-flex flex-column h-70vh">
            <div class="top-sec px-4 ">
                <p class="weight-700 text-lg mb-0">Select locations</p>
                <p class="text-md text-gray-2 pe-2" *ngIf="oTotalCount==0">{{oTotalCount}} locations configured
                    in the system. Please go to <a class=" text-primary-4 fw-bold"
                        routerLink="/account-setting/locations"> Account Settings</a> to start adding locations.
                </p>
                <p class="text-md text-gray-2" *ngIf="oTotalCount>=1">{{oTotalCount}} locations configured in
                    the system</p>
            </div>

            <div class="formItm search-icon searchDropdown px-3">
                <!-- Search Users -->
                <mat-form-field [floatLabel]="floatLabel()" style="width: 28vw !important;">
                    <input [(ngModel)]="title" #locationListAutoComplete #searchLocationField
                        placeholder="Search your Location"
                        (ngModelChange)="this.modelLocationSearchChanged.next($event)" matInput
                        [matAutocomplete]="autoCompleteLocation" />
                    <mat-autocomplete #autoCompleteLocation="matAutocomplete">
                        <mat-option *ngIf="isLocationSearchLoading" class="is-loading">Loading...</mat-option>
                        <ng-container *ngIf="!isLocationSearchLoading && locationSearchList?.result?.length > 0">
                            <mat-option *ngFor="let location of locationSearchList.result" [value]="location.name"
                                (click)="SearchLocation(location)">
                                <span><b>{{ (location.name.length>45)?
                                        (location.name | slice:0:45)+'...':(location.name)}}</b></span>
                            </mat-option>
                        </ng-container>
                        <ng-container *ngIf="locationSearchList?.result?.length == 0">
                            <mat-option [value]="null">
                                <span>No results found</span></mat-option>
                        </ng-container>
                    </mat-autocomplete>

                    <img class="search" src="../../../assets/images/svg/search.svg">
                </mat-form-field>
            </div>

            <div class="searchtem" *ngIf="search_term != ''">
                <div>{{ (search_term.length>25)?
                    (search_term | slice:0:25)+'...':(search_term)}}</div>
                <mat-icon (click)="clearSearchTerm()" class="link">close</mat-icon>
            </div>
            <div class="sort-group-btn mt-3">
                <div class="dropdown dropdownBtn me-2">

                    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span class="dropdownBtnText"><strong>Sort by </strong>{{ sSelectedSort_by }}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end dropdownMenu ddd">
                        <li *ngFor="let sort of lSortBy">
                            <a class="dropdown-item text-md" href="javascript:void(0)"
                                (click)="sortBySearchResult(sort)">{{ sort.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="dropdown dropdownBtn me-2">

                    <button class="dropdown-toggle text-md" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span class="dropdownBtnText"><strong>Group by </strong>{{ sSelectedGroup }}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end dropdownMenu ddd">
                        <li *ngFor="let group of lGroupBy">
                            <a class="dropdown-item text-md" href="javascript:void(0)"
                                (click)="groupBySearchResult(group)">{{ group.name }}</a>
                        </li>
                    </ul>
                </div>

                <div class="sort-arrows">
                    <img src="../../../../../assets/images/DataSort-1.svg" height="25px" *ngIf="sort_type == -1"
                        (click)="sortTypeSearch(1)" />
                    <img src="../../../../../assets/images/DataSort-3.svg" height="25px" *ngIf="sort_type == 1"
                        (click)="sortTypeSearch(-1)" />
                </div>
            </div>


            <div class="mid-sec px-4">
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <div class="tableCustom">
                            <div class="tableCheck d-flex">
                                <div class="custom-switchh">
                                    <input type="checkbox" [(ngModel)]="this.AllLocaleSelected"
                                        (change)="CreateVideoOverViewComponent_SelectAllLocations($event)"
                                        class="check form-check-input" id="checkAll">
                                </div>
                                <span class="text-xs text-gray-2 ms-2 opaque-6 mt-1">{{this.AllLocaleSelected
                                    ?this.oTotalCount:lSelectedLocations.length}} locations selected</span>
                            </div>
                            <br>
                        </div>
                    </div>
                    <div class="col-md-6 pb-0">
                        <div class="float-end searchbox-slide d-inline-block m-0 p-0 mt-1">

                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-sec p-0 flex-grow-1 overflow-auto tableCustom mb-0">
                <nz-collapse nzAccordion class="pt-0" nzGhost [nzBordered]="false">
                    <nz-collapse-panel *ngFor="let lLocales of this.lLocalesGroup; index as groupIndex"
                        [nzHeader]="lLocales.key" [nzActive]="lLocales.active">
                        <ul class="bottom-sec p-0 flex-grow-1 overflow-auto tableCustom mb-0">
                            <li *ngFor="let item of this.lLocales.locations">
                                <div class="py-3 px-4">
                                    <div class="tableCheck d-flex">
                                        <div class="custom-switchh">
                                            <input type="checkbox"
                                                (change)="CreateVideoOverviewComponent_SelectedLocation($event, item)"
                                                [(ngModel)]="item.selected" class="check form-check-input"
                                                id="checkAll">
                                        </div>
                                        <span class=" text-xs text-black ms-2 mt-1" style="display: grid !important;
                            grid-template-columns: 50% 50%; width: 100%;">
                                            <strong style="cursor: pointer;" [matTooltip]="item.name"
                                                matTooltipClass="custom-tooltip" matTooltipPosition="above"
                                                class='truncate-line'>{{item.name}}
                                            </strong>
                                            <div>
                                                <span class=" truncate-line ps-2"
                                                    *ngFor="let localField of item | keyvalue  | locationActiveFieldsPipe : defaultLocationFormField  ">
                                                    <span class="me-3"
                                                        *ngIf="localField.value && localField.value != ''">{{localField.value}}</span>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </nz-collapse-panel>
                </nz-collapse>
            </div>

        </div>
    </div>
    <div class="d-flex mt-3">

        <ul style="padding-left: 25px;" [ngClass]="{'d-none': this.isLocalizeVideo == true}">
            <a class="weight-normal btn btn-primary btn-round-6 px-4 py-3" (click)="passSelectedLocale()">Done</a>

        </ul>
        <div class="table-pagination pe-0" style="width: 100%;">
            <div class="row">
                <div class="col-sm-10 px-0">
                    <mat-paginator style="margin-top: 15px;" pageIndex="currentPage" [length]="this.oTotalCount"
                        [pageSize]="this.num_items" [pageSizeOptions]="[10, 16, 25, 50]" (page)="loadPageEvent($event)"
                        showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>

    </div>

</nav>