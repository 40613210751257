<div class="h-85vh br12 curve-bg tab-content bg-white page-shadow  m-0 p-0" id="pills-tabContent">
    <div class="tab-pane fade show active">
        <div class="content_area p-0">
            <div class="row m-0">

                <div class="col-sm-12 col-md-12 col-lg-7 h-85vh ps-5 pt-4">
                    <div class="row ps-4 pt-3 mt-1">
                        <p class="text-lg text-gray-3 primaryFont"><span class="counts">1. </span> Please upload the
                            video to localize</p>
                        <div>
                            <button class="btn btn-md btn-secondary btn-round-6" (click)="openFileUploadModal()">
                                <img src="../../../../assets/images/svg/upload.svg">
                                &nbsp; &nbsp; Upload a File
                            </button>

                            <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 ms-3"
                                (click)="showMediaModal()">
                                <img src="../../../../assets/images/svg/mediaLibrary.svg">
                                &nbsp; &nbsp; Select From Media Library
                            </button>
                        </div>
                    </div>


                    <div class="row ps-4 pt-3 mt-1 pe-5" *ngIf="this.uploadedVdeo != null">
                        <div class="video-outer media-content-inner d-flex">

                            <div class="video_wrapper video_wrapper_full js-videoWrapper p-0">
                                <figure class="video-baner">
                                    <img [src]="this.uploadedVdeo?.thumb"
                                        style="height:100px; width: auto !important; object-fit: cover;">
                                </figure>
                                <button class="videoPoster js-videoPoster Poster"></button>
                                <div style="position:absolute; width:100%; height: 100%; top: 0; left:0;cursor:pointer;"
                                    (click)="displayVideoPopup()"></div>
                            </div>

                            <div class="ps-3">
                                <p class="text-lg text-gray-2" style="cursor:pointer;" (click)="displayVideoPopup()" *ngIf="this.uploadedVdeo?.filename">
                                    {{returnFileName(this.uploadedVdeo.filename.split("/").pop())}}</p>
                                <p class="video-duration py-1" *ngIf="this.uploadedVdeo?.duration">
                                    {{this.uploadedVdeo?.duration}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row ps-4 pt-3 mt-1" *ngIf="templateCategories && templateCategories?.length > 1">
                        <p class="text-lg text-gray-3 primaryFont"><span class="counts">2. </span> Select a template</p>

                    <div class="videoimg_wrapp">

                        <ul
                            class="mb-1">
                            <li style="width : auto !important" *ngFor="let template of templateCategories | aspectRatio : aspect_ratio"

                            [ngClass]="{'active': this.selectedTemplate == template?.name}"
                            [ngStyle]="{'display': template?.title == 'Default' ? 'none' : ''}">
                                <a href="javascript:void(0)" (click)="selectTemplate(template)"
                                    nz-tooltip nzTooltipPlacement="bottom"
                                    [nzTooltipTitle]="template.title">
                                    <img [src]="template.thumb" class="img-fluid " style='width: auto !important; height : 100px'
                                        />
                                </a>
                            </li>
                        </ul>

                  <p *ngIf="IsTemplateError" class="text-xs pb-1 text-danger fw-bold mt-3">
                    Please select a template to proceed.
                  </p>
                    </div>
                    </div>
                    <div class="col-lg-12 ps-4" *ngIf="countForlouderBrand == 1">
                        <div class="formItm static_lable order_list">
                            <label for=""><span class="counts">3. </span> Select a frame rate from the options or enter a custom FPS</label>
                            <div class="radio_select_wrapp">
                                <label class="radio_select" [ngClass]="{'d-none':  !fps.isEnabled}"
                                    *ngFor="let fps of lVideoFps; index as i">
                                    <input type="radio" name="fps" [value]="fps.value" [checked]="fps.checked"
                                        class="radio_input_element" [(ngModel)]="selectedFps"/>
                                    
                                    <div class="radio_caption" [ngClass]="{'active-fps': isClassAdded && i == 6}">
                                        <span>{{fps.name !== '' ? fps.name + ' FPS' : "Enter Custom FPS"}}</span>
                                    </div>


                                </label>
                                <input class="custom-fps-input" [ngClass]="{'d-none':  !enableCustomFpsInput}" type="number" min="0" step="any" 
                                #fpsInput 
                                (keydown)="updateCustomFPSValue($event)" >
                            </div>
                        </div>
                    </div>

                    <!-- <div class="cus-select aspect-ratio ps-4">
                        <div class=" formItm static_lable order_list">
                            <label for=""><span class="counts">3.</span>Select an aspect
                                ratio for your video</label>

                            <div class="dropdown dropdownSelect">
                                <button class="dropdown-toggle text-md dropdownSelectValid pb-0" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="dropdownBtnText">
                                        <a class="text-md aspect-item justify-content-around ps-2 w-385"
                                            href="javascript:void(0)">
                                            <img class="mt-2"
                                                src="../../../../assets/images/aspect ratio/grenn tick.svg">
                                            <p class="mb-0 mt-0 text-black">{{sSelectedAspectRatio.title}}</p>
                                            <div class="ratio-cel ps-2 bg-org"
                                                [ngClass]="sSelectedAspectRatio.ratio == '1:1'?'square': sSelectedAspectRatio.ratio == '4:5'?'portrait': sSelectedAspectRatio.ratio == '16:9'?'landscape':sSelectedAspectRatio.ratio == '9:16'?'reel':''">
                                                <span
                                                    [ngStyle]="{'left': sSelectedAspectRatio.ratio == '1:1'? '6px': sSelectedAspectRatio.ratio == '9:16'? '0': sSelectedAspectRatio.ratio == '4:5'? '5px':''}">{{sSelectedAspectRatio.ratio}}</span>
                                            </div>
                                            <div class="social-icon"
                                                *ngIf="sSelectedAspectRatio.socialIcon.length > 0">
                                                <span *ngFor="let composant of sSelectedAspectRatio.socialIcon"
                                                    class="ps-2">
                                                    <img [src]='composant'
                                                        [ngClass]="{ 'youtube' : composant.indexOf('youtube') > -1}"
                                                        class="d-block" width="25px" height="25px">
                                                </span>
                                            </div>

                                        </a>
                                    </span>
                                </button>
                                <ul class="dropdown-menu dropdownMenu w-400">
                                    <h6 class="text-sm weight-700 mb-0">Most Common Formats</h6>
                                    <li *ngFor="let aspectRation of videoAspectRatio" class="aspectRatio ">
                                        <a class="dropdown-item text-md aspect-item {{aspectRation?.class}}"
                                            [ngClass]="aspectRation.isSelected == true?'active':''"
                                            href="javascript:void(0)"
                                            (click)="CreateVideoOverviewComponent_SelectAspectRation(aspectRation)">
                                            <p class="mb-0 mt-1">{{aspectRation.title}}</p>
                                            <div class="ratio-cel ps-2 "
                                                [ngClass]="aspectRation.ratio == '1:1'?'square': aspectRation.ratio == '4:5'?'portrait': aspectRation.ratio == '16:9'?'landscape':aspectRation.ratio == '9:16'?'reel':''">
                                                <span
                                                    [ngStyle]="{'left': aspectRation.ratio == '1:1'? '6px': aspectRation.ratio == '9:16'? '0': aspectRation.ratio == '4:5'? '4px':''}">{{aspectRation.ratio}}</span>
                                            </div>
                                            <div class="social-icon" *ngIf="aspectRation.socialIcon.length > 0">
                                                <span *ngFor="let composant of aspectRation.socialIcon"
                                                    class="ps-2">
                                                    <img [src]='composant'
                                                        [ngClass]="{ 'youtube' : composant.indexOf('youtube') > -1}"
                                                        class="d-block" width="25px" height="25px">
                                                </span>
                                            </div>

                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> -->

                </div>


                <div class="col-sm-12 col-md-12 col-lg-1"></div>

                <div class="col-sm-12 col-md-12 col-lg-4 pe-0 box-shaddow">
                    <app-locations-list [isLocalizeVideo]="true" (lEmittedLocations)="selectedLocale($event)"></app-locations-list>
                </div>



            </div>
        </div>
    </div>
    <div class="wtermarklogo">
        <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
    </div>
</div>


<!-- VideoPreview pop-up Start-->
<div *ngIf="videoPopup" class="videoPopupDisplay">

    <div style="position:absolute; top:50%; left: 50%; transform: translate(-50%, -50%);">
        <div class="closeRightSideBar" style="  top: -50px; right: -5px;" (click)="close()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_6)">
                    <path
                        d="M14.1971 0H5.8129C2.17108 0 0 2.17 0 5.81V14.18C0 17.83 2.17108 20 5.8129 20H14.1871C17.8289 20 20 17.83 20 14.19V5.81C20.01 2.17 17.8389 0 14.1971 0Z"
                        fill="#F2F2F5" />
                    <path
                        d="M11.1852 9.9986L13.7568 7.42787C14.0811 7.10374 14.0811 6.56724 13.7568 6.2431C13.4326 5.91897 12.8959 5.91897 12.5716 6.2431L10 8.81383L7.42837 6.2431C7.10412 5.91897 6.56744 5.91897 6.24319 6.2431C5.91894 6.56724 5.91894 7.10374 6.24319 7.42787L8.81481 9.9986L6.24319 12.5693C5.91894 12.8935 5.91894 13.43 6.24319 13.7541C6.4109 13.9218 6.62334 14 6.83578 14C7.04822 14 7.26066 13.9218 7.42837 13.7541L10 11.1834L12.5716 13.7541C12.7393 13.9218 12.9518 14 13.1642 14C13.3767 14 13.5891 13.9218 13.7568 13.7541C14.0811 13.43 14.0811 12.8935 13.7568 12.5693L11.1852 9.9986Z"
                        fill="#A7ABB6" />
                </g>
                <defs>
                    <clipPath id="clip0_1_6">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>

        <div class="video_wrapper video_wrapper_full js-videoWrapper p-0">
            <figure class="video-baner"><img [src]="this.uploadedVdeo?.thumb"
                    style="height: 360px; width:720px; object-fit: contain;" alt="video1"></figure>
            <video #videoPreview controls controlsList="nodownload noplaybackrate" class="videoIframe js-videoIframe" preload="metadata" (loadedmetadata)="vidProps($event)"
                [muted]="true" style="height: 360px; width:720px; object-fit: cover;" [src]="this.uploadedVdeo?.url"
                [ngStyle]="{'z-index': vidPreview ? '-1' : ''}"></video>
            <button class="videoPoster js-videoPoster" (click)="previewVideo()"></button>
        </div>
    </div>

</div>
<!-- VideoPreview pop-up end-->

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isModalVisible" nzCentered [nzWidth]="500" [nzFooter]=null [nzClosable]=false
    (nzOnCancel)="hidePopUP()">

    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close closeRightBtn" (click)="hidePopUP()"></button>

                <h5>Processing</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">Your videos are being localized. Please check back
                    shortly.
                </p>
                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="hidePopUP()">Ok</a>
                <!-- <a href="javascript:void(0)"
class="mt-3 text-gray-3 text-sm" (click)="updateMediaLibrary(mediaObj)">asa</a> -->
            </div>
        </div>
    </div>
</nz-modal>


<!-- Media Library Modal pop-up Start-->
<nz-modal [(nzVisible)]="visible" nzCentered [nzWidth]="850" [nzClosable]="true"
    (nzOnCancel)="closeMediaModal()">
    
    <ng-template nzModalContent>
        <app-media-library-popup [videoOnly]="true" (lEmittedFilesToUpload)="mediaSelected($event)"
            [mediaPopUpLoc]="'mediaPopUpLoc'" [isSingleSelected]="true"></app-media-library-popup>
    </ng-template>

</nz-modal>

<!-- Media Library Modal pop-up End-->