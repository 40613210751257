export const ApiURL = {
  user:{
    update: "update_profile",
    voice : {
      getAll : "get_voice_overs",
      textToVoice : "text_to_voice_file",
      setFavorite: "set_favorite_voices",
      setSelection: "set_selected_voices",
      setSelectedVoices: "set_selected_voices",
      crop_file : "crop_audio_file",
      delete_voice_over_file : "delete_voice_over_file"
    },
    mixpixel:{
      create_video_enter : "track"
    },
    generes : "music_generes"
  },
  admin: {
    user: {
      get: "list_users",
      add: "create_user",
      assignBrand: "user_assign_brand",
      unAssignBrand: "user_unassign_brand",
      profile: "get_user",
      brands: "list_user_brands",
      addMediaLib: "add_user_media_lib",
      addBrands: "add_user_brand",
      update: "update_user",
      deActivate: "delete_user",
      delete_user_brand: "delete_user_brand",
      reset_user_password: "reset_password",
      user_videos: "list_user_videos",
      get_location_fields : "get_location_fields",
      saveLocation : "set_locale",
      get_location_templates : "get_direct_loc_templates",
      get_locations : "get_locales",
      del_location : "delete_locale",
    },
    brand: {
      add: "create_brand",
      getUserBrands: "get_brand",
      getAll: "list_brands",
      addUserBrand: "add_user_brand",
      addbrandMediaLib: "add_brand_media_lib",
      update: "update_brand",
      delete: "delete_brand",
      list_published_videos: "list_brand_published_videos",
      default_brand: "get_default_brand",
      fbAccessToken: "connect_brand_facebook",
      getfbAccessToken: "get_brand_facebook_conn",
      getLinkedInAccessToken: "get_brand_linkedin_conn",
      getInstagramAccessToken: "get_brand_instagram_conn",
      deletefbAccessToken: "del_brand_facebook_conn",
      sharefbAccessToken: "share_brand_facebook",
      shareVideoInstagram: "share_brand_instagram",
      shareVideoInstagramUpload: "insta_brand_upload",
      shareVideoYoutubeBrandUpload: "youtube_brand_upload",
      shareVideoYoutubeUpload: "youtube_upload",
      youTubeAccessToken: "connect_brand_youtube",
      getyouTubeAccessToken: "get_brand_youtube_conn",
      deleteyouTubeAccessToken: "del_brand_youtube_conn",
      shareYouTubeAccessToken: "share_brand_youtube",
      getAllFont: "list_default_fonts"
    },
    visual: {
      getAll: "visuals",
      get: "get_visual",
      delete: "delete_visual",
      getThumbnails: "get_thumbnails",
      signedUrlMultiDownload: "signed_url_multi_download",
      signedUrlForDownload: "signed_url_for_download",
    },
    media: {
      getAll: "list_media_libs",
      add: "create_media_lib",
      addUserMediaLib: "add_user_media_lib",
      getUserMediaLib: "list_users_media_libs",
      get: "get_media_lib",
      delete: "delete_media_lib",
      update: "update_media_lib",
      get_media_lib_users : "list_user_media_libs",
      del_media_lib_users : "delete_user_media_lib",
      list_brand_media_libs: "list_brand_media_libs",
      get_extracted_clips: "get_extracted_clips",
      uv_search_ac: "uv_search_ac",
      visuals_search_ac: "visuals_search_ac",
    },
    industry: {
      getAll: "list_industries"
    },
    fonts: {
      getAll: "list_default_fonts"
    },
    location:{
      search_locales_ac: "search_locales_ac"
    }
  },
};
