import { BrandService } from 'src/app/components/myAccount/manage-brand/service/BrandService';
// import { OnboardingDataService } from './../../services/dataServices/onboarding-data.service';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ChangeDetectorRef, ViewChild, ElementRef,AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { cDataService } from '../../services/data.service';
import { cSessionService } from '../../services/session.service';
import { cWebSocketService } from '../../services/websocket.service';
import { MediaManagerService } from '../../Managers/media-manager.service';
import { mediaLib } from '../../../app/models/media';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { brand, brandList, SearchData } from 'src/app/models/admin';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { MediaService } from 'src/app/services/dataServices/media.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-media-library-popup',
    templateUrl: './media-library-popup.component.html',
    styleUrls: ['./media-library-popup.component.scss'],
})
export class MediaLibraryPopupComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild("videoListAutoComplete", { read: MatAutocompleteTrigger })
    autoCompleteVideo: MatAutocompleteTrigger;
    @ViewChild('searchVideoField') searchVideoField: ElementRef;
    modelVideoSearchChanged = new BehaviorSubject<string>('');
    videoSearchList: any = [];
    isVideoSearchLoading: boolean = false;
    lBreadcrumbOptions: any = [
        { name: 'All', filter: 'all' },
        { name: 'Videos', filter: 'clip' },
        { name: 'Images', filter: 'image' },
        // {name : 'Audio', filter : 'audio'},
        // {name : 'Icons', filter : 'Icons'},
        // {name : 'Text Snippets', filter : 'Snippets'},
    ];
    selectedOption: any;

    sSessionId: string;
    sUserEmail: string;
    sResponseStatus: string;
    fUserVisualListForm: FormGroup;
    fUserVisualCountForm: FormGroup;
    lUserVisualsList: any = [];
    nUserVisualID: number = 0;
    nUserVisualsCount: number;
    nUserImagesCount: number;
    nUserClipsCount: number;
    // nUserAudiosCount: number;
    search: string = '';
    sVisualType: string = 'all';
    nAlreadyFetchedVisuals: number = 0;
    nPageSize: number = 25;
    recordLimit: number = 25;
    lPagination: any = [];
    nCurrentPage: number = 1;
    sSelectedMediaLibrary: string;
    bMediaLoader: boolean = false;
    mediaLibStatus = 'active';
    offset = 0;
    lSelectedUserVisualsList: any = [];
    @Output() lEmittedFilesToUpload = new EventEmitter<any[]>();
    @Input() oInputVisualToRemove: any;
    @Input('bInputEnableMultiSelection') bInputEnableMultiSelection: boolean;
    @Input() isSlideout: boolean = false;

    bSelectionType: boolean = false;
    lMediaLibraryList: any = [];
    lMediaLibraryListCopy: any = [];
    selectedVisualArr: any = [];
    fSearchLibraryForm: FormGroup;
    @Input('sInputLibraryName') sInputLibraryName: any;
    @Input('videoOnly') videoOnly: boolean = false;
    @Input('audioOnly') audioOnly: boolean = false;
    @Input('mediaLoadType') mediaLoadType: string;
    @Input('component') component: string;
    bHideMediaLibrarySearchOptions: boolean = false;
    @Output() onClose = new EventEmitter<any>();
    @Input() mediaPopUpLoc: any;
    @Input() isSingleSelected = false;
    @Input() allMediaLib: boolean = false;
    @Input() applyAspectView: boolean = false;
    public imageStaticThumb = '../assets/images/business_Profile/audio-line.png';
    limit = 20;
    skipVideos = 0;
    currentPage = 1;
    listPages: number[] = [];
    showBrandMediaLibs = false;
    videoBrandId = '';
    selectedLibrary = false;
    sSelectedVideoId: string;
    brandList: brandList;
    oAudio = new Audio();
    @ViewChild('paginator', { static: true }) paginator;   
    sSelectedSortingCategory: string = 'Date Created';
    sSelectedSortingCategoryValue: string = 'created';
    sSelectedGroup: string = 'Month Created';
    sSelectedGroupValue: string = 'month_created';
    lSortBy: any = [
        { name: 'Date Created', value: 'created' },
        { name: "Last Modified", value: "last_modified" },
        { name: 'Name', value: 'video.title' },
        // { name: 'Aspect Ratio', value: 'video.aspect_ratio' },
        // { name: 'FPS', value: 'video.fps' },
        // { name: 'Duration', value: 'video.duration' },
    ];
    lGroupBy: any = [
        { name: 'Month Created', value: 'month_created' },
        { name: 'Year Created', value: 'year_created' },
        { name: 'Month Modified', value: 'month_last_modified' },
        { name: 'Year Modified', value: 'year_last_modified' },
        // { name: 'Aspect Ratio', value: 'aspect_ratio' },
        // { name: 'Drafts', value: 'publish_status' },
        // { name: 'Published', value: 'publish_status' },
    ];
    sortType = -1;
    searchTermText: string = '';
    constructor(
        private oDataService: cDataService,
        private oSessionService: cSessionService,
        public oFormBuilder: FormBuilder,
        public mediaManager: MediaManagerService,
        public _MatPaginatorIntl: MatPaginatorIntl,
        private onBoarding: OnboardingManagerService,
        private changeDetect: ChangeDetectorRef,
        public videoManger: CreateVideoManagerService,
        public brandService: BrandService,
        public prontoService: ProntoSettingService,
        private mediaService: MediaService,
    ) {
        this.mediaManager.visuals = [];
    }
   

    populatePagination() {
        if (this.mediaManager.visuals?.length && this.mediaManager.visuals?.length > 0) {
            const maxPage = Math.ceil(this.mediaManager.visuals?.length / this.nPageSize) || 1;
            if (maxPage > 1) {
                this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);
            } else {
                this.listPages = [1];
            }
        }
    }

    loadPage(pageNumber: number, forceReload = false) {
        if (pageNumber !== this.nCurrentPage || forceReload) {
            this.nCurrentPage = pageNumber;
            let skipVideos = (pageNumber - 1) * this.nPageSize;
            this.offset = skipVideos;
            this.changeDetect.detectChanges(); 
            let libsArray = [];
            if (this.showBrandMediaLibs && !this.selectedLibrary) {
                libsArray = this.mediaManager.selectedmedialibIdArrays;
            }
            this.mediaManager
                .getVisuals(
                    this.sUserEmail,
                    this.sSessionId,
                    this.sVisualType,
                    this.mediaManager.selectedmedialib.lib_id,
                    this.nPageSize,
                    skipVideos,
                    this.sSelectedSortingCategoryValue,
                    this.sSelectedGroupValue,
                    this.search,
                    undefined,
                    libsArray,
                    this.sortType
                )
                .then(() => {
                    if (this.paginator) {
                        this.paginator.pageIndex = this.nCurrentPage > 1 ? this.nCurrentPage - 1 : 0;
                        this.changeDetect.detectChanges();
                    }
                });
        }
    }
 
    ngAfterViewInit(): void {
        console.log("chk trigger");

        this.modelVideoSearchChanged
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(async (value: any) => {
                if (value && value != "" && value.length > 1) {
                    console.log("chk trigger value", value);
                    this.videoSearchList = await this.mediaService
                        .visuals_search_ac(this.sUserEmail, value, this.sVisualType, [this.mediaManager.selectedmedialib.lib_id])
                        .then((resList) => {
                            console.log("chk trigger value list", resList);
                            return (this.videoSearchList = resList);
                        });

                    this.isVideoSearchLoading = false;
                    return this.videoSearchList;
                } else {
                    console.log("chk trigger value list 3", this.videoSearchList);
                    return [];
                }
            });
            console.log("chk trigger value list 2", this.videoSearchList);
    }
    async ngOnInit(): Promise<void> {
        this._MatPaginatorIntl.firstPageLabel = '';
        this._MatPaginatorIntl.lastPageLabel = '';
        this._MatPaginatorIntl.nextPageLabel = '';
        this._MatPaginatorIntl.previousPageLabel = '';

        if (this.onBoarding.profile?.default_media_lib?._id.$oid) {
            this.sSelectedMediaLibrary = this.onBoarding.profile.default_media_lib._id.$oid;
            this.mediaManager.selectedmedialib = this.onBoarding.profile.default_media_lib;
            this.mediaManager.selectedmedialib.lib_id = this.onBoarding.profile.default_media_lib._id.$oid;
        }

        // this.oWebSocketService.WebSocket_CreateWebSocket();
        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
        this.fUserVisualListForm = this.oFormBuilder.group({
            email: this.sUserEmail,
            session_id: this.sSessionId,
            num_skip: 0,
            num_items: this.recordLimit,
            visual_type: '',
            media_lib: this.sSelectedMediaLibrary,
        });
        this.fUserVisualCountForm = this.oFormBuilder.group({
            email: this.sUserEmail,
            session_id: this.sSessionId,
            visual_type: '',
            media_lib: this.sSelectedMediaLibrary,
        });
        if (this.sInputLibraryName) {
            this.bSelectionType = true;
            this.bHideMediaLibrarySearchOptions = true;
            // this.sSelectedMediaLibrary = this.sInputLibraryName;
            this.MediaLibrarySharedComponent_DisplayMyMediaLibrary();
        } else {
            this.bHideMediaLibrarySearchOptions = false;
            this.MediaLibrarySharedComponent_DisplayGetMediaLibraryList();
        }
        //this.oWebSocketService.WebSocket_RegisterEvent('user_visual_list_result', this.MediaLibrarySharedComponent_HandleUserVisualsResponse);

        if (this.videoManger.selectedVideo?.brand_id && this.videoManger.selectedVideo?.brand_id != '') {
            this.showBrandMediaLibs = true;
            this.videoBrandId = this.videoManger.selectedVideo.brand_id;
            await this.getBrandMediaLibList();
        } else {
            // console.log(" LOAD USER MEDIA LIBS ...... <<<<");
            this.mediaManager
                .getUsersMyMediaLibraries({
                    email: this.sUserEmail,
                    session_id: this.sSessionId,
                    status: 'all',
                    name: '',
                })
                .then(() => {
                    if(this.onBoarding.profile.plan == 'enterprise'){
                        this.reloadBrandList();
                    }
                });

            if (this.allMediaLib) {
                // console.log(" ALL MEDIA LIBS ......");
                this.mediaManager.selectedmedialib = this.onBoarding?.profile?.default_media_lib;
            }
        }

        let mediaType = 'all';
        if (this.videoOnly) {
            mediaType = 'clip';
        }
        if (this.audioOnly) {
            mediaType = 'audio';
        }

        if (this.mediaLoadType && this.mediaLoadType != '') {
            mediaType = this.mediaLoadType;
        }
        this.loadVisual(mediaType);
        //for enabling multi selection (by default user can only select single visual)
        if (!this.bInputEnableMultiSelection && this.bInputEnableMultiSelection == false) {
            this.bSelectionType = true;
        }

        this.fSearchLibraryForm = this.oFormBuilder.group({ name: '' });
        this.MediaLibrarySharedComponent_SearchMediaLibrary();

        this.mediaManager.reloadMediaLibVisuals.subscribe((response) => {
            this.loadVisual(this.sVisualType);
        });
    }
    reloadBrandList() {
        let __this = this;
        if (this.brandList) {
            // this.brandList.result = [];
        }
        this.brandService
            .getListBrand(this.sUserEmail, this.sSessionId, 0, 20, 'created', -1, '', 'name', true)
            .then(function (listbrand: any) {
                __this.brandList = listbrand;
                __this.loadBrandMediaLibs();

                // __this.populatePagination();
            });
    }

    loadBrandMediaLibs() {
        this.brandList?.result.map((brand: brand) => {
            this.brandService
                .getUserBrandMediaLibList({
                    brand_id: brand._id.$oid,
                    status: '',
                    name: '',
                })
                .then((res: any) => {

                    // this.mediaManager.mediaLibs = { ...this.mediaManager.mediaLibs, ...res.results };
                    if (res.result) {
                        res.result.map((item) => {
                            let objNew : mediaLib = {
                                activation_status: 'active',
                                name: item.name,
                                lib_id: item._id.$oid ?  item._id.$oid :  item._id,
                                library_access: '',
                                total_items: 0
                            };

                            this.mediaManager.mediaLibs.push(objNew);
                        });
                    }

                    if (res.result) {
                        brand.mediaLibs = res.result;
                    }
                });
        });
    }
    async getBrandMediaLibList() {
        let __this = this;
        const search: SearchData = {
            brand_id: this.videoBrandId,
            offset: this.offset,
            limit: 50,
            asc: -1,
            search_term: '',
            search_by: 'name',
            sort_by: 'created',
        };
        await this.brandService.getBrandMediaLibListSearch(search).then((brandlib: brand) => {
            this.mediaManager.mediaLibs = brandlib.result;
            if (this.mediaManager.mediaLibs) {
                const arryMediaLibsIds = [];
                this.mediaManager.mediaLibs.map((item, index) => {
                    arryMediaLibsIds.push(item._id.$oid);
                });
                this.mediaManager.selectedmedialibIdArrays = arryMediaLibsIds;
            }
            // __this.brandMediaLibList = brandlib.result;
            // __this.populatePagination();
        });
    }

    searchMediaLibrary() {
        this.searchTermText = this.search;
        this.offset = 0;
        this.nCurrentPage = 1;
        this.mediaManager.visualsTotalCount = 0;
        this.loadVisual(this.sVisualType);
    }
    clearSearchTerm() {
        this.searchTermText = '';
        this.search = '';
        this.offset = 0;
        this.nCurrentPage = 1;
        this.mediaManager.visualsTotalCount = 0;
        this.loadVisual(this.sVisualType);
    }
    floatLabel(): 'auto' | 'always' | 'never' {
        // You can conditionally return 'auto', 'always', or 'never' here
        return 'auto';  // Default: it floats when the input has focus or value
    }

    loadVisual(visualType: string = 'all') {
        this.sVisualType = visualType;
        this.nCurrentPage = 1;
        const mediaLibId = this.mediaManager.selectedmedialib?._id?.$oid
            ? this.mediaManager.selectedmedialib?._id?.$oid
            : this.mediaManager.selectedmedialib?.lib_id;
        let searchMediaLibIds = mediaLibId;
        let libsArray = [];
        if (this.showBrandMediaLibs && !this.selectedLibrary) {
            libsArray = this.mediaManager.selectedmedialibIdArrays;
        }
        this.mediaManager
            .getVisuals(
                this.sUserEmail,
                this.sSessionId,
                visualType,
                mediaLibId,
                this.nPageSize,
                this.offset,
                this.sSelectedSortingCategoryValue,
                this.sSelectedGroupValue,
                this.search,
                undefined,
                libsArray,
                this.sortType
            )
            .then((_) => {
                if (this.paginator) {
                    this.paginator.pageIndex = 0;
                    this.changeDetect.detectChanges();
                }
                // this.createPagination();
            });
    }
    // loadVisual(visualType: string) {
    //   if (this.allMediaLib) {
    //     this.mediaManager.selectedmedialib = undefined;

    //   }

    //   if (this.mediaPopUpLoc) {
    //     this.sVisualType = visualType;

    //     this.mediaManager
    //       .getVisuals(
    //         this.sUserEmail,
    //         this.sSessionId,
    //         "clip",
    //         this.mediaManager.selectedmedialib
    //           ? this.mediaManager.selectedmedialib.lib_id
    //           : "",
    //         this.nPageSize,
    //         0,
    //         this.search
    //       )
    //       .then((_) => {
    //         this.createPagination();
    //         // this.populatePagination();

    //       });
    //   } else {
    //     this.sVisualType = visualType;
    //     this.mediaManager
    //       .getVisuals(
    //         this.sUserEmail,
    //         this.sSessionId,
    //         visualType,
    //         this.mediaManager.selectedmedialib
    //           ? this.mediaManager.selectedmedialib.lib_id
    //           : "",
    //         this.nPageSize,
    //         0,
    //         this.search
    //       )
    //       .then((_) => {
    //         this.createPagination();
    //       // this.populatePagination();
    //       });
    //   }
    // }

    ngOnChanges(): void {
        if (this.oInputVisualToRemove) {
            for (let nSlectedVisualIndex = 0; nSlectedVisualIndex < this.lUserVisualsList.length; nSlectedVisualIndex++) {
                if (this.lUserVisualsList[nSlectedVisualIndex].visual_path === this.oInputVisualToRemove.visual_path) {
                    this.lUserVisualsList[nSlectedVisualIndex].checked = false;
                }
            }
        }
    }
    MediaLibrarySharedComponent_DisplayGetMediaLibraryList() {
        //this.oWebSocketService.WebSocket_RegisterEvent('user_media_libs_result', this.MediaLibrarySharedComponent_HandleMediaLibraryListResponse);
        // this.oWebSocketService.WebSocket_SendMessage('user_media_libs_request', JSON.stringify({email : this.sUserEmail,session_id : this.sSessionId}));
    }
    MediaLibrarySharedComponent_HandleMediaLibraryListResponse = (IncomingMessage) => {
        if (JSON.parse(IncomingMessage).errorMessage) {
        } else {
            let oFormatedIncommingMessage = JSON.parse(IncomingMessage);
            this.lMediaLibraryList = oFormatedIncommingMessage.media_libs;
            this.lMediaLibraryListCopy = JSON.parse(JSON.stringify(this.lMediaLibraryList));
            this.sSelectedMediaLibrary = this.lMediaLibraryList[0].name ? this.lMediaLibraryList[0].name : 'default';
            this.MediaLibrarySharedComponent_DisplayMyMediaLibrary();
        }
        //this.oWebSocketService.WebSocket_UnRegisterEvent('user_media_libs_result', this.MediaLibrarySharedComponent_HandleMediaLibraryListResponse);
    };
    MediaLibrarySharedComponent_DisplayMyMediaLibrary() {
        //  this.oWebSocketService.WebSocket_RegisterEvent('visual_count_result', this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse);
        // this.MediaLibrarySharedComponent_GetUserImagesCount();
        // this.MediaLibrarySharedComponent_GetUserClipsCount();
        // this.MediaLibrarySharedComponent_GetUserAudioCount();
    }

    MediaLibrarySharedComponent_ChangeMedialibrary(event: any, selectedMedia) {
        const bChecked = event.target.checked;
        this.selectedLibrary = true;
        this.mediaManager.selectedmedialib = selectedMedia;
        // if (this.mediaPopUpLoc) {
        this.loadVisual(this.sVisualType);
        // this.mediaManager
        //   .getVisuals(
        //     this.sUserEmail,
        //     this.sSessionId,
        //     "clip",
        //     this.mediaManager.selectedmedialib
        //       ? this.mediaManager.selectedmedialib.lib_id
        //       : "",
        //     this.nPageSize,
        //     0,
        //     this.search
        //   )
        //   .then((_) => {
        //     this.createPagination();
        //   // this.populatePagination();
        //   });
        // } else {
        //   this.mediaManager
        //     .getVisuals(
        //       this.sUserEmail,
        //       this.sSessionId,
        //       this.sVisualType,
        //       this.mediaManager.selectedmedialib
        //         ? this.mediaManager.selectedmedialib.lib_id
        //         : "",
        //       this.nPageSize,
        //       0,
        //       this.search
        //     )
        //     .then((_) => {
        //       this.createPagination();
        //     // this.populatePagination();
        //     });
        // }

        const sValue = event.target.value;
        if (bChecked) {
            if (this.sSelectedMediaLibrary !== sValue) {
                this.sSelectedMediaLibrary = sValue;
                this.MediaLibrarySharedComponent_DisplayMyMediaLibrary();
                //if user stays on same tab then we have to reset view

                this.nCurrentPage = 1;
                this.nUserImagesCount = undefined;
                this.nUserClipsCount = undefined;
                this.nUserVisualsCount = undefined;
            }
        }
    }

    createPagination() {
        // console.log("this.paginator.", this.paginator);
        // if(this.paginator){

        //   this.paginator.pageIndex = this.nCurrentPage;
        // }
        this.lPagination = [];
        let pages = Math.ceil(this.mediaManager.visualsCount / this.recordLimit);
        for (let nPaginationIndex = 1; nPaginationIndex <= pages; nPaginationIndex++) {
            this.lPagination.push(nPaginationIndex);
        }
    }

    selectedVisual(visual) {
        visual.isSelected = !visual.isSelected;
        this.selectedVisualArr.push[visual];
    }

    MediaLibrarySharedComponent_SearchMediaLibrary() {
        this.fSearchLibraryForm
            .get('name')
            .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((data) => {
                if (data == '') {
                    this.lMediaLibraryList = JSON.parse(JSON.stringify(this.lMediaLibraryListCopy));
                } else {
                    this.lMediaLibraryList = this.lMediaLibraryListCopy.filter((library) => {
                        return library.name.indexOf(data) !== -1;
                    });
                }
            });
    }

    MediaLibrarySharedComponent_FetchPage(nIncommingPageNumber: number) {
        this.nCurrentPage = nIncommingPageNumber;
        let skipVideos = 0;

        this.loadVisual(this.sVisualType);
        // if (this.mediaPopUpLoc) {
        //   this.mediaManager
        //     .getVisuals(
        //       this.sUserEmail,
        //       this.sSessionId,
        //       "clip",
        //       this.mediaManager.selectedmedialib
        //         ? this.mediaManager.selectedmedialib.lib_id
        //         : "",
        //       this.nPageSize,
        //       skipVideos,
        //       this.search
        //     )
        //     .then();
        // } else {
        //   this.mediaManager
        //     .getVisuals(
        //       this.sUserEmail,
        //       this.sSessionId,
        //       this.sVisualType,
        //       this.mediaManager.selectedmedialib
        //         ? this.mediaManager.selectedmedialib.lib_id
        //         : "",
        //       this.nPageSize,
        //       skipVideos,
        //       this.search
        //     )
        //     .then();
        // }
    }
    MediaLibrarySharedComponent_ResetView() {
        this.lUserVisualsList = [];
        // this.lSelectedUserVisualsList = [];
        this.nAlreadyFetchedVisuals = 0;
        this.nUserVisualID = 0;
        this.fUserVisualListForm = this.oFormBuilder.group({
            email: this.sUserEmail,
            session_id: this.sSessionId,
            num_skip: 0,
            num_items: this.recordLimit,
            visual_type: '',
            media_lib: this.sSelectedMediaLibrary,
        });
    }
    MediaLibrarySharedComponent_GetUserImage(nIemsToFetch: number, nItemsToSkip: number) {
        this.fUserVisualListForm.patchValue({
            visual_type: 'image',
            num_items: nIemsToFetch,
            num_skip: nItemsToSkip,
            media_lib: this.sSelectedMediaLibrary,
        });
        //  this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
    }
    MediaLibrarySharedComponent_GetUserClips(nIemsToFetch: number, nItemsToSkip: number) {
        this.fUserVisualListForm.patchValue({
            visual_type: 'clip',
            num_items: nIemsToFetch,
            num_skip: nItemsToSkip,
            media_lib: this.sSelectedMediaLibrary,
        });
        //  this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
    }
    // MediaLibrarySharedComponent_GetUserAudios(nIemsToFetch : number, nItemsToSkip : number)
    // {
    //   this.fUserVisualListForm.patchValue({visual_type: 'audio', num_items : nIemsToFetch, num_skip : nItemsToSkip,media_lib: this.sSelectedMediaLibrary});
    //   this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
    // }
    MediaLibrarySharedComponent_GetUserImagesCount() {
        this.fUserVisualCountForm.patchValue({
            visual_type: 'image',
            media_lib: this.sSelectedMediaLibrary,
        });
        //   this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
    }
    MediaLibrarySharedComponent_GetUserClipsCount() {
        this.fUserVisualCountForm.patchValue({
            visual_type: 'clip',
            media_lib: this.sSelectedMediaLibrary,
        });
        //   this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
    }
    // MediaLibrarySharedComponent_GetUserAudioCount()
    // {
    //   this.fUserVisualCountForm.patchValue({ visual_type: 'audio'});
    //   this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
    // }
    MediaLibrarySharedComponent_SelectMediaType(oIncommingMediaCategory: any) {
        if (this.sVisualType !== oIncommingMediaCategory) {
            this.sVisualType = oIncommingMediaCategory;
            this.MediaLibrarySharedComponent_ResetView();

            // this.loadPage(1, true);
            this.offset = 0;
            this.nCurrentPage = 1;
            this.mediaManager.visualsTotalCount = 0;
            this.loadVisual(this.sVisualType);
        }
    }
    BusinessProfileSidepanelsComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    MediaLibrarySharedComponent_HandleUserVisualsResponse = async (IncomingMessage) => {
        if (IncomingMessage.errorMessage) {
            console.error('MediaLibrarySharedComponent_HandleUserVisualsResponse : Error ==> ', JSON.parse(IncomingMessage.errorMessage));
        } else {
            let formatedIcomingMessage = JSON.parse(IncomingMessage);
            this.bMediaLoader = true;
            if (formatedIcomingMessage.length !== 0) {
                for (let nVisualToPushIndex = 0; nVisualToPushIndex < formatedIcomingMessage.length; nVisualToPushIndex++) {
                    await this.MediaLibrarySharedComponent_GenerateDisplayList(formatedIcomingMessage[nVisualToPushIndex]);
                }
                // this.MediaLibrarySharedComponent_InitializeJqueryCode();
            } else {
                console.log('You have got a empty array in the response');
            }
            this.bMediaLoader = false;
        }
    };

    async MediaLibrarySharedComponent_GenerateDisplayList(oIncommingVisual: any) {
        const imageStaticThumb = '../assets/images/business_Profile/audio-line.png';
        this.nUserVisualID = this.nUserVisualID + 1;
        let visual_path = oIncommingVisual.visual_path;
        let filename = visual_path.substr(visual_path.indexOf('.com/') + 5, visual_path.length);
        let key_terms = oIncommingVisual.key_terms ? oIncommingVisual.key_terms : [];
        let url = await this.oDataService.DataService_GetSignedURLForDownload(visual_path);
        let visual_type = oIncommingVisual.visual_type;
        let thumbnail =
            !oIncommingVisual.thumb && visual_type !== 'audio'
                ? await this.oDataService.DataService_GetUserVisualThumbnail(visual_path)
                : oIncommingVisual.thumb;
        let size = oIncommingVisual.size;
        let duration = oIncommingVisual.duration;
        let library = oIncommingVisual.media_lib;
        let used_in = oIncommingVisual.videos_used_in ? oIncommingVisual.videos_used_in : [];
        let checked: boolean = false;
        let type = this.bSelectionType == true ? 'radio' : 'checkbox';
        let sThumbnail: string;
        if (visual_type == 'audio') {
            sThumbnail = imageStaticThumb;
        } else {
            sThumbnail = await this.oDataService.DataService_GetSignedURLForDownload(thumbnail);
        }
        if (this.sVisualType === 'image' && visual_type === 'image') {
            this.lUserVisualsList.unshift({
                id: this.nUserVisualID,
                thumb: sThumbnail,
                url: url,
                visual_path: visual_path,
                key_terms: key_terms,
                visual_type: visual_type,
                name: filename,
                size: size,
                library: library,
                used_in: used_in,
                checked: checked,
                duration: duration,
                type: type,
            });
        } else if (this.sVisualType === 'clip' && visual_type === 'clip') {
            this.lUserVisualsList.unshift({
                id: this.nUserVisualID,
                thumb: sThumbnail,
                url: url,
                visual_path: visual_path,
                key_terms: key_terms,
                visual_type: visual_type,
                name: filename,
                size: size,
                library: library,
                used_in: used_in,
                checked: checked,
                duration: duration,
                type: type,
            });
        } else if (this.sVisualType === 'all') {
            this.lUserVisualsList.unshift({
                id: this.nUserVisualID,
                thumb: sThumbnail,
                url: url,
                visual_path: visual_path,
                key_terms: key_terms,
                visual_type: visual_type,
                name: filename,
                size: size,
                library: library,
                used_in: used_in,
                checked: checked,
                duration: duration,
                type: type,
            });
        }
    }

    MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse = (IncomingMessage) => {
        if (IncomingMessage.errorMessage) {
            console.error(
                'MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Error ==> ',
                JSON.parse(IncomingMessage.errorMessage),
            );
        } else {
            let formatedIcomingMessage = JSON.parse(IncomingMessage);
            if (formatedIcomingMessage.visual_type == 'image') {
                this.nUserImagesCount = formatedIcomingMessage.count;
            } else if (formatedIcomingMessage.visual_type == 'clip') {
                this.nUserClipsCount = formatedIcomingMessage.count;
            }
            // else if (formatedIcomingMessage.visual_type == 'audio')
            // {
            //   this.nUserAudiosCount = formatedIcomingMessage.count;
            // }
            // this.nUserVisualsCount = this.nUserImagesCount + this.nUserClipsCount + this.nUserAudiosCount;
            this.nUserVisualsCount = this.nUserImagesCount + this.nUserClipsCount;
            if (this.nUserVisualsCount) {
                this.MediaLibrarySharedComponent_SelectMediaType('all');
                //   this.oWebSocketService.WebSocket_UnRegisterEvent('visual_count_result', this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse);
            }
        }
    };
    MediaLibrarySharedComponent_DrawPagination(nIncommingVisualCount: number) {
        this.lPagination = [];
        this.nPageSize = Math.ceil(nIncommingVisualCount / this.recordLimit);
        for (let nPaginationIndex = 1; nPaginationIndex <= this.nPageSize; nPaginationIndex++) {
            this.lPagination.push(nPaginationIndex);
        }
    }
    MediaLibrarySharedComponent_PushSelectedVisual(oIncommingVisual: any) {
        if (this.isSingleSelected) {
            this.mediaManager.visuals.forEach((element) => {
                element.isSelected = false;
            });

            this.lSelectedUserVisualsList = [];
        }

        //pass single values

        oIncommingVisual.isSelected = !oIncommingVisual.isSelected;
        if (this.bSelectionType) {
            this.mediaManager.visuals.forEach((c) => {
                if (c.visual_path != oIncommingVisual.visual_path) {
                    c.isSelected = false;
                } else {
                    console.log(oIncommingVisual);
                }
            });
            this.lSelectedUserVisualsList = [];
            this.lSelectedUserVisualsList.push(oIncommingVisual);
            for (let nSlectedVisualIndex = 0; nSlectedVisualIndex < this.lSelectedUserVisualsList.length; nSlectedVisualIndex++) {
                if (this.lSelectedUserVisualsList[nSlectedVisualIndex].visual_path === oIncommingVisual.visual_path) {
                    this.lSelectedUserVisualsList[nSlectedVisualIndex].isSelected = true;
                } else {
                    this.lSelectedUserVisualsList[nSlectedVisualIndex].isSelected = false;
                }
            }

            // oIncommingVisual.isSelected = !oIncommingVisual.isSelected;
            this.MediaLibrarySharedComponent_PassSelectedVisual(false);
        }
        //pass multiple values
        else {
            let nVisualIndex = this.lSelectedUserVisualsList.indexOf(oIncommingVisual);
            if (nVisualIndex == -1) {
                this.lSelectedUserVisualsList.push(oIncommingVisual);
                for (let nSlectedVisualIndex = 0; nSlectedVisualIndex < this.lSelectedUserVisualsList.length; nSlectedVisualIndex++) {
                    if (this.lSelectedUserVisualsList[nSlectedVisualIndex].visual_path === oIncommingVisual.visual_path) {
                        this.lSelectedUserVisualsList[nSlectedVisualIndex].checked =
                            !this.lSelectedUserVisualsList[nSlectedVisualIndex].checked;
                    }
                }
            } else {
                for (let nSlectedVisualIndex = 0; nSlectedVisualIndex < this.lSelectedUserVisualsList.length; nSlectedVisualIndex++) {
                    if (this.lSelectedUserVisualsList[nSlectedVisualIndex].visual_path === oIncommingVisual.visual_path) {
                        this.lSelectedUserVisualsList[nSlectedVisualIndex].checked =
                            !this.lSelectedUserVisualsList[nSlectedVisualIndex].checked;
                    }
                }
                this.lSelectedUserVisualsList.splice(nVisualIndex, 1);
            }
        }
    }
    MediaLibrarySharedComponent_PassSelectedVisual(selectClicked) {
        this.lSelectedUserVisualsList.component = this.component;
        this.lEmittedFilesToUpload.emit(this.lSelectedUserVisualsList);
        if (selectClicked) {
            this.closeMediaModal();
        }
    }

    closeMediaModal(): void {
        this.onClose.emit();
        this.lSelectedUserVisualsList = [];
        this.mediaManager.visuals.forEach((c) => {
            c.isSelected = false;
        });
    }

    loadPageEvent($event) {
        if ($event.pageSize != this.nPageSize) {
            this.nPageSize = $event.pageSize;
            this.loadPage(1, true);
            this.offset = 0;
        } else {
            this.loadPage($event.pageIndex + 1);
        }
    }

    reloadVisual() {
        if (this.mediaPopUpLoc) {
            this.mediaManager
                .getVisuals(
                    this.sUserEmail,
                    this.sSessionId,
                    'clip',
                    this.mediaManager.selectedmedialib ? this.mediaManager.selectedmedialib.lib_id : '',
                    this.nPageSize,
                    this.skipVideos,
                    this.sSelectedSortingCategoryValue,
                    this.sSelectedGroupValue,
                    this.search,
                    false,
                    [],
                    this.sortType
                )
                .then();
        } else {
            this.mediaManager
                .getVisuals(
                    this.sUserEmail,
                    this.sSessionId,
                    this.sVisualType,
                    this.mediaManager.selectedmedialib ? this.mediaManager.selectedmedialib.lib_id : '',
                    this.nPageSize,
                    this.skipVideos,
                    this.sSelectedSortingCategoryValue,
                    this.sSelectedGroupValue,
                    this.search,
                    false,
                    [],
                    this.sortType
                )
                .then();
        }
    }
    filterLibraryName(libraries: any): any {
        if (this.fSearchLibraryForm && this.fSearchLibraryForm.controls['name'].value != '') {
            let filteredName = libraries.filter((library) => {
                return library.name.indexOf(this.fSearchLibraryForm.controls['name'].value) > -1;
            });
            return filteredName;
        } else {
            // console.log(" libraries ",libraries );
            return libraries;
        }
    }

    PlayVideo(videoItem, videoClass) {
        this.sSelectedVideoId = videoItem.fpath;
        let __this = this;
        setTimeout(() => {
            __this.mediaManager.visuals.forEach((element, i) => {
                if (document.getElementsByClassName(videoClass)) {
                    var video = <HTMLVideoElement>document.getElementsByClassName('play-video-id-' + i)[0];
                    video.pause();
                    // console.log('Video Path ', element, element.fpath, __this.sSelectedVideoId);
                    if (element.fpath == __this.sSelectedVideoId) {
                        // console.log(' PLay Video here ....');
                        video.play();
                    }
                }
            });
        }, 100);
    }

    returnFileName(filename) {
        var split = filename.split('.');
        var filename = split[0];
        var extension = split[1];
        if (filename.length > 15) {
            filename = filename.substring(0, 14);
        }
        var result = filename + '.' + extension;
        return result;
    }
    AudioSidePanelComponent_PlayAudio(oIncommingAudioUrl) {
        // console.log(oIncommingAudioUrl);
        this.oAudio = new Audio();
        this.oAudio.src = oIncommingAudioUrl;
        this.oAudio.load();
        this.oAudio.play();
    }
    AudioSidePanelComponent_StopAudio(oIncommingAudioUrl) {
        let filename = oIncommingAudioUrl.replace(/^.*(\\|\/|\:)/, '');
        if (this.oAudio.src.replace(/^.*(\\|\/|\:)/, '') == filename) {
            this.oAudio.pause();
            this.oAudio = new Audio();
            this.oAudio.src = oIncommingAudioUrl;
            this.oAudio.load();
            this.oAudio.pause();
        }
    }
    clearSearchParams() {
        this.offset = 0;
        this.nCurrentPage = 1;
        this.mediaManager.visualsTotalCount = 0;
    }
    sortBySearch(sortingValue: any) {
        if (this.sSelectedSortingCategory != sortingValue.name) {
            this.sSelectedSortingCategory = sortingValue.name;
            this.sSelectedSortingCategoryValue = sortingValue.value;
        this.clearSearchParams();
        this.loadVisual(this.sVisualType);
        }
    }
    groupBySearch(groupingValue: any) {
        if (this.sSelectedGroup != groupingValue.name) {
            this.sSelectedGroup = groupingValue.name;
            this.sSelectedGroupValue = groupingValue.value;
        this.clearSearchParams();
        this.loadVisual(this.sVisualType);
        }
    }
    sortTypeSearch(val: number) {
        this.clearSearchParams();
        this.sortType = val;
        this.loadVisual(this.sVisualType);
    }
}
