<div class="h-85vh br12 curve-bg tab-content bg-white page-shadow pb-0" id="pills-tabContent">
    <div class="tab-pane fade show active">
        <div class="content_area pb-4 border-bottom">
            <ul class="process_tabs nav">
                <li class="nav-item" role="presentation">
                    <button class="nav-link" [ngClass]="sSelectedTab==='video content'?'new':''"
                        (click)="ContentComponent_CheckVideoContentTab()" id="Video-Content-tab" data-bs-toggle="pill"
                        data-bs-target="#Video-Content" type="button" role="tab" aria-controls="Video-Content"
                        aria-selected="false">
                        <img src="../../../../assets/images/svg/video content.svg">
                        Video Content</button>
                </li>
                <!-- <li class="nav-item" role="presentation">
                    <button class="nav-link" [ngClass]="sSelectedTab==='text overlays'?'new':''"
                        (click)="ContentComponent_CheckTextOverlaysTab()" id="Text-Overlays-tab" data-bs-toggle="pill"
                        data-bs-target="#Text-Overlays" type="button" role="tab" aria-controls="Text-Overlays"
                        aria-selected="false"><svg width="21" height="15" viewBox="0 0 21 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.868 7.14H15.22L14.548 9H13.396L16.42 0.684H17.68L20.692 9H19.54L18.868 7.14ZM18.556 6.252L17.044 2.028L15.532 6.252H18.556Z"
                                fill="#483D8F"></path>
                            <path
                                d="M5.4 1.28H9.04L14.4 15H11.24L10.26 12.38H4.02L3.04 15H0.04L5.4 1.28ZM9.34 9.96L8.04 6.48C7.88 6.05333 7.59333 5.15333 7.18 3.78H7.08C6.66667 5.15333 6.38 6.05333 6.22 6.48L4.92 9.96H9.34Z"
                                fill="#FF8369"></path>
                        </svg>Text Overlays</button>
                </li>
                <li class="nav-item" role="presentation" (click)="ContentComponent_CheckVoiceOverTextTab()">
                    <button class="nav-link" [ngClass]="sSelectedTab==='voiceover text'?'new':''"
                        id="Voiceover-text-tab" data-bs-toggle="pill" data-bs-target="#Voiceover-text" type="button"
                        role="tab" aria-controls="Voiceover-text" aria-selected="true"><svg width="15" height="14"
                            viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.825413 10.1808C0.374187 10.1808 0 9.80661 0 9.35538V4.63401C0 4.18278 0.374187 3.80859 0.825413 3.80859C1.27664 3.80859 1.65083 4.18278 1.65083 4.63401V9.35538C1.65083 9.81761 1.27664 10.1808 0.825413 10.1808Z"
                                fill="#FF8369"></path>
                            <path
                                d="M4.12717 11.7544C3.67595 11.7544 3.30176 11.3802 3.30176 10.929V3.07102C3.30176 2.61979 3.67595 2.24561 4.12717 2.24561C4.5784 2.24561 4.95258 2.61979 4.95258 3.07102V10.929C4.95258 11.3912 4.5784 11.7544 4.12717 11.7544Z"
                                fill="#FF8369"></path>
                            <path
                                d="M7.42893 13.3282C6.9777 13.3282 6.60352 12.954 6.60352 12.5028V1.49729C6.60352 1.04606 6.9777 0.671875 7.42893 0.671875C7.88015 0.671875 8.25434 1.04606 8.25434 1.49729V12.5028C8.25434 12.954 7.88015 13.3282 7.42893 13.3282Z"
                                fill="#FF8369"></path>
                            <path
                                d="M10.7307 11.7544C10.2795 11.7544 9.90527 11.3802 9.90527 10.929V3.07102C9.90527 2.61979 10.2795 2.24561 10.7307 2.24561C11.1819 2.24561 11.5561 2.61979 11.5561 3.07102V10.929C11.5561 11.3912 11.1819 11.7544 10.7307 11.7544Z"
                                fill="#FF8369"></path>
                            <path
                                d="M14.0324 10.1808C13.5812 10.1808 13.207 9.80661 13.207 9.35538V4.63401C13.207 4.18278 13.5812 3.80859 14.0324 3.80859C14.4837 3.80859 14.8579 4.18278 14.8579 4.63401V9.35538C14.8579 9.81761 14.4837 10.1808 14.0324 10.1808Z"
                                fill="#FF8369"></path>
                        </svg>
                        Voiceover text</button>
                </li> -->
            </ul>
        </div>


        <div class="tab-content" style="overflow-y: auto; height: 630px;">
            <!-- ------------------------------ Video Content -------------------------------------- -->

            <div class="fade" [ngClass]="sSelectedTab==='video content'?'show fade':'d-none'" role="tabpanel"
                aria-labelledby="Video-Content-tab">
                <div class="content_area">
                    <div class="row">

                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <div class="d-flex align-items-start check_toggle_content">
                                <div class="check_toggle">
                                    <div class="form-switch toggle_switch">
                                        <input class="form-check-input" type="checkbox" [checked]="bLetProntoChoose"
                                            id="flexSwitchCheckChecked"
                                            (change)="ContentComponent_ToggleLetProntoChoose($event)">
                                    </div>
                                </div>
                                <div class="check_content w-100">
                                    <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">Let Prontopia Choose</p>
                                    <p class="text-xs text-gray-3 pb-1">We'll select clips for your video based on the
                                        category,
                                        tags, and other information you've provided.</p>
                                        <p *ngIf="bLetProntoChoose && (!this.videoManager.selectedVideo.key_terms || this.videoManager.selectedVideo.key_terms.length == 0)"
                                            class="text-xs pb-1 text-danger fw-bold">
                                            Oops! You haven't entered any keywords yet. <br> Please go back and add keywords for Prontopia to find relevant
                                            clips.
                                        </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <div class="d-flex align-items-start check_toggle_content">
                                <div class="check_toggle">
                                    <div class="form-switch toggle_switch">
                                        <input class="form-check-input" type="checkbox" id="MyMediaLibrary"
                                            [checked]="bDisplayMyMediaSection"
                                            (change)="ContentComponent_ToggleMyMediaLibrary($event)">
                                    </div>
                                </div>
                                <div class="check_content active check_content0 w-100">
                                    <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">My Media Library</p>
                                    <p class="text-xs text-gray-3 pb-1">We'll piece together content you've uploaded to
                                        create your video.</p>
                                    <div class="mb-4" *ngIf="bDisplayMyMediaSection">
                                        <a class="select-file btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad mt-3"
                                            (click)="showMediaModal()">
                                            <img src="../../../../assets/images/svg/media.svg">
                                            &nbsp; Select a file
                                        </a>
                                    </div>
                                    <div class="media_list" *ngIf="bDisplayMyMediaSection">
                                        <ul>
                                            <li class="mb-3" *ngFor="let visual of lUserSelectedVisuals">
                                                <figure class="media_figure">
                                                    <span class="media">
                                                        <img [src]="visual.thumbnail_signed_url" alt="Media Library"></span>
                                                    <figcaption>
                                                        <div class="caption w-100">
                                                            <p class="text-xs title">{{visual.visual_path | slice:0:30}}
                                                            </p>
                                                            <p class="text-xs sub_title">
                                                                {{ContentComponent_ReturnFileSize(visual.size)}}</p>
                                                        </div>
                                                        <div class="cta d-flex align-items-center"
                                                            (click)="removeSelectedVisuals(visual)">
                                                            <a href="javascript:void(0)" class=" me-3">
                                                                <img src="../../../../assets/images/Delete.svg"
                                                                    style="height: 16px; width: 18px;">
                                                            </a>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-4">
                            <!-- upload success section -->
                            <div class="d-flex align-items-start check_toggle_content">
                                <div class="check_toggle">
                                    <div class="form-switch toggle_switch">
                                        <input class="form-check-input" type="checkbox" id="UploadMyOwn"
                                            (change)="ContentComponent_ToggleFileUploadSection($event)">
                                    </div>
                                </div>
                                <div class="check_content active check_content1 w-100">
                                    <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">Upload My Own...</p>
                                    <p class="text-xs text-gray-3 pb-1">Upload your own clips and images for Prontopia to
                                        use
                                        in your video. These files will also be added to your
                                        Media Library.</p>
                                    <div class="mb-4" *ngIf="bDisplayUploadSection">
                                        <button (click)="openFileUploadModal('image_video', 'Upload Media', 'media')"
                                            class="upload btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow">
                                            <img src="../../../../assets/images/svg/upload.svg">
                                            &nbsp; Upload a file
                                        </button>
                                    </div>

                                    <div class="media_list" *ngIf="bDisplayUploadSection">
                                        <ul>
                                            <li class="mb-3" *ngFor="let file of lUploadedFiles">
                                                <figure class="media_figure">
                                                    <span class="media">
                                                        <img [src]="file.thumb" alt="Media Library"></span>
                                                    <figcaption>
                                                        <div class="caption w-100">
                                                            <p class="text-xs title">{{file.filename | slice:0:30}}</p>
                                                            <p class="text-xs sub_title">
                                                                {{ContentComponent_ReturnFileSize(file.size)}}</p>
                                                        </div>
                                                        <div class="cta d-flex align-items-center">
                                                            <a class=" me-3"
                                                                (click)="ContentComponent_RemoveFileFromList(file.fpath)">

                                                                <img src="../../../../assets/images/Delete.svg"
                                                                    style="height: 16px; width: 18px;">
                                                            </a>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <!-- upload failed section -->
                            <div class="d-flex align-items-start check_toggle_content" *ngIf="lFailedToUpload.length>0">
                                <div class="check_toggle">
                                    <div class="form-switch toggle_switch">
                                        <input class="form-check-input" type="checkbox" id="UploadMyOwn"
                                            (change)="ContentComponent_ToggleFailedToUploadSection($event)">
                                    </div>
                                </div>
                                <div class="check_content active check_content1 w-100">
                                    <p class="text-sm text-gray-2 pb-1 mb-0 primaryFont">Failed to upload Visuals</p>
                                    <div class="media_list" *ngIf="bDisplayFailedToUploadSection">
                                        <ul>
                                            <li class="mb-3" *ngFor="let file of lFailedToUpload">
                                                <figure class="media_figure">
                                                    <span class="media">
                                                        <img [src]="sanitizeImageUrl(readURL(file))"
                                                            *ngIf="file.type !=='video/mp4'" alt="Media Library">
                                                        <video style="pointer-events: none;"
                                                            *ngIf="file.type==='video/mp4'">
                                                            <source [src]="sanitizeImageUrl(readURL(file))"
                                                                type="video/mp4">
                                                        </video>
                                                    </span>
                                                    <figcaption>
                                                        <div class="caption w-100">
                                                            <p class="text-xs title text-gray-12">{{file.name |
                                                                slice:0:30}}</p>
                                                            <p class="text-xs sub_title text-primary-3">File Error</p>
                                                        </div>
                                                        <div class="cta d-flex align-items-center">
                                                            <a class=" me-3"
                                                                (click)="ContentComponent_RemoveFailedFileFileFromList(file.name)">

                                                                <img src="../../../../assets/images/Delete.svg"
                                                                    style="height: 16px; width: 18px;">
                                                            </a>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>




        <div class="wtermarklogo">
            <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
        </div>
    </div>
</div>



<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="visible" nzCentered [nzWidth]="850" [nzFooter]=null [nzClosable]=true
    (nzOnCancel)="closeMediaModal()">
    <app-media-library-popup (lEmittedFilesToUpload)="mediaSelected($event)"></app-media-library-popup>
</nz-modal>
<!-- Modal pop-up End-->

<!-- upload media -->
<!-- <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a File</h5>
                <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <span class="file-message" *ngIf="nFileToUploadIndex"> uploaded.</span>
                                <input #file *ngIf="!bUploadVoiceOverFile" class="file-input" multiple type="file"
                                    accept="image/*, video/*" (change)="ContentComponent_SelectFilesForUpload($event)">
                            </div>
                        </div>


                        <ul class="d-flex mb-3" id="pills-tab" role="tablist">
                            <li class="col" role="presentation">
                                <button (click)="fileClick()" id="pills-device-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device"
                                    aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span>
                                </button>

                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- error modal -->
<div class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4">sDisplayResponseMessage</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                    fill="#FF8369"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="28" height="29" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>


                    </div>
                    <div class="col-11 p-0">

                        <p class="text-sm text-gray-3 mb-0 ms-2">sDisplayResponseMessage</p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    routerLink="/dashboard/myvideos">Go Back</a>

            </div>
        </div>
    </div>
</div>
<!-- error modal end -->