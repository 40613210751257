import { Subject } from 'rxjs';
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { visitAll } from '@angular/compiler';
import { EventEmitter, Injectable } from '@angular/core';
import { id } from '@swimlane/ngx-charts';
import { Config, element, promise } from 'protractor';
import { SearchData } from '../models/admin';

import { mediaLib, visual, mediaFile, clip } from '../models/media';
import { MediaService } from '../services/dataServices/media.service';
import { MyAccountService } from '../services/dataServices/my-account.service';
import { ErrorService } from '../services/error.service';
import { LoaderService } from '../services/loader.service';
import { cSessionService } from '../services/session.service';
import { ConfigManagerService } from './config-manager.service';
import { OnboardingManagerService } from './onboarding-manager.service';

@Injectable({
    providedIn: 'root',
})
export class MediaManagerService {
    visualsData: Object;
    mediaList: any;

    constructor(
        public dataservice: MediaService,
        public loader: LoaderService,
        public config: ConfigManagerService,
        public sessionService: cSessionService,
        public accountSettingService: MyAccountService,
        public errorService: ErrorService,
        public onboardingService: OnboardingManagerService,
    ) {}
    public selectedmedialib: mediaLib;
    public selectedmedialibIdArrays: string[];
    public mediaLibs: mediaLib[] = [];

    public uploadMediaList: mediaFile[] = [];
    public visuals: visual[] | any = [];
    public selectedVisual: visual;
    public selectedVisuals: visual[] = [];
    public visualsCount = 0;
    public visualsTotalCount = 0;
    public selectedPage = '';
    public duplicateImageFpath = '';
    public duplicateImageFpathSignedURL = '';
    public duplicatedImageReplaceEmitter: EventEmitter<string> = new EventEmitter();

    // Media Modal Popup Params
    public replaceMediaFromVisualPopup: EventEmitter<string> = new EventEmitter();
    public replacedMediaComponent= 'default';
    public replaceMediaTypeFromVisualPopup: any;
    public showMediaReplacementPopup = false;
    public mediaModelOiotns = {
        hideAvi : false,
    }
    // Media Model Popup params Ends here

    replaceMediaListener($event: any) {
        this.replaceMediaFromVisualPopup.emit($event);
    }

    openMediaReplacementPopup() {
        this.showMediaReplacementPopup = true;
    }

    // use on media-library-popup to reload data
    reloadMediaLibVisuals: Subject<boolean> = new Subject<boolean>();

    async getMediaLibraries(email, session_id, mediaLibStatus): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getMediaLibrary(email, session_id, mediaLibStatus)
                .toPromise()
                .then((res) => {
                    this.loader.hideLoader();
                    this.mediaLibs = res['media_libs'] as mediaLib[];

                    resolve(this.mediaLibs);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return promise;
    }
    async getUsersMyMediaLibraries(data): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getMyMediaLibrary(data)
                .toPromise()
                .then((res) => {
                    this.loader.hideLoader();
                    this.mediaLibs = res['result'] as mediaLib[];
                    console.log(this.mediaLibs);
                    resolve(this.mediaLibs);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return promise;
    }

    addMediaLib(email, session_id, lib_name, keyterms, description, category): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .addMediaLibrary(email, session_id, lib_name, keyterms, description, category)
                .toPromise()
                .then((res) => {
                    //  console.log("res", res);
                    let media = new mediaLib();
                    media.name = lib_name;
                    media.lib_id = res['media_lib'];
                    media.total_items = 0;
                    media.activation_status = 'active';
                    this.mediaLibs.push(media);
                    resolve(this.mediaLibs);
                    this.loader.hideLoader();
                })
                .catch((err) => {
                    this.loader.hideLoader();
                    reject(err);
                });
        });
        return promise;
    }

    updateMediaLibrary(mediaLibName, libActivationStatus, sUserEmail, sSessionId, mediaLibId) {
        this.loader.showLoader();
        //  console.log("Service",media);
        let mediaLibStatus = 'all';
        this.dataservice
            .updateMediaLibrary(mediaLibName, libActivationStatus, sUserEmail, sSessionId, mediaLibId)
            .toPromise()
            .then((res) => {
                this.dataservice
                    .getMediaLibrary(sUserEmail, sSessionId, mediaLibStatus)
                    .toPromise()
                    .then((res) => {
                        this.loader.hideLoader();
                        this.mediaLibs = res['media_libs'] as mediaLib[];
                    })
                    .catch((err) => {});
                this.loader.hideLoader();
            });
    }

    updateUserLibrary(mediaLibName, libActivationStatus, sUserEmail, sSessionId, mediaLibId, search = '', filter = 'all') {
        return new Promise((resolve, reject) => {
            this.loader.showLoader();
            //  console.log("Service",media);
            let mediaLibStatus = 'all';
            this.dataservice
                .updateMediaLibrary(mediaLibName, libActivationStatus, sUserEmail, sSessionId, mediaLibId)
                .toPromise()
                .then((res) => {
                    this.dataservice
                        .getMyMediaLibrary({ email: sUserEmail, session_id: sSessionId, status: filter, name: search })
                        .toPromise()
                        .then((res) => {
                            this.loader.hideLoader();
                            this.mediaLibs = res['result'] as mediaLib[];
                        })
                        .catch((err) => {});
                    this.loader.hideLoader();
                    resolve(true);
                })
                .catch((err) => {
                    reject(false);
                });
        });
    }

    getVisuals(
        email,
        session_id,
        visualType,
        medilibId,
        num_items,
        skip_video,
        sort_by,
        group_by,
        search_keyword,
        isBrand = false,
        libsArray = [],
        asc
    ): Promise<unknown> {
        // this.loader.showLoader();
        this.visuals = [];
        this.accountSettingService.showGridDataLoader = true;
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getvisual(email, session_id, visualType, medilibId, num_items, skip_video, sort_by, group_by, search_keyword, isBrand, libsArray, asc)
                .toPromise()
                .then((res: any) => {
                    this.accountSettingService.showGridDataLoader = false;
                    this.visuals = [];
                    if (res?.visuals && res?.visuals?.items) {
                        this.visuals = res?.visuals?.items; this.visualsCount = res['count'] as number;
                       let clipCount = res['visuals']['clip_count'];
                        let imageCount = res['visuals']['images_count'];

                        if (clipCount > this.visualsCount) {
                            this.visualsCount = clipCount;
                        }

                        if (imageCount > this.visualsCount) {
                            this.visualsCount = imageCount;
                        }
                    } else {
                        this.visuals = res['visuals'] as visual[];
                    }
                    this.visualsCount = res['count'];
                    this.visualsTotalCount = res['total_count'];
                    // this.getsignedThumbandURL(email, session_id);
                    this.loader.hideLoader();
                    // console.log(this.visualsTotalCount);
                        let response = res?.visuals?.items;
                        if (res && typeof response === 'object') {
                            this.visuals = Object.keys(response).map(key => ({
                            key: key,
                            videos: response[key]
                            }));
                        } else {
                            this.visuals = []; 
                        }
                        if (this.visuals.length > 0) {
                            this.visuals[0].active = true;

                        }
                        console.log("chk updated visuals", this.visuals);
                        
                    resolve('');
                })
                .catch((err) => {
                    this.accountSettingService.showGridDataLoader = false;
                    this.loader.hideLoader();
                });
        });

        return promise;
    }



    public replacementClips: visual[] = [];
    getReplaceClipsV2(email, session_id, visualType, keyowrds: [], limit, target_library = 'stock'): Promise<unknown> {
        this.replacementClips = [];
        this.visualsCount = 0;
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getReplaceClipsV2(email, session_id, visualType, keyowrds, limit, target_library)
                .then((res) => {
                    console.log('clips loaded ...');
                    (res['searched_clip'] as string[]).forEach((ele: any) => {
                        let cl = new visual();
                        cl.visual_type = 'clip';
                        cl.visual_path = ele.object_path;
                        cl.url = ele.object_surl;
                        cl.thumb = ele.thumbnail_path;
                        cl.thumbnail = ele.thumb_surl;
                        this.replacementClips.push(cl);
                        cl.loadingUrl = false;
                        cl.loadingThumbnail = false;
                    });

                    // this.getsignedURLForReplacmentclip(email, session_id);
                    resolve(res);
                })
                .catch((err) => {
                    this.loader.hideLoader();
                    reject(err);
                });
        });

        return promise;
    }
    getReplaceClip(email, session_id, visualType, replace_id): Promise<unknown> {
        this.loader.showLoader();
        this.replacementClips = [];
        this.visualsCount = 0;
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getReplaceClips(email, session_id, visualType, replace_id)
                .then((res) => {
                    this.loader.hideLoader();
                    (res['new_clips'] as string[]).forEach((ele) => {
                        let cl = new visual();
                        cl.visual_type = 'clip';
                        cl.visual_path = ele;
                        let thumb = cl.visual_path.slice(0, 15) + 'thumbs/' + cl.visual_path.slice(15, cl.visual_path.length);
                        thumb = cl.visual_path.replace('.mp4', '_thumb.jpg').replace('.mov', '_thumb.jpg').replace('.avi', '_thumb.jpg');
                        cl.thumb = thumb;
                        this.replacementClips.push(cl);
                    });

                    this.getsignedURLForReplacmentclip(email, session_id);
                    resolve(res);
                })
                .catch((err) => {
                    this.loader.hideLoader();
                    reject(err);
                });
        });

        return promise;
    }

    public audios: visual[] = [];
    getAudios(email, session_id, num_Items, num_Skips, category = undefined) {
        this.audios = [];
        // this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getPronotoaudio(email, session_id, num_Items, num_Skips, category)
                .then((res) => {
                    // this.loader.hideLoader();

                    res['audios'].forEach((ele: any) => {

                        let v = new visual();
                        v.visual_type = 'audio';
                        v.visual_path = ele._id;
                        v.duration = ele.duration;
                        v.isSelected = false;
                        v.name = ele._id.slice(ele._id.indexOf('.com/') + 5, ele._id.length);

                        /* this.dataservice.getduration(email,session_id,v.visual_path).then(r=>{
             console.log(r);
             v.duration =r['duration'];
           }) */
                        this.audios.push(v);
                    });
                    this.getsignedURLForaudioclip(email, session_id);

                    resolve(res);
                })
                .catch((err) => {
                    this.loader.hideLoader();
                    reject(err);
                });
        });

        return promise;
    }

    getVisualCount(email, session_id, visualType, medilibId): Promise<unknown> {
        let sort_by: string;
        let group_by: string;
        this.loader.showLoader();
        this.visualsCount = 0;
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getVisualListByMediaLib(email, session_id, visualType, medilibId, sort_by, group_by, 18, 'created')
                .toPromise()
                .then((res) => {
                    this.visualsCount = res['count'];

                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

        return promise;
    }

    getVideoThumbnailUrls(email: string, session_id: string, mediaList: any[]) {
        let batchUrl: string[] = [];
        for (let k = 0; k < mediaList.length; k++) {
            let vid = mediaList[k];
            vid.loadingUrl = true;
            vid.loadingThumbnail = true;
            if (vid.thumbnail_path != '') {
                batchUrl.push(vid.thumbnail_path);
            }
        }

        this.dataservice
            .getBatchSignedURL(email, session_id, batchUrl)
            .then((res) => {
                let output = res['output'];
                for (let k = 0; k < mediaList.length; k++) {
                    let e = mediaList[k];
                    e.loadingUrl = false;
                    e.loadingThumbnail = false;
                    e.url = output.filter((a) => a[e.thumbnail_path])[0][e.thumbnail_path];
                    e.thumbnail = output.filter((a) => a[e.thumbnail_path])[0][e.thumbnail_path];
                }
            })
            .catch((err) => {
                this.visuals.forEach((e, index) => {
                    e.loadingUrl = false;
                    e.loadingThumbnail = false;
                });
            });
    }
    getsignedThumbandURL(email, session_id) {
        let batchUrl: string[] = [];
        this.visuals.forEach((vid) => {
            vid.loadingUrl = true;
            vid.loadingThumbnail = true;
            if (vid.visual_path && vid.visual_path != null && vid.visual_path != '') {
                batchUrl.push(vid.visual_path);
            }

            if (vid.visual_type == 'clip') {
                if (vid.thumb != '') batchUrl.push(vid.thumb);
            }
        });

        this.dataservice
            .getBatchSignedURL(email, session_id, batchUrl)
            .then((res) => {
                let output = res['output'];
                // console.log('output2', output);

                // console.log('visual count', this.visuals.length);
                this.visuals.forEach((e, index) => {
                    e.loadingUrl = false;
                    e.loadingThumbnail = false;
                    // console.log('element', e);
                    e.url = output.filter((a) => a[e.visual_path])[0][e.visual_path];
                    if (e.thumb && e.visual_type == 'clip') {
                        e.thumbnail = output.filter((a) => a[e.thumb])[0][e.thumb];
                    }
                    // console.log('element', e);
                    if (e.visual_type == 'image') {
                        e.thumbnail = output.filter((a) => a[e.visual_path])[0][e.visual_path];
                    }

                    // console.log(e);
                });
                //  console.log("singed-------------------" + JSON.stringify(vid));
            })
            .catch((err) => {
                this.visuals.forEach((e, index) => {
                    e.loadingUrl = false;
                    e.loadingThumbnail = false;
                });
            });
    }

    getsignedURLForReplacmentclip(email, session_id) {
        let batchUrl: string[] = [];
        this.replacementClips.forEach((vid) => {
            vid.loadingUrl = true;
            if (vid.visual_path != '') batchUrl.push(vid.visual_path);

            if (vid.thumb != '') vid.loadingThumbnail = true;
            batchUrl.push(vid.thumb);
        });

        this.dataservice
            .getBatchSignedURL(email, session_id, batchUrl)
            .then((res) => {
                let output = res['output'];
                // console.log('output', output);
                this.replacementClips.forEach((e, index) => {
                    e.url = output.filter((a) => a[e.visual_path])[0][e.visual_path];
                    e.thumbnail = output.filter((a) => a[e.thumb])[0][e.thumb];
                    // console.log(e);
                    setTimeout(() => {
                        e.loadingUrl = false;
                        e.loadingThumbnail = false;
                    }, 1000);
                });
                //  console.log("singed-------------------" + JSON.stringify(vid));
            })
            .catch((err) => {
                this.replacementClips.forEach((e, index) => {
                    e.loadingUrl = false;
                });
            });
    }

    getsignedURLForaudioclip(email, session_id) {
        let batchUrl: string[] = [];
        this.audios.forEach((vid) => {
            vid.loadingUrl = true;
            if (vid.visual_path != '') batchUrl.push(vid.visual_path);
        });

        this.dataservice
            .getBatchSignedURL(email, session_id, batchUrl)
            .then((res) => {
                let output = res['output'];
                // console.log('output', output);
                this.audios.forEach((e, index) => {
                    e.loadingUrl = false;

                    e.url = output.filter((a) => a[e.visual_path])[0][e.visual_path];

                    // console.log(e);
                });
                //  console.log("singed-------------------" + JSON.stringify(vid));
            })
            .catch((err) => {
                this.audios.forEach((e, index) => {
                    e.loadingUrl = false;
                });
            });
    }

    deleteVisual(email, session_id, visual_id): Promise<unknown> {
        this.loader.showLoader();

        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .deleteVisual(email, session_id, visual_id)
                .toPromise()
                .then((res) => {
                    this.loader.hideLoader();
                    this.visuals.forEach((element, index) => {
                        if (element.visual_path == this.selectedVisual.visual_path) {
                            this.visuals.splice(index, 1);
                        }
                    });
                    resolve(res);
                })
                .catch((err) => {
                    reject('');
                    this.loader.hideLoader();
                });
        });
        return promise;
    }

    deleteAllSelectedVisuals(email, session_id) {
        let promises: Promise<Object>[] = [];
        this.loader.showLoader();
        this.selectedVisuals.forEach((ele) => {
            promises.push(this.dataservice.deleteVisual(email, session_id, ele.visual_path).toPromise());
        });

        Promise.all(promises)
            .then((ele) => {
                this.loader.hideLoader();

                this.selectedVisuals.forEach((selec) => {
                    this.visuals.forEach((visu: visual, index) => {
                        if (selec.visual_path === visu.visual_path) {
                            this.visuals.splice(index, 1);
                        }
                    });
                });

                this.selectedVisuals = [];
            })
            .catch((err) => {
                this.loader.hideLoader();
            });
    }

    updateVisual(email, session_id, visual_id, keyterms) {
        this.loader.showLoader();
        this.dataservice
            .updateVisual(email, session_id, visual_id, keyterms)
            .toPromise()
            .then((res) => {
                this.loader.hideLoader();
            })
            .catch((err) => {
                this.loader.hideLoader();
            });
    }

    uploadMedia(email, session_id) {
        this.uploadMediaList.forEach((ele: mediaFile) => {
            if (!ele.status) {
                ele.status = 'uploading';
                this.dataservice
                    .DataService_BulkUpload(email, session_id, this.selectedmedialib.lib_id, ele.file.name, ele.file.type)
                    .then((e) => {
                        var signed_url = e['signed_url'];
                        var fpath = e['fpath'];

                        this.dataservice.DataService_UploadFileOnSignedUrl(ele.file, signed_url).then((es) => {
                            this.dataservice
                                .AddUserMedia(email, session_id, ele.file.name, this.selectedmedialib.lib_id, fpath)
                                .then((res) => {
                                    ele.status = 'uploaded';

                                    this.dataservice.getVisual(email, session_id, res['fpath']).then((vis) => {
                                        let visual = vis as visual;
                                        // visual.thumb = res['thumb']
                                        // visual.url = res['url'];
                                        visual.loadingUrl = true;
                                        this.dataservice
                                            .getSignedURL(email, session_id, visual.visual_path)
                                            .then((resp) => {
                                                // console.log("resp", resp);

                                                visual.url = resp['url'];
                                                visual.loadingUrl = false;
                                            })
                                            .catch((err) => {
                                                visual.loadingUrl = false;
                                            });
                                        if (visual.visual_type == 'clip') {
                                            visual.loadingThumbnail = true;
                                            this.dataservice
                                                .getThumbBatchSignedURL(email, session_id, [visual.visual_path])
                                                .then((resp) => {
                                                    // console.log(resp[0]);
                                                    visual.thumbnail = resp['output'][0].thumb_signed_url;
                                                    visual.loadingThumbnail = false;

                                                    // console.log("visual_thumbnail", visual);
                                                })
                                                .catch((err) => {
                                                    visual.loadingThumbnail = false;
                                                });
                                        }

                                        this.visuals.push(visual);
                                    });
                                });
                            // console.log(es);
                        });
                    });
            }
        });
    }

    async startUploadProcessing(email, session_id) {
        this.uploadMediaList.forEach(async (item) => {
            await this.uploadMediaDefaultLibrary(email, session_id, item);
        });
    }

    async uploadMediaDefaultLibrary(email, session_id, media: mediaFile, mediaLibId = undefined): Promise<visual> {
        let hasMediaLibrary = true;
        if (mediaLibId || this.mediaLibs.length == 0) {
            const ListMediaLibs: any = await this.getMediaLibraries(email, session_id, 'active').then();
            if (!ListMediaLibs || ListMediaLibs?.length == 0) {
                hasMediaLibrary = false;
            }
        }

        let promise = new Promise<visual>(async (resolve, reject) => {
            let vs = new visual();
            if (!hasMediaLibrary) {
                reject({
                    error: { errorMessage: "User dont't have any active media library." },
                });
            }
            let uploadMediaLibraryId = this.mediaLibs[0].lib_id;
            if (mediaLibId == undefined && this.onboardingService?.profile?.default_media_lib?._id?.$oid) {
                // console.log( "%c*********CHECKING DEFAULT MEDIA LIB********", "color : red");
                // console.log(`%c*********${this.onboardingService?.profile?.default_media_lib?._id?.$oid}`,"color : red");
                // console.log("%cNote: Uploading into Default Media Library ", 'color: red; background: yellow; font-size: 16px');
                uploadMediaLibraryId = this.onboardingService?.profile?.default_media_lib?._id?.$oid;
            } else {
                uploadMediaLibraryId = this.mediaLibs[0].lib_id
            }
            if (mediaLibId && mediaLibId != undefined) {
                uploadMediaLibraryId = mediaLibId;
            }
            media.status = 'uploading';
            try {
                await this.dataservice
                    .DataService_BulkUpload(email, session_id, uploadMediaLibraryId, media.file.name, media.file.type)
                    .then(async (e) => {
                        var signed_url = e['signed_url'];
                        var fpath = e['fpath'];

                        await this.dataservice
                            .DataService_UploadFileOnSignedUrl(media.file, signed_url)
                            .then(async (es) => {
                                await this.dataservice
                                    .AddUserMedia(email, session_id, media.file.name, uploadMediaLibraryId, fpath)
                                    .then(async (res) => {
                                        media.status = 'uploaded';

                                        await this.dataservice.getVisual(email, session_id, res['fpath']).then(async (vis) => {
                                            vs = vis as visual;
                                            // visual.thumb = res['thumb']
                                            // visual.url = res['url'];
                                            vs.name = media.name;
                                            vs.loadingUrl = true;
                                            vs.loadingThumbnail = true;
                                            await this.dataservice
                                                .getSignedURL(email, session_id, vs.visual_path)
                                                .then(async (resp) => {
                                                    vs.url = resp['url'];
                                                    vs.thumbnail_signed_url  = resp['url'];
                                                    if (vs.visual_type == 'image') {
                                                        vs.thumbnail = resp['url'];
                                                    }
                                                    vs.loadingUrl = false;
                                                })
                                                .catch((err) => {
                                                    vs.loadingUrl = false;
                                                    reject(err);
                                                });

                                            if (vs.visual_type == 'clip') {
                                                await this.dataservice
                                                    .getThumbBatchSignedURL(email, session_id, [vs.visual_path])
                                                    .then(async (resp) => {
                                                        vs.thumbnail = resp['output'][0].thumb_signed_url;
                                                        vs.thumbnail_signed_url  = resp['output'][0].thumb_signed_url;
                                                        vs.loadingThumbnail = false;
                                                    })
                                                    .catch((err) => {
                                                        vs.loadingThumbnail = false;
                                                        reject(err);
                                                    });
                                            }
                                        });
                                    })
                                    .catch((err) => {
                                        reject(err);
                                        throw err;
                                    });
                            })
                            .catch((err) => {
                                reject(err);
                                throw err;
                            });

                        resolve(vs);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    getMediaThumbandURL(email: string, session_id: string, fPath) {
        return new Promise((resolve, reject) => {
            this.dataservice
                .getBatchSignedURL(email, session_id, fPath)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject();
                });
        });
    }
    getAdminMediaList(search: SearchData, loaderSection: any = undefined): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const visualType = search.visual_type;
            let strsearch = `email=${search.email}&session_id=${search.session_id}&visual_type=${search.visual_type}&media_lib=${search.media_lib}&num_items=${search.limit}&num_skip=${search.offset}`;
            if (search?.visual_id && search?.visual_id != '') {
                strsearch += `&search_keyword=${search.visual_id}`;
            }
            if (search?.sort_by && search?.sort_by != '') {
                strsearch += `&order_by=${search.sort_by}`;
            }
            // const strSearch = `email=${search.email} ${search.sSessionId} ${search.email} ${search.email} ${search.email} ${search.email} ${search.email}`;
            try {
                this.visualsData = await this.dataservice.getAdminMediaList(strsearch);
                const res = this.visualsData;

                if (visualType == 'all') {
                    res['visuals']['audios']['items'].forEach((element: visual) => {
                        element.visual_type = 'audio';
                        this.visuals.push(element);
                    });

                    res['visuals']['clips']['items'].forEach((element: visual) => {
                        element.visual_type = 'clip';
                        this.visuals.push(element);
                    });

                    res['visuals']['images']['items'].forEach((element: visual) => {
                        element.visual_type = 'image';
                        this.visuals.push(element);
                    });
                    // TODO media loading..
                    // console.log("All Visual response", res);
                    //this.visuals = this.visuals.concat(...(res['visuals']['audios']['items'].map(c =>c.visual_type ='audio')  as visual[]));
                    this.visualsCount = res['count']; //res["visuals"]["audios"]["count"] as number;
                    // this.visuals = this.visuals.concat(...(res['visuals']['clips']['items'].map(c =>  c.visual_type ='clip')  as visual[]));
                    // this.visuals = this.visuals.concat(...(res['visuals']['images']['items'].map(c =>c.visual_type ='image') as visual[]));

                    // TODO media loading
                    // console.log("All visuals", this.visuals);

                    let clipCount = res['visuals']['clips']['count'];
                    let imageCount = res['visuals']['images']['count'];

                    if (clipCount > this.visualsCount) {
                        this.visualsCount = clipCount;
                    }

                    if (imageCount > this.visualsCount) {
                        this.visualsCount = imageCount;
                    }
                } else {
                    this.visuals = res['visuals'] as visual[];
                    this.visualsCount = res['count'];
                }

                this.getsignedThumbandURL(
                    this.sessionService.cSessionService_GetUserEmail(),
                    this.sessionService.SessionService_GetSessionId(),
                );
                if (this.accountSettingService.showGridDataLoader) {
                    this.accountSettingService.showGridDataLoader = false;
                }
                this.loader.hideLoader();
                resolve('');
            } catch (e) {
                this.accountSettingService.showGridDataLoader = false;
                reject('');
            }
        });
    }

    getListGenres(params) {
        return this.dataservice.getListGenres(params);
    }
}
