<div class="container row voice-over-container fancy-scroll" [ngClass]="{ 'side-panel-container': editVoicePanel }" style="overflow-y: auto">
    <div [ngClass]="{ 'col-lg-7 ps-4': !editVoicePanel, 'col-lg-12': editVoicePanel }" style='position: relative'>
        <div (click)="hideVideoAudioSync()" class="hideVoiceOverSection" *ngIf="showVoiceVideoPreview">

        </div>
        <div *ngIf="!editVoicePanel" class="top-heading">
            Select a voice avatar from our diverse collection, or upload your custom voiceover.
        </div>
        <div *ngIf="!editVoicePanel" class="custom-radio mb-1">
            <div class="custom-radios mt-3 heading-main">
                <div class="cus-radio-btm me-2 pe-1">
                    <input type="radio" name="voiceovertype" value="voiceover" [(ngModel)]="selectedOption" />
                    <label for="color-1" (click)="selectVoiceOption('voiceover')">
                        <img class="me-2" style="margin-top: -4px" [src]="
                                selectedOption == 'voiceover'
                                    ? this.pSetting.icons.checkbox_default_selected
                                    : this.pSetting.icons.checkbox_default
                            " width="18" height="18" />
                        Select Voice Avatar
                    </label>
                </div>
                <div class="cus-radio-btm" (click)="selectVoiceOption('upload')">
                    <input type="radio" name="voiceovertype" value="upload" [(ngModel)]="selectedOption" />
                    <label for="color-2">
                        <img style="margin-top: -4px" [src]="
                                selectedOption == 'upload'
                                    ? this.pSetting.icons.checkbox_default_selected
                                    : this.pSetting.icons.checkbox_default
                            " width="18" height="18" />

                        Upload Voice
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="editVoicePanel" class="d-flex justify-content-start">
            <button href="javascript:void(0)" [disabled]="!this.videoManger.selectedVideo.voice_file_obj || this.videoManger.selectedVideo.voice_file_obj == undefined" class="btn text-xs d-inline-block btn-outline-gray-radius text-gray-3 btn-edit-voice ms-3"
                style="padding: 10px 14px 22px 14px !important" (click)="openVoiceEditor()">
                Edit Voice Duration
            </button>
        </div>
        <ul *ngIf="editVoicePanel" class="nav breadcrumb-tabs ms-3 mb-0" id="myTab" role="tablist">
            <li class="nav-item ps-0" role="presentation">
                <button class="nav-link tab-size ps-0" id="tab1-tab" [ngClass]="{ active: selectedOption == 'upload' }" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true" (click)="selectVoiceOption('upload')">
                    Upload Voiceover
                </button>
            </li>
            <li class="nav-item px-0" role="presentation">
                <button class="nav-link tab-size" [ngClass]="{ active: selectedOption != 'upload' }" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false" (click)="selectVoiceOption('voiceover')">
                    Select Voice
                </button>
            </li>
        </ul>
        <div class="row fade-out mt-3" style="position: relative;" *ngIf="selectedOption == 'upload'" [ngClass]="{ hide: selectedOption != 'upload', 'ms-2': editVoicePanel }">

            <div (click)="hideVideoAudioSync()" class="hideVoiceOverSection" *ngIf="showVoiceVideoPreview">

            </div>
            <div class="my-1 pb-2 voicover-btn row" [ngClass]="editVoicePanel ? 'mt-3' : ''">
                <div [ngClass]="editVoicePanel ? 'col-lg-5' : 'col-lg-3 w-22'">
                    <button (click)="openFileUploadModal('audio', true)" class="btn weight-600 text-xs btn-secondary btn-round-6 text-white btn-md-same-pad d-flex align-items-center btn-effect">
                        <img [src]="this.pSetting.icons.upload" width="18" height="18" style="margin-right: 10px; filter: invert(1)" />

                        Upload File
                    </button>
                </div>
                <div [ngClass]="editVoicePanel ? 'col-lg-7 pe-0 ps-0' : 'col-lg-5 ps-0'">
                    <button _ngcontent-okx-c310="" (click)="showMediaModal()" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-effect" style="padding: 9px 14px !important">
                        <img _ngcontent-okx-c310="" src="../assets/images/svg/mediaLibrary.svg" /> &nbsp; &nbsp; Select From Media Library
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <p class="text-xs text-siver">Supported format (.mp3)</p>
                </div>
            </div>
            <div class="media_list ps-3" [ngClass]="isVoiceFromUploadOption ? 'd-block' : 'd-none'">
                <ul>
                    <li class="mb-3">
                        <figure class="media_figure">
                            <span class="media" style="height: 75px; width: 80px;">
                                <a href="javascript:void(0)" class="muteBtn btn-hover-w voice-btn mb-2"
                                (mouseover)="AudioSidePanelComponent_PlayAudio(this.oUploadedVoiceOverFile?.url? this.oUploadedVoiceOverFile?.url : this.oUploadedVoiceOverFile?.signed_url)"
                                (mouseout)="AudioSidePanelComponent_StopAudio(this.oUploadedVoiceOverFile?.url? this.oUploadedVoiceOverFile?.url : this.oUploadedVoiceOverFile?.signed_url)" >

                                <img src="../assets/images/microphone-off.svg" class="off"
                                style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
                            <img src="../assets/images/microphone.svg" class="on"
                                style="margin-top: 0px;margin-left: 4px;" width="12" height="11" />
                                </a>
                                <img src="../assets/images/business_Profile/audio-line.png" alt="vo-file" height="75px"/></span>
                            <figcaption>
                                <div class="caption w-100">
                                    <p class="text-xs title text-secondary" *ngIf="oUploadedVoiceOverFile">
                                        {{ oUploadedVoiceOverFile.fpath | mediaFileName }}
                                    </p>
                                    <p class="text-xs sub_title" *ngIf="nVoiceOverFileSize">
                                        {{ ContentComponent_ReturnFileSize(nVoiceOverFileSize) }}
                                    </p>
                                </div>
                            </figcaption>
                        </figure>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row fade-out" *ngIf="selectedOption == 'voiceover'">
            <div class="row fade-out" *ngIf="editVoicePanel">
                <div class="mty-1 pb-2 voicover-btn row">
                    <div class="col-lg-12 mt-3">
                        <button _ngcontent-okx-c310="" (click)="openBrowseVoiceOverModal()" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-effect ms-2" style="max-width: 198px; max-height: unset">
                            <img class="me-2" [src]="pSetting.icons.avatar" width="20" height="20" /> Select Voice Avatar
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="this.selectedVoicesDropDownList.length > 0" class="row pd-2 d-flex voice-over-dropdown-bar mb-3" style="margin-top: 4px">
                <div class="dropdown dropdownSelect select-voice pe-0">
                    <button class="dropdown-toggle text-md dropdownSelectValid pb-0" style="margin-top: 10px" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="dropdownBtnText">
                            <a class="dropdown-item text-md aspect-item voice-avatar-selected" href="javascript:void(0)">
                                <div class="col-sm-1" style="width: 50px">
                                    <img [src]="selectedAvatar?.avatar_url ? selectedAvatar?.avatar_url : pSetting.icons.avatar" width="40" height="40" />
                                </div>
                                <div class="ps-0 avatar-heading">
                                    <span class="avatar-heading">{{
                                        selectedAvatar != '' ? selectedAvatar?.speaker_name : 'Voice Name'
                                        }}</span>
                                </div>

                                <div class="ps-2 id-heading" *ngIf="speaker_id">
                                    <span class="id-heading-inner">{{ 'ID ' + speaker_id }}</span>
                                </div>
                            </a>
                        </span>
                    </button>
                    <ul class="dropdown-menu dropdownMenu w-400 avatar-selection-list fancy-scroll pt-2">
                        <li *ngFor="let voice of selectedVoicesDropDownList" class="aspectRatio">
                            <a class="dropdown-item text-md aspect-item d-flex" href="javascript:void(0)" (click)="selectAvatar(voice)">
                                <div class="col-sm-1" style="width: 50px">
                                    <img [src]="voice.avatar_url ? voice.avatar_url : this.pSetting.icons.avatar" width="40" height="40" />
                                </div>
                                <div class="col-sm-10 ps-0 avatar-heading">
                                    <span class="avatar-heading">{{ voice.speaker_name }}</span>
                                </div>
                                <img *ngIf="this.selectedAvatar == voice.speaker_name" [src]="this.pSetting.icons.green_tick" />
                            </a>
                        </li>
                    </ul>
                </div>

                <div id="selectpreff" class="select-narration">
                    <div class="formItm down_arrow mb-2">
                        <div class="dropdown dropdownBtn">
                            <button class="dropdown-toggle text-md speech_style_dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="dropdownBtnText">{{ selectedstyle ? selectedstyle : 'Narration' }}</span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end dropdownMenu ddd">
                                <li *ngFor="let speechStyle of this.selectedVoiceStyles">
                                    <a class="dropdown-item text-md" href="javascript:void(0)" (click)="setWellSideIds(speechStyle)">{{
                                        speechStyle?.style
                                        }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.selectedVoicesDropDownList.length == 0" (click)="openBrowseVoiceOverModal()" class="row pd-2" style="width: 354px; padding-left: 35px">
                <div class="col-sm-1" style="width: 67px">
                    <img [src]="pSetting.icons.avatar" width="40" height="40" />
                </div>
                <div class="col-sm-8 ps-0 avatar-heading"><span class="avatar-heading">No Avatar selected</span></div>
            </div>
            <div *ngIf="this.selectedVoicesDropDownList.length == 0" class="row mt-2 mb-2" style="padding-left: 35px">
                <div class="error-no-voice">Please select a voice avatar before proceeding.</div>
            </div>
            <div class="row" [ngClass]="{ 'ms-1': editVoicePanel }">
                <textarea placeholder="Enter Voiceover text" class="voice-input-field fancy-scroll ms-3" [(ngModel)]="vo_text" (input)="updateVoiceText()" [disabled]="this.selectedVoicesDropDownList.length == 0" [maxlength]="numberOFVoiceCharacters"
                    [ngClass]="{ 'ms-3': !editVoicePanel, 'ms-0 editPopup': editVoicePanel }">
                </textarea>
            </div>
            <div class="row button-area mt-3" style="max-width: 300px" [ngClass]="{ 'margin-eight-minus': editVoicePanel }">
                <div class="col-sm-4">
                    <button class="btn-create" (click)="createVoice()" [disabled]="vo_text == ''" [ngClass]="{
                            disable:
                                vo_text == undefined ||
                                vo_text == '' ||
                                vo_text.trim() == '' ||
                                (this.selectedVoicesDropDownList && this.selectedVoicesDropDownList.length == 0)
                        }">
                        Create
                    </button>
                </div>
                <div class="col-sm text-sm">({{ vo_text?.length }}/{{ numberOFVoiceCharacters }}) characters</div>
            </div>
            <div class="loading-progress-bar border mt-3 ms-3" [ngClass]="{ editPopup: editVoicePanel }" *ngIf="createVoiceLoader && (!createdVoicesList || createdVoicesList.length == 0)">
                <div class="bg-green"></div>
            </div>
            <div class="row mt-3 pe-0 fancy-scroll" style="height: 150px; overflow-y: auto; overflow-x: hidden">
                <div class="col-lg-12 ps-0 ms-0" *ngFor="let media of createdVoicesList; let i = index">
                    <div class="audio-file-item ms-3 p-relative" [ngClass]="{ 'mb-1': media.error, 'mb-4': !media.error }">
                        <div class="selected-left-bar" *ngIf="media.selected && !media.error"></div>
                        <div class="error-left-bar" *ngIf="media.error"></div>
                        <div (click)="selectCreatedVoice(media, !media.selected)" class="m-auto">
                            <img [src]="media.selected ? pSetting.icons.checkbox_green_mark : pSetting.icons.checkbox_off" width="18" height="18" style="margin-right: 10px; margin-left: 10px" />
                        </div>
                        <div>
                            <!-- <div *ngIf="media.speaker ||  media.artist_id" class="artist_id">
                            {{ media.speaker ? media.speaker : media.artist_id }}
                        </div> -->
                            <img [src]="getAvatarUrlBySpeakerId(media)" width="24" height="24" />
                        </div>
                        <div class="relative">
                            <audio class="hide-it" id="audio-player-{{ i }}" controls="false">
                                <source [src]="media.url" type="audio/mpeg" />
                            </audio>
                            <div class="player-section">
                                <div (click)="playAudio(i, media)">
                                    <i *ngIf="!media.play" class="play-icon p-icon sm link m-auto mt-1" [ngClass]="{ active: media.play }"></i>
                                    <i *ngIf="media.play" class="pause-voice-icon p-icon sm link m-auto mt-1"></i>
                                </div>
                                <div class="player-bar">
                                    <div class="name truncate-line">{{ media.name | fileNameOnly : 50 }}</div>
                                    <div class="current-time">{{ media.current_time }}/{{ mathObj.trunc(media.duration) }}</div>
                                    <div class="bar-progress-bg"></div>
                                    <div class="bar-progress" [style]="{ width: (media.current_time / mathObj.trunc(media.duration) ) * 100 + '%' }"></div>
                                </div>
                                <!-- <input type="range" id="range-voice-{{i}}" value="0" max="{{media.duration}}" /> -->
                            </div>
                        </div>
                        <div (click)="downloadCreatedVoice(media)">
                            <i matTooltip="Download" matTooltipClass="custom-tooltip" matTooltipPosition="above" class="p-icon sm icon-download link"></i>
                        </div>
                        <div (click)="AddCreatedVoice(media)">
                            <i matTooltip="Save to Library" matTooltipClass="custom-tooltip" matTooltipPosition="above" class="p-icon sm icon-move-to link"></i>
                        </div>
                        <div (click)="deleteCreatedVoice(media, i)">
                            <i matTooltip="Delete" matTooltipClass="custom-tooltip" matTooltipPosition="above" class="p-icon sm icon-cross-square link"></i>
                        </div>
                    </div>
                    <div *ngIf="media.error" class="audio-duration-error ms-3 mb-3">
                        Voice exceeds video duration. Please adjust your text to fit the chosen video length.
                    </div>
                    <div class="loading-progress-bar border mt-3 ms-3 editPopup" style="width: 98%;" *ngIf="createVoiceLoader && i == createdVoicesList.length - 1">
                        <div class="bg-green"></div>
                    </div>
                </div>
                <div *ngIf="errorAudioGeneration && errorAudioGeneration != ''" class="audio-duration-error ms-3 mb-3">
                    {{ errorAudioGeneration }}
                </div>
            </div>
        </div>
        <div *ngIf="this.isLocation" class="ms-1" style="position: absolute; bottom: 4vh;">


            <button class="weight-normal btn btn-primary btn-round-6 me-3" style="padding: 12px 43px" *ngIf="(selectedOption == 'voiceover' && createdVoicesList && createdVoicesList.length > 0 && speakerVoiceObj !=  undefined && speakerVoiceObj.url !=  undefined && speakerVoiceObj.url != '') || (selectedOption != 'voiceover' && oUploadedVoiceOverFile && oUploadedVoiceOverFile.url)" (click)="previewVideoAudioSync()">Preview</button>
            <button class="weight-normal btn btn-primary btn-round-6" style="padding: 12px 54px" (click)="saveVoiceLoc()">Save</button>
            <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm ms-3" (click)="cancelVoiceLoc()">Cancel</a>
        </div>
    </div>
    <!-- Preview Voice and Video Sync-->
    <div class="col-lg-5" *ngIf="showVoiceVideoPreview && ((selectedOption == 'voiceover' && createdVoicesList && createdVoicesList.length > 0 && speakerVoiceObj !=  undefined) || (selectedOption != 'voiceover' && oUploadedVoiceOverFile && oUploadedVoiceOverFile.url))">
        <p class="preview-heading my-4" style="margin-top: 32px !important">Preview Voice & Video Sync</p>
        <div class="d-flex" style="margin-top: -19px; align-items: baseline">
            <p>
                <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 px-2 truncate-line" (click)="openFileUploadModalV2('video_restricted', 'Upload a Media')">
                    <img class="svgSelect" src="../../../../assets/images/svg/upload.svg" /> &nbsp;&nbsp; Upload Media
                </button>
                <br />
                <span class="text-siver" style="font-size: 9px !important; position: absolute">Supported formats (.mp4, .mov)</span>
            </p>

            <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 ms-2 px-2 truncate-line" (click)="showMediaLibrary('clip')">
                <img src="../../../../assets/images/svg/mediaLibrary.svg" />
                &nbsp; &nbsp; Select From Media Library
            </button>
            <span class="ps-2 search-box">
                <div class="formItm search-icon searchDropdown">
                    <input id="search-feild" type="search" value="" [(ngModel)]="searchTermText" placeholder="Search" required="" (keyup.enter)="searchVid()" />
                    <label for="search-feild" class="text-sm" style="cursor: default">Search</label>

                    <img class="icon" (click)="searchVid()" src="../../../../assets/images/svg/search.svg" />
                </div>
                <div class="searchtem" *ngIf="search_term != ''" (click)="clearSearchTerm()">
                    <div>{{ search_term }}</div>
                    <mat-icon class="link">close</mat-icon>
                </div>
            </span>
        </div>
        <div class="video-box fancy-scroll" *ngIf="search_term">
            <h5 *ngIf="search_term && this.videoListingManager.videoCount == 0" class="no-data">No data Found</h5>
            <ul class="ps-4 pt-3" *ngIf="search_term && this.videoListingManager.videos.length > 0">
                <p class="video-heading">Localized Videos</p>
                <li class="video-list-item" *ngFor="let video of this.videoListingManager.videos; index as i" (click)="selectVideo(video)">

                    <div class="video-outer d-flex" [ngStyle]="{
                         minHeight:
                            true
                             ? '67px'
                             : ''
                       }" [ngClass]="
                       true ? 'h-67 list-grid-view' : ''
                     ">
                        <div class="pageloader media-loader-outer" *ngIf="video.loadingThumbUrl">
                            <div id="loader-inner" class="media-loader-inner"></div>
                        </div>

                        <div class="video_wrapper js-videoWrapper" style="height: 50px; width: 66px;">
                            <app-pr-video-player *ngIf="video.show_player" [styleForced]="false" [autoPlay]="true" [video]="video"></app-pr-video-player>
                            <figure class="video-baner" style="width: 66px; height:50px">
                                <img style="width: 66px; height:50px;" *ngIf="video?.thumbnail" [src]="video.thumbnail" alt="{{video.title}}" />

                            </figure>
                            <button class="videoPoster previewVideoPoster"></button>
                        </div>
                        <div class="video-details ms-2">
                            <div class="title-details">
                                <a *ngIf="!video.edit_title " href="javascript:void(0)" class="text-sm pb-2 ps-0 m-0 text-gray-2 title-text" [title]="video.title">{{ video.title | slice:0:20}}
                                </a>
                                <a style="height:40px; width:40px; position: absolute; top: -10px; font-weight:600; display:none" [routerLink]="['/webplayer']" [queryParams]="{page:'webplayer', id: getSafePath(video)}">Web player</a>

                            </div>
                            <div class="list-v-dis date-format d-flex">

                                <span *ngIf="video.template_name != 'SAP'" class="video-duration">{{ video.duration | secondsToTime
                                    }}</span>
                                <p class="ps-1 m-0 created ">Created on {{ video.created | date}}</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="relative preview-container" *ngIf="!search_term">
            <!-- *ngIf="this.voicePreview.loader" -->
            <div class="loading-preview" *ngIf="this.voicePreview.loader">
                <div class="item"><span class="loading-icon">
                        <div class="lds-default absolute-right">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </span></div>
            </div>
            <div class="row mb-3 mt-3" style="padding: 0px 11px;" [hidden]="voicePreview.firstLoad">
                <video [muted]="true" preload="auto" controlslist="nodownload noplaybackrate" class="video-preview-player innerVideo" [src]='this.voiceStore.voiceVideoPreviewVideoSrc'></video>
            </div>
            <div class="row mb-3 mt-3 preview-comparison" [hidden]="voicePreview.firstLoad">

                <audio *ngIf="selectedOption == 'voiceover'" id="audio-preview-player" controls style=" display:none ">
                    <source [src]="speakerVoiceObj.url" type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
                <audio  *ngIf="selectedOption != 'voiceover' && oUploadedVoiceOverFile" id="audio-preview-player" controls style=" display:none ">
                    <source [src]="oUploadedVoiceOverFile.url" type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>

                <div class="player-controls">
                    <div class="link">
                        <img id="play-pause-button" width="14" style="margin-top: -2px;" height4="14" src="/assets/images/icons/play-icon-purple.png" />
                        <!-- <button id="play-pause-button">Play</button> -->
                        <!-- <input type="range" id="volume-control" min="0" max="1" step="0.01" value="1"> -->
                    </div>
                    <div class="progress-preview-range" style=" margin-top: -7px; ">
                        <!-- <div class="progress-bar-preview-player link" id="progress-bar-preview-player">
                            <div class="progress-bar-circle"></div>
                        </div> -->
                        <input class="preview-bar" type="range" id="timeController" min="0" max="10" step="0.01" value="0" width="400">
                    </div>
                    <div class="voice-time">
                        <span id="current-time-preview-player" class="time">0:00</span>/<span id="total-time-preview-player" class="time">0:00</span>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <!-- Select Recent voiceovers Select Favorites -->
    <div class="col-lg-1" *ngIf="selectedOption == 'voiceover' && showRightPanel && !editVoicePanel"></div>
    <div class="col-lg-4" *ngIf="selectedOption == 'voiceover' && showRightPanel && !editVoicePanel" [ngClass]="{ 'ps-5': selectedOption == 'voiceover' && showRightPanel && !editVoicePanel }">
        <div class="row mb-3">
            <span class="sm-heading-text">Select | Recently Used</span>

            <div class="col-md-12" style="max-width: 100%" *ngFor="let voice of listSelected.slice(0, 2)">
                <app-voice-over-single-item [showPlayer]="false" [voice]="voice"></app-voice-over-single-item>
            </div>

            <span class="sm-heading-text-sm" *ngIf="!listSelected || listSelected.length == 0">Selected and Recently used Avatars will appear here
            </span>
        </div>

        <div class="row fade-out">
            <div class="mt-4 mb-4 pb-2 voicover-btn row">
                <div class="col-lg-12">
                    <button _ngcontent-okx-c310="" (click)="openBrowseVoiceOverModal()" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-effect select-voice-avatar">
                        <img class="me-2" [src]="pSetting.icons.avatar" width="20" height="20" /> Select Voice Avatar
                    </button>
                </div>
            </div>
        </div>
        <div class="row" style="max-width: 100%">
            <span class="sm-heading-text">My Favorites</span>
            <div style="max-height: 250px; overflow-y: auto" class="fancy-scroll">
                <div class="col-md-12 fancy-scroll" style="max-width: 100%" *ngFor="let voice of listFavorites">
                    <app-voice-over-single-item [showPlayer]="false" [voice]="voice"></app-voice-over-single-item>
                </div>
            </div>
            <span class="sm-heading-text-sm" *ngIf="!listFavorites || listFavorites.length == 0">Favorited Avatars will appear here </span>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="showMediaFromLibrary" nzCentered [nzWidth]="850" [nzFooter]="null" [nzClosable]="true" (nzOnCancel)="closeMediaModal()">
    <app-media-library-popup *ngIf="showMediaFromLibrary" [audioOnly]="true" (lEmittedFilesToUpload)="mediaSelected($event)" [mediaPopUpLoc]="'mediaPopUpLoc'" [isSingleSelected]="true"></app-media-library-popup>
</nz-modal>