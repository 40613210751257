import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { registerLocaleData } from '@angular/common';
import { AlertModule } from 'src/app/_alert';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgWaveformModule } from 'ng-waveform';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { TimestampToDate } from '../services/pipes/timestamp-to-date-convertor.pipe';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { DatesPipeModule } from '../services/pipes/pronto-pipes.module';
import { MediaLibrarySharedMyAccountComponent } from './media-library-shared-myaccount/media-library-shared-myaccount.component';
import { CreatedVideosComponent } from './created-videos/created-videos.component';
import { MediaLibrarySharedComponent } from './media-library-shared/media-library-shared.component';
import { LoaderComponent } from './loader/loader.component';
import { MediaLibraryPopupComponent } from './media-library-popup/media-library-popup.component';
import { SplashscreenComponent } from './splashscreen/splashscreen.component';
import { HeaderComponent } from './header/header.component';
import { SuccessMessageComponent } from './components/success-message/success-message.component';
import { SecondsToTime } from '../services/pipes/seconds-to-time.pipe';
import { PrVideoPlayerComponent } from './components/pr-video-player/pr-video-player.component';
import { RemoveAudioFromVisualData } from '../services/pipes/remove-audio-from-visual-data.pipe';
import { ManageBrandMediaLibrariesComponent } from '../components/myAccount/manage-brand/manage-brand-media-libraries/manage-brand-media-libraries.component';
import { MyAccountRoutingModule } from '../components/myAccount/my-account-routing.module';
import { VoiceDataStore } from '../stores/voice.store';
import { VoiceOverService } from './services/voice-over.service';
import { VoiceOverFilterSearchWizardComponent } from './components/voice-over-filter-search-wizard/voice-over-filter-search-wizard.component';
import { BrowseVoiceOverComponent } from './components/browse-voice-over/browse-voice-over.component';
import { VideoVoiceOverSelectionComponent } from './components/video-voice-over-selection/video-voice-over-selection.component';
import { MyAccountVoiceOverComponent } from '../components/myAccount/my-account-voice-over/my-account-voice-over.component';
import { VoiceOverSingleItemComponent } from './components/voice-over-single-item/voice-over-single-item.component';
import { TimelineViewSidepanelsComponent } from './timeline-view-sidepanels/timeline-view-sidepanels.component';
import { VideoSidepanelComponent } from './video-sidepanel/video-sidepanel.component';
import { BrandLogoListComponent } from './brand-logo-list/brand-logo-list.component';
import { SystemFontsDropdownComponent } from './components/system-fonts-dropdown/system-fonts-dropdown.component';
import { AddKeywordsComponent } from './components/add-keywords/add-keywords.component';
import { AccountSidebarComponent } from '../components/myAccount/account-sidebar/account-sidebar.component';
import { ResizeDragableItemComponent } from './components/resize-dragable-item/resize-dragable-item.component';
import { WebplayerComponent } from './webplayer/webplayer.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import { FormsModule } from '@angular/forms';
const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);
@NgModule({
    providers: [{ provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons }, { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}
    ],
    imports: [
        DatesPipeModule,
        CommonModule,
        BrowserModule,
        AlertModule,
        NgZorroAntdModule,
        // AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgApexchartsModule,
        NgxChartsModule,
        BrowserAnimationsModule,
        DragDropModule,
        NgWaveformModule,
        SlickCarouselModule,
        MatPaginatorModule,
        MatIconModule,
        MatDialogModule,
        MatTooltipModule,
        NzDrawerModule,
        MyAccountRoutingModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        MatOptionModule
        // TimestampToDate
    ],
    declarations: [
        CreatedVideosComponent,
        // AccountSidebarComponent,
        MediaLibrarySharedComponent,
        MediaLibraryPopupComponent,
        SplashscreenComponent,
        HeaderComponent,
        SuccessMessageComponent,
        SecondsToTime,
        PrVideoPlayerComponent,
        RemoveAudioFromVisualData,
        MediaLibrarySharedMyAccountComponent,
        // AccountSidebarComponent,
        VoiceOverFilterSearchWizardComponent,
        BrowseVoiceOverComponent,
        VideoVoiceOverSelectionComponent,
        VoiceOverSingleItemComponent,
        // MyAccountVoiceOverComponent
        LoaderComponent,
        TimelineViewSidepanelsComponent,
        VideoSidepanelComponent,
        BrandLogoListComponent,
        SystemFontsDropdownComponent,
        AddKeywordsComponent,
        ResizeDragableItemComponent,
        WebplayerComponent,
        // AccountSidebarComponent
        // TimestampToDate
    ],
    exports: [
        SuccessMessageComponent,
        CreatedVideosComponent,
        // AccountSidebarComponent,
        MediaLibrarySharedComponent,
        MediaLibraryPopupComponent,
        SplashscreenComponent,
        LoaderComponent,
        DatesPipeModule,
        // TimestampToDate,
        CommonModule,
        // BrowserModule,
        AlertModule,
        NgZorroAntdModule,
        // AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgApexchartsModule,
        NgxChartsModule,
        BrowserAnimationsModule,
        DragDropModule,
        NgWaveformModule,
        SlickCarouselModule,
        MatPaginatorModule,
        MatIconModule,
        MatDialogModule,
        MatTooltipModule,
        NzDrawerModule,
        HeaderComponent,
        SecondsToTime,
        PrVideoPlayerComponent,
        RemoveAudioFromVisualData,
        MediaLibrarySharedMyAccountComponent,
        // AccountSidebarComponent,
        VoiceOverFilterSearchWizardComponent,
        BrowseVoiceOverComponent,
        VideoVoiceOverSelectionComponent,
        VoiceOverSingleItemComponent,
        TimelineViewSidepanelsComponent,
        VideoSidepanelComponent,
        BrandLogoListComponent,
        SystemFontsDropdownComponent,
        AddKeywordsComponent,
        // AccountSidebarComponent
        // MyAccountVoiceOverComponent
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
        };
    }
}
