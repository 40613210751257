import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MediaManagerService } from '../../Managers/media-manager.service';
import { mediaFile, mediaLib, visual } from '../../models/media';
import { cDataService } from '../../services/data.service';
import { cSessionService } from '../../services/session.service';
import { cWebSocketService } from '../../services/websocket.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorService } from '../../services/error.service';
import { MyAccountService } from 'src/app/services/dataServices/my-account.service';
import { BrandService } from 'src/app/components/myAccount/manage-brand/service/BrandService';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadFileDialogComponent } from '../components/upload-file-dialog/upload-file-dialog.component';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { MediaService } from 'src/app/services/dataServices/media.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { VideoService } from 'src/app/services/dataServices/video.service';
@Component({
    selector: 'app-media-library-shared',
    templateUrl: './media-library-shared.component.html',
    styleUrls: ['./media-library-shared.component.scss'],
})
export class MediaLibrarySharedComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild("videoListAutoComplete", { read: MatAutocompleteTrigger })
    autoCompleteVideo: MatAutocompleteTrigger;
    @ViewChild('searchVideoField') searchVideoField: ElementRef;
    modelVideoSearchChanged = new BehaviorSubject<string>('');
    videoSearchList: any = [];
    isVideoSearchLoading: boolean = false;
    searchTermText = '';
    @ViewChild('myVideo') myVideo: ElementRef;
    @ViewChild('closeModal') closeModal: ElementRef;
    @ViewChild('uploadFileField', { static: false }) uploadFileField: ElementRef;

    slideConfig = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1500,
    };

    bDisplayMediaButtons: boolean = false;
    sAddActiveClass: any;
    lBreadcrumbOptions: any = [
        { name: 'All', filter: 'all' },
        { name: 'Videos', filter: 'clip' },
        { name: 'Audio', filter: 'audio' },
        { name: 'Images', filter: 'image' },
        // {name : 'Icons', filter : 'Icons'},
        // {name : 'Text Snippets', filter : 'Snippets'},
    ];
    selectedOption: any;
    public imageStaticThumb = '../assets/images/business_Profile/audio-line.png';
    sSessionId: string;
    sUserEmail: string;
    public commonTags = [];
    sResponseStatus: string;
    fUserVisualListForm: FormGroup;
    fUserVisualCountForm: FormGroup;
    lUserVisualsList: any = [];
    nUserVisualID: number = 0;
    nUserVisualsCount: number;
    nUserImagesCount: number;
    nUserClipsCount: number;
    nUserAudiosCount: number;
    public search: string = '';
    sVisualType: string = '';
    nAlreadyFetchedVisuals: number = 0;
    nPageSize: number = 20;
    limit: number = 18;
    offset: number = 0;
    lPagination: any = [];
    nCurrentPage: number = 1;
    lSelectedUserVisualsList: any = [];
    sSelectedVideoId: string;
    oSingleVisual: any;
    bDisplayAddInputField: boolean = false;
    inputValue: string = '';
    lKeyTerms: any = [];
    @Input() bSelectMultipleVisuals: boolean = false;
    oAudio = new Audio();
    currentKey;
    oSelectedImage: any = '';
    lSelectedKeyTermsTags: any = [];
    @ViewChild('AddInputTagElement') AddInputTagElement: ElementRef;
    @ViewChild('AddInputElement') AddInputElement: ElementRef;
    @ViewChild('AddInputElementClip') AddInputElementClip: ElementRef;
    lFilesToBeUploaded: any = [];
    nFilesToUploadCount: number = 0;
    @Output() lEmittedFilesToUpload = new EventEmitter<string[]>();
    sSelectedMediaLibrary: string;
    sUrl: any = [];
    bMediaLoader: boolean = false;
    @Input() sInputVisualPath: string;
    @Input() lInputVisualsToDelete: any = [];
    bDisplaySelect: boolean = false;
    bSelectListDisplay: boolean = false;
    selectedItemId = -1;
    selectedItemArray = [];
    isSpinning = false;
    multiselectmedia: boolean = false;
    selectmedia: boolean = false;
    uploadingMedia: boolean = false;
    isOwner = false;
    mediaLibId: string;
    sort_by: string;
    sortType = -1;
    mediaLibraryDetail: mediaLib = {
        name: '',
        lib_id: '',
        activation_status: '',
        total_items: 0,
        library_access: '',
        owner_type: '',
        owner: '',
    };
    copyPathclicked = false;
    selectListView = false;
    listview = false;
    isFetching = false;
    extractedClips: any;
    sSelectedSortingCategory: string = 'Date Created';
    sSelectedSortingCategoryValue: string = 'created';
    sSelectedGroup: string = 'Month Created';
    sSelectedGroupValue: string = 'month_created';
    lSortBy: any = [
        { name: 'Date Created', value: 'created' },
        { name: "Last Modified", value: "last_modified" },
        { name: 'Name', value: 'video.title' },
        // { name: 'Aspect Ratio', value: 'video.aspect_ratio' },
        // { name: 'FPS', value: 'video.fps' },
        // { name: 'Duration', value: 'video.duration' },
    ];
    lGroupBy: any = [
        { name: 'Month Created', value: 'month_created' },
        { name: 'Year Created', value: 'year_created' },
        { name: 'Month Modified', value: 'month_last_modified' },
        { name: 'Year Modified', value: 'year_last_modified' },
        // { name: 'Aspect Ratio', value: 'video.aspect_ratio' },
        // { name: 'Drafts', value: 'publish_status' },
        // { name: 'Published', value: 'publish_status' },
    ];
    constructor(
        private oRouter: Router,
        public mediaManager: MediaManagerService,
        private oDataService: cDataService,
        private oSessionService: cSessionService,
        public oFormBuilder: FormBuilder,
        private oWebSocketService: cWebSocketService,
        private sanitizer: DomSanitizer,
        public errorService: ErrorService,
        private myAccountService: MyAccountService,
        private brandService: BrandService,
        public accountSettingService: MyAccountService,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private prontoSetting: ProntoSettingService,
        private mediaService: MediaService,
    ) {
        if (
            this.oRouter.url === '/business-profile/business-plus/manage-libraries' ||
            this.oRouter.url === '/business-profile/franchesees/media-library' ||
            this.oRouter.url === '/business-profile/brands/media-library'
        ) {
            this.bDisplayMediaButtons = true;
        }
    }
    ngAfterViewInit(): void {
        console.log("chk trigger");

        this.modelVideoSearchChanged
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(async (value: any) => {
                if (value && value != "" && value.length > 1) {
                    console.log("chk trigger value", value);
                    this.videoSearchList = await this.mediaService
                        .visuals_search_ac(this.sUserEmail, value, this.sVisualType, [this.mediaManager.selectedmedialib.lib_id])
                        .then((resList) => {
                            return (this.videoSearchList = resList);
                        });

                    this.isVideoSearchLoading = false;
                    return this.videoSearchList;
                } else {
                    return [];
                }
            });
        // throw new Error('Method not implemented.');
    }

    setUpMediaUpload() {
        /*var media = new mediaFile();
   media.status = "UPLOADED";
   media.name = "File Name customer video";
   this.mediaManager.uploadMediaList.push(media);

   var media = new mediaFile();
   media.status = "UPLOADED";
   media.name = "File 2 customer video";
   this.mediaManager.uploadMediaList.push(media);

  var media = new mediaFile();
   media.status = "UPLOADING";
   media.name = "File Name customer video";
   this.mediaManager.uploadMediaList.push(media);

     var media = new mediaFile();
   media.status = "CANCEL";
   media.name = "File 3 customer video";
   this.mediaManager.uploadMediaList.push(media); */
    }
    ngOnInit(): void {
        this.mediaManager.selectedVisual = undefined;
        if (this.mediaManager?.selectedmedialib && this.mediaManager.selectedmedialib?.lib_id) {
            localStorage.setItem('selectedMediaLibrary', JSON.stringify(this.mediaManager?.selectedmedialib));
        } else {
            if (localStorage.getItem('selectedMediaLibrary')) {
                this.mediaManager.selectedmedialib = JSON.parse(localStorage.getItem('selectedMediaLibrary'));
            }
        }
        this.getMediaLibDetail();
        this.setUpMediaUpload();
        // this.MediaLibrarySharedComponent_InitializeJqueryCode();

        this.sSessionId = this.oSessionService.SessionService_GetSessionId();
        this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

        this.fUserVisualListForm = this.oFormBuilder.group({
            email: this.sUserEmail,
            session_id: this.sSessionId,
            num_skip: 0,
            num_items: 10,
            visual_type: '',
            media_lib: this.sSelectedMediaLibrary,
        });
        this.fUserVisualCountForm = this.oFormBuilder.group({
            email: this.sUserEmail,
            session_id: this.sSessionId,
            visual_type: '',
            media_lib: this.sSelectedMediaLibrary,
        });
        this.loadVisual();
        var self = this;
    }
    ngOnDestroy() {
        this.oAudio.pause();
        this.oAudio.currentTime = 0;
        this.myVideo?.nativeElement?.pause();
    }

    loadVisual(visualType: string = 'all') {
        this.sVisualType = visualType;
        this.mediaManager
            .getVisuals(
                this.sUserEmail,
                this.sSessionId,
                visualType,
                this.mediaManager.selectedmedialib.lib_id,
                this.nPageSize,
                this.offset,
                this.sSelectedSortingCategoryValue,
                this.sSelectedGroupValue,
                this.search,
                false,
                [],
                this.sortType,
            )
            .then((_) => {
                this.createPagination();
            });
    }

    searchMediaLibrary() {
        this.searchTermText = this.search;
        this.offset = 0;
        this.nCurrentPage = 1;
        this.mediaManager.visualsTotalCount = 0;
        this.loadVisual(this.sVisualType);
        // this.mediaManager
        //   .getVisuals(
        //     this.sUserEmail,
        //     this.sSessionId,
        //     this.sVisualType,
        //     this.mediaManager.selectedmedialib.lib_id,
        //     this.nPageSize,
        //     this.offset,
        //     this.search
        //   )
        //   .then((_) => {
        //     this.createPagination();
        //   });
    }

    loadPage(pageNumber: number, forceReload = false) {
        if (pageNumber !== this.nCurrentPage || forceReload) {
            this.nCurrentPage = pageNumber;
            let skipVideos = (pageNumber - 1) * this.nPageSize;
            this.offset = skipVideos;
            this.mediaManager
                .getVisuals(
                    this.sUserEmail,
                    this.sSessionId,
                    this.sVisualType,
                    this.mediaManager.selectedmedialib.lib_id,
                    this.nPageSize,
                    skipVideos,
                    this.sSelectedSortingCategoryValue,
                    this.sSelectedGroupValue,
                    this.search,
                    false,
                    [],
                    this.sortType
                )
                .then();
        }
    }

    MediaLibrarySharedComponent_DisplayMyMediaLibrary() {
        this.oWebSocketService.WebSocket_RegisterEvent(
            'visual_count_result',
            this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse,
        );
        this.MediaLibrarySharedComponent_GetUserImagesCount();
        this.MediaLibrarySharedComponent_GetUserClipsCount();
        this.MediaLibrarySharedComponent_GetUserAudioCount();
    }
    MediaLibrarySharedComponent_FetchPage(nIncommingPageNumber: number) {
        if (nIncommingPageNumber !== this.nCurrentPage) {
            this.MediaLibrarySharedComponent_ResetView();
            this.nCurrentPage = nIncommingPageNumber;
            let skipVideos = (nIncommingPageNumber - 1) * 18;
            if (this.sVisualType === 'clip') {
                this.MediaLibrarySharedComponent_GetUserClips(18, skipVideos);
            } else if (this.sVisualType === 'image') {
                this.MediaLibrarySharedComponent_GetUserImage(18, skipVideos);
            } else if (this.sVisualType === 'audio') {
                this.MediaLibrarySharedComponent_GetUserAudios(18, skipVideos);
            } else {
                this.nPageSize = 18 / this.nUserVisualsCount;
                // console.log("Total : ==> ", this.nPageSize);
                let nImages = Math.floor(this.nPageSize * this.nUserImagesCount);
                let nClips = Math.floor(this.nPageSize * this.nUserClipsCount);
                let nAudios = Math.floor(this.nPageSize * this.nUserAudiosCount);
                let imageSkip = (nIncommingPageNumber - 1) * nImages;
                let clipSkip = (nIncommingPageNumber - 1) * nClips;
                let audioSkip = (nIncommingPageNumber - 1) * nAudios;
                this.MediaLibrarySharedComponent_GetUserImage(nImages, imageSkip);
                this.MediaLibrarySharedComponent_GetUserClips(nClips, clipSkip);
                this.MediaLibrarySharedComponent_GetUserAudios(nAudios, audioSkip);
            }
        }
    }
    MediaLibrarySharedComponent_ResetView() {
        this.mediaManager.selectedVisual = null;
        this.mediaManager.selectedVisuals = [];
    }
    MediaLibrarySharedComponent_GetUserImage(nIemsToFetch: number, nItemsToSkip: number) {
        this.fUserVisualListForm.patchValue({
            visual_type: 'image',
            num_items: nIemsToFetch,
            num_skip: nItemsToSkip,
        });
        if (nIemsToFetch !== 0) {
            this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
        }
    }
    MediaLibrarySharedComponent_GetUserClips(nIemsToFetch: number, nItemsToSkip: number) {
        this.fUserVisualListForm.patchValue({
            visual_type: 'clip',
            num_items: nIemsToFetch,
            num_skip: nItemsToSkip,
        });
        if (nIemsToFetch !== 0) {
            this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
        }
    }
    MediaLibrarySharedComponent_GetUserAudios(nIemsToFetch: number, nItemsToSkip: number) {
        this.fUserVisualListForm.patchValue({
            visual_type: 'audio',
            num_items: nIemsToFetch,
            num_skip: nItemsToSkip,
        });
        if (nIemsToFetch !== 0) {
            this.oWebSocketService.WebSocket_SendMessage('user_visual_list_request', JSON.stringify(this.fUserVisualListForm.value));
        }
    }
    MediaLibrarySharedComponent_GetUserImagesCount() {
        this.fUserVisualCountForm.patchValue({ visual_type: 'image' });
        this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
    }
    MediaLibrarySharedComponent_GetUserClipsCount() {
        this.fUserVisualCountForm.patchValue({ visual_type: 'clip' });
        this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
    }
    MediaLibrarySharedComponent_GetUserAudioCount() {
        this.fUserVisualCountForm.patchValue({ visual_type: 'audio' });
        this.oWebSocketService.WebSocket_SendMessage('visual_count_request', JSON.stringify(this.fUserVisualCountForm.value));
    }
    MediaLibrarySharedComponent_SelectMediaType(oIncommingMediaCategory: any) {
        this.close();
        if (this.sVisualType !== oIncommingMediaCategory) {
            this.sVisualType = oIncommingMediaCategory;

            this.bSelectMultipleVisuals = false;
            this.MediaLibrarySharedComponent_ResetView();

            // this.loadPage(1, true);
            this.offset = 0;
            this.nCurrentPage = 1;
            this.mediaManager.visualsTotalCount = 0;
            this.loadVisual(this.sVisualType);
        }
    }
    MediaLibrarySharedComponent_PlayVideo(visual: visual) {
        visual.isSelected = !visual.isSelected;

        if (visual.visual_type === 'clip') {
            var video = <HTMLVideoElement>document.getElementById(visual.visual_path);
            var play_pause_status = 'pause';

            if (play_pause_status == 'pause') {
                video.play();
                console.log('Play');
            } else {
                video.pause();
                console.log('Pause');
            }
        }
    }

    MediaLibrarySharedComponent_PassSingleVisualToSidebar(oIncommingVisual: any) {
        oIncommingVisual.isSelected = !oIncommingVisual.isSelected;

        if (oIncommingVisual.isSelected) this.mediaManager.selectedVisual = oIncommingVisual;

        this.bDisplaySelect = true;
        document.getElementById('main-content').classList.add('main-area');
        document.getElementById('selectmedia').classList.add('active');
        this.selectListView = true;
        this.bSelectMultipleVisuals = false;
        this.oSingleVisual = oIncommingVisual;
        this.lKeyTerms = this.oSingleVisual.key_terms;
    }

    selectVisual(oIncommingVisual: visual) {
        this.selectmedia = true;
        this.resetTab();
        if (oIncommingVisual?.visual_type == 'clip') {
            this.fetchClips(oIncommingVisual);;
        }
        this.oAudio.pause();
        this.oAudio.currentTime = 0;
        this.myVideo?.nativeElement?.pause();
        this.copyPathclicked = false;
        console.log('Selected visual ', oIncommingVisual);

        document.getElementById('uploadingMedia').classList.remove('active');
        document.getElementById('media-view').classList.add('media-lib-resize');
        // console.log("---" + oIncommingVisual);
        oIncommingVisual.isSelected = !oIncommingVisual.isSelected;
        // console.log("---" + oIncommingVisual.isSelected);

        if (oIncommingVisual.isSelected) {
            this.mediaManager.selectedVisual = oIncommingVisual;
            this.mediaManager.selectedVisuals.push(oIncommingVisual);

            this.getCommonTag();
        } else {
            // console.log(oIncommingVisual.visual_path);
            this.mediaManager.selectedVisuals.forEach((ele: visual, index) => {
                if (ele.visual_path === oIncommingVisual.visual_path) {
                    // console.log(index);
                    // console.log(this.mediaManager.selectedVisuals);
                    this.mediaManager.selectedVisuals.splice(index, 1);
                    // console.log(this.mediaManager.selectedVisuals);
                }
            });
        }

        this.bDisplaySelect = true;
        document.getElementById('main-content').classList.add('main-area');
        document.getElementById('selectmedia').classList.add('active');
        this.selectListView = true;
    }

    MediaLibrarySharedComponent_SelectedImage(nIncomingIndex) {
        this.oSelectedImage = nIncomingIndex;
        if (this.oSelectedImage.visual_type == 'audio') {
            this.MediaLibrarySharedComponent_PlayAudio(nIncomingIndex);
        }
    }
    CreateVideoLooknFeelComponent_EnableSingleSelection() {
        this.bSelectMultipleVisuals = false;
    }

    removeTag(tag) {
        //this.lKeyTerms.splice(index , 1);
        if (this.bSelectMultipleVisuals) {
            this.mediaManager.selectedVisuals.forEach((visual) => {
                visual.key_terms.forEach((c, index) => {
                    if (c == tag) {
                        visual.key_terms.splice(index, 1);
                    }
                });

                this.updateVisual(visual);
            });
        } else {
            this.mediaManager.selectedVisual.key_terms.forEach((c, index) => {
                if (c == tag) {
                    this.mediaManager.selectedVisual.key_terms.splice(index, 1);
                }
            });
            this.updateVisual(this.mediaManager.selectedVisual);
        }
    }

    updateVisual(visual: visual) {
        this.mediaManager.updateVisual(this.sUserEmail, this.sSessionId, visual.visual_path, visual.key_terms);
    }

    CreateVideoLooknFeelComponent_RemoveTag(index, tag?) {
        //this.lKeyTerms.splice(index , 1);
        if (this.bSelectMultipleVisuals) {
            this.mediaManager.selectedVisuals.forEach((visual) => {
                visual.key_terms.forEach((element, index) => {
                    if (tag == element) visual.key_terms.splice(index, 1);
                });
                this.mediaManager.updateVisual(this.sUserEmail, this.sSessionId, visual.visual_path, visual.key_terms);
            });
        } else {
            this.mediaManager.selectedVisual.key_terms.splice(index, 1);
            this.mediaManager.updateVisual(
                this.sUserEmail,
                this.sSessionId,
                this.mediaManager.selectedVisual.visual_path,
                this.mediaManager.selectedVisual.key_terms,
            );
        }
    }
    CreateVideoLooknFeelComponent_FocusoutFromInput() {
        console.log(this.inputValue);
        if (this.inputValue !== '') {
            this.CreateVideoLooknFeelComponent_AddTag();
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);
    }
    CreateVideoLooknFeelComponent_AddTag() {
        this.bDisplayAddInputField = false;

        if (this.bSelectMultipleVisuals) {
            this.mediaManager.selectedVisuals.forEach((element) => {
                let isTagExist = false;
                element.key_terms.forEach((tag) => {
                    if (this.inputValue == tag) {
                        isTagExist = true;
                    }
                });

                if (!isTagExist) {
                    element.key_terms.push(this.inputValue);
                }
                this.mediaManager.updateVisual(this.sUserEmail, this.sSessionId, element.visual_path, element.key_terms);
            });
        } else {
            let isTagExist = false;
            this.mediaManager.selectedVisual.key_terms.forEach((tag) => {
                if (this.inputValue == tag) {
                    isTagExist = true;
                }
            });

            if (!isTagExist) {
                this.mediaManager.selectedVisual.key_terms.push(this.inputValue);
            }

            this.mediaManager.updateVisual(
                this.sUserEmail,
                this.sSessionId,
                this.mediaManager.selectedVisual.visual_path,
                this.mediaManager.selectedVisual.key_terms,
            );
        }
        this.getCommonTag();
        this.inputValue = '';
    }

    getCommonTag() {
        let data = [];

        this.mediaManager.selectedVisuals.forEach((elemnet) => {
            data.push(elemnet?.key_terms);
        });

        this.commonTags = data?.reduce((a, b) => a?.filter((c) => b?.includes(c)));
    }
    CreateVideoLooknFeelComponent_EnterKeyTermsTag(keycode) {
        if ((keycode == 13 && this.inputValue != '') || (keycode == 188 && this.inputValue != '')) {
            this.CreateVideoLooknFeelComponent_AddTag();
        }
    }
    CreateVideoLooknFeelComponent_GetInputValue(event) {
        this.inputValue = event.target.value;
        console.log('Input Value ==>', this.inputValue);
    }
    CreateVideoLooknFeelComponent_DisplayAddTagInput() {
        this.inputValue = '';
        setTimeout(() => this.AddInputTagElement.nativeElement.focus());
        this.bDisplayAddInputField = true;
    }
    CreateVideoLooknFeelComponent_DisplayAddTagMultiSelectedInput() {
        this.inputValue = '';
        setTimeout(() => this.AddInputElement.nativeElement.focus());
        this.bDisplayAddInputField = true;
    }
    BusinessProfileSidepanelsComponent_UpdateKeyterms(oIncommingVisual) {
        let oUpdateVisualPayload = {
            key_terms: this.lKeyTerms,
            email: this.sUserEmail,
            session_id: this.sSessionId,
            visual_id: oIncommingVisual.visual_path,
        };
        console.log('BusinessProfileSidepanelsComponent_UpdateKeyterms : Keyterms Request ==>', oUpdateVisualPayload);
        this.oWebSocketService.WebSocket_SendMessage('update_visual_request', JSON.stringify(oUpdateVisualPayload));
    }

    BusinessProfileSidepanelsComponent_HandleUserVisualsUpdateResponse = (IncomingMessage) => {
        if (IncomingMessage.errorMessage) {
            console.error('BusinessProfileSidepanelsComponent_HandleUserVisualsUpdateResponse : Error ==>', IncomingMessage.errorMessage);
        } else {
            let formatedIcomingMessage = JSON.parse(IncomingMessage);
            console.log('BusinessProfileSidepanelsComponent_HandleUserVisualsUpdateResponse : ==>', formatedIcomingMessage);
            this.lUserVisualsList.forEach((element) => {
                if (element.visual_path == formatedIcomingMessage.visual_id) {
                    element.key_terms = [...this.lKeyTerms];
                }
            });
            this.lSelectedUserVisualsList.forEach((element) => {
                if (element.visual_path == formatedIcomingMessage.visual_id) {
                    element.key_terms = [...this.lKeyTerms];
                }
            });
        }
    };

    getMediaLibraryUsers() {
        this.myAccountService.getMediaLibrayUsers(this.mediaManager.selectedmedialib.lib_id).then((users) => { });
    }
    MediaLibrarySharedComponent_DeleteVisual() {
        console.log(this.bSelectMultipleVisuals);
        if (this.bSelectMultipleVisuals) {
            this.mediaManager.deleteAllSelectedVisuals(this.sUserEmail, this.sSessionId);
            this.bSelectMultipleVisuals = false;
            this.loadVisual(this.sVisualType);

        } else {
            this.mediaManager.deleteVisual(this.sUserEmail, this.sSessionId, this.mediaManager.selectedVisual.visual_path).then((res) => {
                this.close();
                this.mediaManager.selectedVisual = undefined;
                this.loadVisual(this.sVisualType);
            });
        }
    }

    BusinessProfileSidepanelsComponent_ReturnFileSize(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    async MediaLibrarySharedComponent_GenerateDisplayList(oIncommingVisual: any) {
        const imageStaticThumb = '../assets/images/business_Profile/audio-line.png';
        this.nUserVisualID = this.nUserVisualID + 1;
        let visual_path = oIncommingVisual.visual_path;
        let filename = visual_path.substr(visual_path.indexOf('.com/') + 5, visual_path.length);
        let key_terms = oIncommingVisual.key_terms ? oIncommingVisual.key_terms : [];
        let visual_type = oIncommingVisual.visual_type;
        let thumbnail =
            !oIncommingVisual.thumb && visual_type !== 'audio'
                ? await this.oDataService.DataService_GetUserVisualThumbnail(visual_path)
                : oIncommingVisual.thumb;
        let url = await this.oDataService.DataService_GetSignedURLForDownload(visual_path);
        let size = oIncommingVisual.size;
        let duration = oIncommingVisual.duration;
        let library = oIncommingVisual.media_lib;
        let used_in = oIncommingVisual.videos_used_in ? oIncommingVisual.videos_used_in : [];
        let sThumbnail: string;
        let checked: boolean = false;

        if (visual_type == 'audio') {
            sThumbnail = imageStaticThumb;
        } else {
            sThumbnail = await this.oDataService.DataService_GetSignedURLForDownload(thumbnail);
        }
        //if user do Bulk upload then populate data accordingly
        if (this.sVisualType === 'audio' && visual_type === 'audio') {
            this.lUserVisualsList.unshift({
                id: this.nUserVisualID,
                thumb: sThumbnail,
                url: url,
                visual_path: visual_path,
                key_terms: key_terms,
                visual_type: visual_type,
                name: filename,
                size: size,
                library: library,
                used_in: used_in,
                checked: checked,
                duration: duration,
            });
        } else if (this.sVisualType === 'image' && visual_type === 'image') {
            this.lUserVisualsList.unshift({
                id: this.nUserVisualID,
                thumb: sThumbnail,
                url: url,
                visual_path: visual_path,
                key_terms: key_terms,
                visual_type: visual_type,
                name: filename,
                size: size,
                library: library,
                used_in: used_in,
                checked: checked,
                duration: duration,
            });
        } else if (this.sVisualType === 'clip' && visual_type === 'clip') {
            this.lUserVisualsList.unshift({
                id: this.nUserVisualID,
                thumb: sThumbnail,
                url: url,
                visual_path: visual_path,
                key_terms: key_terms,
                visual_type: visual_type,
                name: filename,
                size: size,
                library: library,
                used_in: used_in,
                checked: checked,
                duration: duration,
            });
        } else if (this.sVisualType === 'all') {
            this.lUserVisualsList.unshift({
                id: this.nUserVisualID,
                thumb: sThumbnail,
                url: url,
                visual_path: visual_path,
                key_terms: key_terms,
                visual_type: visual_type,
                name: filename,
                size: size,
                library: library,
                used_in: used_in,
                checked: checked,
                duration: duration,
            });
        }
        // console.log('List to display :', this.lUserVisualsList);
    }
    MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse = (IncomingMessage) => {
        if (IncomingMessage.errorMessage) {
            console.error(
                'MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Error ==> ',
                JSON.parse(IncomingMessage.errorMessage),
            );
        } else {
            let formatedIcomingMessage = JSON.parse(IncomingMessage);
            console.log('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : ', formatedIcomingMessage);
            if (formatedIcomingMessage.visual_type == 'image') {
                this.nUserImagesCount = formatedIcomingMessage.count;
            } else if (formatedIcomingMessage.visual_type == 'clip') {
                this.nUserClipsCount = formatedIcomingMessage.count;
            } else if (formatedIcomingMessage.visual_type == 'audio') {
                this.nUserAudiosCount = formatedIcomingMessage.count;
            }
            this.nUserVisualsCount = this.nUserImagesCount + this.nUserClipsCount + this.nUserAudiosCount;
            console.log('MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse : Total visuals count ==> ', this.nUserVisualsCount);
            if (this.nUserVisualsCount) {
                this.MediaLibrarySharedComponent_SelectMediaType('all');
                this.oWebSocketService.WebSocket_UnRegisterEvent(
                    'visual_count_result',
                    this.MediaLibrarySharedComponent_HandleNumberOfUserVisualsResponse,
                );
            }
        }
    };
    createPagination() {
        // console.log(this.mediaManager.visualsCount);
        this.lPagination = [];
        let pages = Math.ceil(this.mediaManager.visualsCount / this.nPageSize);
        for (let nPaginationIndex = 1; nPaginationIndex <= pages; nPaginationIndex++) {
            this.lPagination.push(nPaginationIndex);
        }
        // console.log("Pagination List : ==> ",this.lPagination);
    }
    MediaLibrarySharedComponent_PlayAudio(key) {
        if (this.currentKey !== key) {
            this.oAudio.src = key;
            this.currentKey = key;
        }
        if (this.oAudio.paused) {
            this.oAudio.play();
        } else {
            this.oAudio.pause();
            this.oAudio.currentTime = 0;
        }
    }
    MediaLibrarySharedComponent_StopAudio(filepath) {
        this.myVideo?.nativeElement?.pause();
        let filename = filepath?.replace(/^.*(\\|\/|\:)/, '');
        if (this.oAudio?.src?.replace(/^.*(\\|\/|\:)/, '') == filename) {
            console.log('Audio Paused');
            this.oAudio?.pause();
            this.oAudio = new Audio();
            this.oAudio.src = filepath;
            this.oAudio.load();
        }
        this.bSelectMultipleVisuals = false;
    }
    MediaLibrarySharedComponent_CloseSidebar() {
        this.bSelectMultipleVisuals = false;

        document.getElementById('multiselectmedia').classList.remove('active');
        this.lSelectedUserVisualsList.forEach((element) => {
            if (element.checked) {
                element.checked = false;
            }
        });
        console.log('After clicking the Close Button', this.lSelectedUserVisualsList);
        this.lSelectedUserVisualsList = [];
        this.oSelectedImage = '';
        this.lKeyTerms = [];
    }
    clearUploadField() {
        this.uploadFileField.nativeElement.value = '';
        console.log('Clear filed..');
    }

    openFileUploadModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '100wv';
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'pronto-dialog';
        dialogConfig.closeOnNavigation = true;

        dialogConfig.data = {
            uploadFor: 'all',
            multipleUpload: true,
            mediaLibraryId: this.mediaManager.selectedmedialib.lib_id,
            // showUploadingPopUP : true,
            // accept: "video/mp4, video/avi",
            dropBoxText: '',
            heading: 'Upload Media Files',
        };
        this.mediaManager.uploadMediaList = [];
        let dialogModal = this.dialog.open(UploadFileDialogComponent, dialogConfig);

        dialogModal.afterClosed().subscribe((data: any) => {
            if (data?.status == 'success') {
                this.openLoading();
                // this.isUploadVideo = true;
                // this.uploadedVdeo = {
                //   filename: uploadedFiles.name,
                //   fpath: uploadedFiles.visual_path,
                //   duration: uploadedFiles.duration.toFixed(2),
                //   thumb: uploadedFiles.thumbnail,
                //   url: uploadedFiles.url,
                //   size: uploadedFiles.size,
                // };

                // this.isUploadVideo = true;

                this.cd.detectChanges();
            } else {
            }
        });
    }

    MediaLibrarySharedComponent_UploadFiles(event: any) {
        console.log('file ', event);
        let lUserSelectedFiles = event.target.files;

        console.log(event);
        for (let nFileToReadIndex = 0; nFileToReadIndex < lUserSelectedFiles.length; nFileToReadIndex++) {
            var isfound = this.mediaManager.uploadMediaList.filter((c) => c.name == lUserSelectedFiles[nFileToReadIndex].name)[0];

            if (
                !isfound &&
                (lUserSelectedFiles[nFileToReadIndex].type === 'image/jpg' ||
                    lUserSelectedFiles[nFileToReadIndex].type === 'image/png' ||
                    lUserSelectedFiles[nFileToReadIndex].type === 'image/jpeg' ||
                    lUserSelectedFiles[nFileToReadIndex].type === 'audio/mp3' ||
                    lUserSelectedFiles[nFileToReadIndex].type === 'audio/mpeg' ||
                    lUserSelectedFiles[nFileToReadIndex].type === 'video/mp4')
            ) {
                let mf = new mediaFile();

                mf.name = lUserSelectedFiles[nFileToReadIndex].name;
                mf.file = lUserSelectedFiles[nFileToReadIndex];
                mf.status = undefined;
                mf.uri = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(mf.file));
                this.mediaManager.uploadMediaList.push(mf);
                console.log('media list', this.mediaManager.uploadMediaList);
            } else {
                this.clearUploadField();
                if (isfound) {
                    this.errorService.handleError('Duplicate file found.', 'File Upload faile.', true);
                } else {
                    this.errorService.handleError('Incorrect media format or type not supported', 'Unsupported Media ', true);
                }
            }
        }

        if (this.mediaManager.uploadMediaList.length > 0) {
            this.mediaManager.uploadMedia(this.sUserEmail, this.sSessionId);
            this.clearUploadField();
            this.openLoading();
        }
        setTimeout(() => {
            this.closeModal.nativeElement.click();
        }, 2000);
    }
    ngOnChanges() {
        if (this.sInputVisualPath) {
            console.log('Incomming Visual path : NgOnChanges ==> ', this.sInputVisualPath);
            this.sInputVisualPath = '';
        }

        if (this.lInputVisualsToDelete.length > 0) {
            console.log('Incomming Visual To delete list : NgOnChanges ==> ', this.lInputVisualsToDelete);
            for (let nVisualTodeleteIndex = 0; nVisualTodeleteIndex < this.lInputVisualsToDelete.length; nVisualTodeleteIndex++) {
                const sFileName = this.lInputVisualsToDelete[nVisualTodeleteIndex].name.replace(/ /g, '_');
                const oVisualToDelete = this.lUserVisualsList.find(({ name }) => name === sFileName);
                if (oVisualToDelete) {
                }
            }
            this.lInputVisualsToDelete = [];
        }
    }

    MediaLibrarySharedComponent_PlayVideoPosterButton(visual: visual) {
        visual.isSelected = !visual.isSelected;
    }
    deleteSelectedUploadingMedia(media) {
        for (let index = 0; index < this.mediaManager.uploadMediaList.length; index++) {
            const element = this.mediaManager.uploadMediaList[index];
            if (element.name == media.name) {
                console.log('Deleted Index = ', index);
                this.mediaManager.uploadMediaList.splice(index, 1);
            }
        }
    }

    getFileByPath(media) {
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(media));
    }
    openslect() {
        document.getElementById('main-content').classList.remove('main-area');
        document.getElementById('selectmedia').classList.remove('active');
        document.getElementById('multiselectmedia').classList.remove('active');
        document.getElementById('uploadingMedia').classList.remove('active');
        this.selectListView = false;

        document.getElementById('media-view').classList.remove('media-lib-resize');
        this.bDisplaySelect = false;
        this.multiselectmedia = false;
        this.selectmedia = false;
        this.uploadingMedia = false;

        this.selectedItemId = -1;
        this.bSelectMultipleVisuals = false;
        this.lSelectedUserVisualsList = [];

    }
    SelectListDisplay() {
        console.log('SelectListDisplay');
    }
    openMultiSelectMedia() {
        document.getElementById('main-content').classList.add('main-area');
        document.getElementById('multiselectmedia').classList.add('active');
        document.getElementById('selectmedia').classList.remove('active');
        document.getElementById('uploadingMedia').classList.remove('active');
        document.getElementById('media-view').classList.add('media-lib-resize');
        this.bDisplaySelect = true;
        this.multiselectmedia = true;
        this.uploadingMedia = false;
        this.selectmedia = false;
        this.bSelectMultipleVisuals = true;
        this.mediaManager.visuals.map((c) => (c.isSelected = false));
        this.mediaManager.selectedVisuals = [];
        this.copyPathclicked = false;
        this.selectListView = true;
    }

    openLoading() {
        document.getElementById('main-content').classList.add('main-area');
        document.getElementById('uploadingMedia').classList.add('active');
        document.getElementById('multiselectmedia').classList.remove('active');
        document.getElementById('selectmedia').classList.remove('active');
        document.getElementById('media-view').classList.add('media-lib-resize');
        this.bDisplaySelect = true;
        this.multiselectmedia = false;
        this.uploadingMedia = true;
        this.selectmedia = false;
        this.bSelectMultipleVisuals = true;
        this.mediaManager.selectedVisuals = [];
        this.copyPathclicked = false;
        this.selectListView = true;
    }

    close() {
        document.getElementById('main-content').classList.remove('main-area');
        document.getElementById('selectmedia').classList.remove('active');
        document.getElementById('uploadingMedia').classList.remove('active');
        document.getElementById('multiselectmedia').classList.remove('active');
        this.copyPathclicked = false;
        document.getElementById('media-view').classList.remove('media-lib-resize');
        this.bDisplaySelect = false;
        this.multiselectmedia = false;
        this.selectmedia = false;
        this.uploadingMedia = false;

        this.selectedItemId = -1;
        this.selectedItemArray = [];

        this.oAudio.pause();
        this.oAudio.currentTime = 0;
        this.myVideo?.nativeElement?.pause();
        this.selectListView = false;
    }

    isActiveMultiSelection(visual: visual) {
        return this.mediaManager.selectedVisuals.filter((o1) => o1.visual_path === visual.visual_path).length > 0;
    }

    copyPath(path) {
        // Copy the text inside the text field
        navigator.clipboard.writeText(path);
        this.copyPathclicked = true;
    }
    getMediaLibDetail() {
        this.mediaLibId = this.mediaManager.selectedmedialib.lib_id;
        let __this = this;
        this.brandService.getMediaLibraryProfile(this.mediaLibId).then((mediaLibData: any) => {
            __this.mediaLibraryDetail = mediaLibData;
            if (__this.mediaLibraryDetail.owner == this.sUserEmail || __this.mediaLibraryDetail.owner_type == 'admin') {
                this.isOwner = true;
                __this.mediaLibraryDetail.name;
            }
        });
    }
    loadPageEvent($event) {
        if ($event.pageSize != this.nPageSize) {
            this.nPageSize = $event.pageSize;
            this.loadPage(1, true);
        } else {
            this.loadPage($event.pageIndex + 1);
        }
    }
    clearSearchParams() {
        this.offset = 0;
        this.nCurrentPage = 1;
        this.mediaManager.visualsTotalCount = 0;
    }
    sortBySearch(sortingValue: any) {
        if (this.sSelectedSortingCategory != sortingValue.name) {
            this.sSelectedSortingCategory = sortingValue.name;
            this.sSelectedSortingCategoryValue = sortingValue.value;
            this.clearSearchParams();
            this.loadVisual(this.sVisualType);
        }
    }
    groupBySearch(groupingValue: any) {
        if (this.sSelectedGroup != groupingValue.name) {
            this.sSelectedGroup = groupingValue.name;
            this.sSelectedGroupValue = groupingValue.value;
            this.clearSearchParams();
            this.loadVisual(this.sVisualType);
        }
    }
    returnFileName(filename) {
        var split = filename?.split('.');
        var filename = split[0];
        var extension = split[1];
        if (filename.length > 15) {
            filename = filename.substring(0, 14);
        }
        var result = filename + '.' + extension;
        return result;
    }

    downloadCSV() {
        let loop = 0;
        let data = [['No', 'Path']];
        this.mediaManager.selectedVisuals.map((item) => {
            data.push([++loop + '', item.fpath]);
        });
        console.log(data);
        this.prontoSetting.downloadCSV(data);
    }
    sortTypeSearch(val: number) {
        this.clearSearchParams();
        this.sortType = val;
        this.loadVisual(this.sVisualType);
    }
    gridView() {
        document.getElementById('gridView')?.classList?.add('active');
        document.getElementById('listView')?.classList?.remove('active');
        this.listview = false;
    }
    listView() {
        document.getElementById('listView')?.classList?.add('active');
        document.getElementById('gridView')?.classList?.remove('active');
        this.listview = true;
    }
    activeTab: string = 'tab3';
    onTabSelect(tab: string) {
        this.activeTab = tab;
    }

    getTabImageFilter(tab: string): string {
        if (tab === 'tab3' && this.activeTab === 'tab3') {
            return 'invert(0)';
        }
        return 'grayscale(100%)';
    }

    fetchClips(oIncommingVisual) {
        if (this.isFetching) {
            return;
        }
        this.isFetching = true;
        let svideoId = oIncommingVisual.fpath;
        console.log("Video ID : ", svideoId);

        this.mediaService.getExtractedClips(svideoId).subscribe(
            (data) => {
                console.log('Response extracted clips:', data);
                this.isFetching = false;
                this.extractedClips = data.result;

                if (this.extractedClips.length > 0) {
                    this.extractedClips[0].active = true;
                }
            },
            (error) => {
                console.error('Error fetching clips:', error);
                this.isFetching = false;
            }
        );
    }
    onPanelChange(extractedClip: any, groupIndex: number): void {
        console.log('Panel clicked:', extractedClip, groupIndex);

        this.extractedClips.forEach((clip, index) => {
            if (index === groupIndex) {
                this.extractedClips[index].active = true;
            }
            else {
                this.extractedClips[index].active = false;
            }
        });
    }
    removeClipTag(tag, gi) {
        this.extractedClips[gi].key_terms.forEach((c, index) => {
            if (c == tag) {
                this.extractedClips[gi].key_terms.splice(index, 1);
            }
        });
        this.mediaManager.updateVisual(this.sUserEmail, this.sSessionId, this.extractedClips[gi]._id, this.extractedClips[gi].key_terms);
    }

    clipTag_FocusoutFromInput() {
        console.log(this.inputValue);
        if (this.inputValue !== '') {
            this.addCipTag();
        }
        setTimeout(() => {
            this.bDisplayAddInputField = false;
        }, 100);
    }
    addCipTag() {
        let gi: any
        this.extractedClips.map((clip, index) => {
            if (clip.active == true) {
                gi = index;
                console.log("chk group index", clip);
                clip?.key_terms.map((tag) => {
                    if (this.inputValue == tag) {
                        isTagExist = true;
                    }
                });

            }
        })
        this.bDisplayAddInputField = false;
        let isTagExist = false;

        if (!isTagExist) {
            this.extractedClips[gi].key_terms.push(this.inputValue);
        }
        this.mediaManager.updateVisual(this.sUserEmail, this.sSessionId, this.extractedClips[gi]._id, this.extractedClips[gi].key_terms);

        // this.getCommonTag();
        this.inputValue = '';
    }

    EnterClipTag(keycode) {
        if ((keycode == 13 && this.inputValue != '') || (keycode == 188 && this.inputValue != '')) {
            this.addCipTag();
        }
    }
    DisplayAddClipTagInput() {
        this.inputValue = '';
        setTimeout(() => this.AddInputElementClip.nativeElement.focus());
        this.bDisplayAddInputField = true;
    }
    deleteClip() {
        if (this.tempGI != '') {
            this.mediaManager.deleteVisual(this.sUserEmail, this.sSessionId, this.extractedClips[this.tempGI]._id).then((res) => {

                this.extractedClips.splice(this.tempGI, 1);
                this.tempGI = '';
                this.deleteClipPopup = false;
                // this.close();
                // this.mediaManager.selectedVisual = undefined;
                // this.loadVisual(this.sVisualType);
            });
        }
    }
    // CreatedVideosComponent_PlayVideo(videoItem) {
    //     videoItem.show_player = true;
    //     this.sSelectedVideoId = videoItem.fpath;
    //     let __this = this;
    //     setTimeout(() => {
    //     __this.videoManager.videos.forEach((element) => {
    //     var video = <HTMLVideoElement>document.getElementById(element.fpath);
    //     if (element.fpath == __this.sSelectedVideoId) {
    //         video.play();
    //     }
    //     });
    //     }, 100);

    //     }

    clearSearchTerm() {
        this.searchTermText = '';
        this.search = '';
        this.offset = 0;
        this.nCurrentPage = 1;
        this.mediaManager.visualsTotalCount = 0;
        this.loadVisual(this.sVisualType);
    }
    floatLabel(): 'auto' | 'always' | 'never' {
        // You can conditionally return 'auto', 'always', or 'never' here
        return 'auto';  // Default: it floats when the input has focus or value
    }

    deleteClipPopup = false;
    tempGI = '';
    deleteClipTrigger(gi) {
        this.tempGI = gi;
        this.deleteClipPopup = true;
    }
    resetTab() {

        document.getElementById('tab3').classList.add('active');
        document.getElementById('tab3').classList.add('show');
        document.getElementById('tab3-tab').classList.add('active');
        this.getTabImageFilter('tab3');
        this.activeTab = 'tab3';
        const tab2Element = document.getElementById('tab2');
        const tab1Element = document.getElementById('tab1');

        if (tab2Element && tab2Element.classList.contains('active')) {
            tab2Element.classList.remove('active');
        }

        if (tab1Element && tab1Element.classList.contains('active')) {
            tab1Element.classList.remove('active');
        }
        const tab2Head = document.getElementById('tab2-tab');
        const tab1Head = document.getElementById('tab1-tab');

        if (tab2Head && tab2Head.classList.contains('active')) {
            tab2Head.classList.remove('active');
        }

        if (tab1Head && tab1Head.classList.contains('active')) {
            tab1Head.classList.remove('active');
        }
    }
}

