<video #videoValidator height="100" width="100" controls style="opacity: 0.2; z-index:-1; position: absolute">

</video>
<div class="h-85vh br12 curve-bg tab-content bg-white page-shadow  m-0 p-0" id="pills-tabContent">
    <div class="tab-pane fade show active">
        <div class="content_area p-0">
            <div class="row m-0">

                <div class="col-sm-12 col-md-12 col-lg-7  h-85vh ps-5 pt-4">
                    <div class="row ps-4 pt-3 mt-1">
                        <p class="text-lg text-gray-3 primaryFont"><span class="counts">1. </span>Upload your video with
                            audio to get started</p>
                        <div>
                            <!-- <button class="btn btn-md btn-secondary btn-round-6" (click)="openFileUploadModal()" >
                                Upload a File New
                            </button>  -->
                            <button class="btn btn-md btn-secondary btn-round-6" (click)="openFileUploadModal()">
                                <img src="../../../../assets/images/svg/upload.svg">

                                &nbsp; &nbsp; Upload a File
                            </button>

                            <button class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 ms-3"
                                (click)="showMediaModal()">
                                <img src="../../../../assets/images/svg/mediaLibrary.svg">
                                &nbsp; &nbsp; Select From Media Library
                            </button>
                        </div>
                    </div>


                    <div class="row ps-4 pt-3 mt-1 pe-5">
                        <div class=" d-flex" *ngIf="this.uploadedVdeo">
                            <div style="    height: 170px;
                            width: 275px;" class="video_wrapper video_wrapper_full js-videoWrapper p-0">


                                <video controls controlsList="nodownload noplaybackrate"
                                    class="videoIframe js-videoIframe"
                                    style="min-width:280px; min-height:157px; object-fit: fill;" [muted]="true"
                                    [src]="this.uploadedVdeo?.url" [id]="this.uploadedVdeo?.fpath"></video>
                                <figure class="video-baner">
                                    <img style="min-width: 280px; min-height: 191px"
                                        [src]="(this.uploadedVdeo?.thumb) ? this.uploadedVdeo?.thumb : this.uploadedVdeo?.thumbnail"
                                        alt="video1" />
                                    <span class="video-duration">00:{{ this.uploadedVdeo?.sap_video_duration }}</span>
                                </figure>
                                <button class="videoPoster js-videoPoster"
                                    (click)="CreatedVideosComponent_PlayVideo(this.uploadedVdeo?.fpath)"></button>




                            </div>

                            <div class="ps-3">
                                <p class="text-lg text-gray-2" style="cursor:pointer;">{{returnFilename(this.uploadedVdeo)}}
                                </p>
                                <p *ngIf="this.uploadedVdeo?.duration" class="video-duration py-1">
                                    {{this.uploadedVdeo?.duration}}</p>
                            </div>

                        </div>
                        <span *ngIf="this.isUploadVideo != false && !this.uploadedVdeo" class="error ms-3">Please upload
                            or select a file</span>
                    </div>


                    <form [formGroup]="fOverviewForm">
                        <div class="col-lg-12 ps-4" *ngIf="countForlouderBrand == 1">
                            <div class="formItm static_lable order_list">
                                <label for=""><span class="counts">{{ 2}}.</span> Select a frame rate from the options or enter a custom FPS</label>
                                <div class="radio_select_wrapp">
                                    <label class="radio_select" [ngClass]="{'d-none':  !fps.isEnabled}"
                                        *ngFor="let fps of this.videoStore.lVideoFps; index as i">
                                        <input type="radio" name="fps" [value]="fps.value" [checked]="fps.checked"
                                            class="radio_input_element" formControlName="fps"/>
                                        
                                        <div class="radio_caption" [ngClass]="{'active-fps': isClassAdded && i == 6}">
                                            <span>{{fps.name !== '' ? fps.name + ' FPS' : "Enter Custom FPS"}}</span>
                                        </div>


                                    </label>
                                    <input class="custom-fps-input" [ngClass]="{'d-none':  !enableCustomFpsInput}" type="number" min="0" step="any" 
                                    #fpsInput 
                                    (keydown)="updateCustomFPSValue($event)" >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 ps-4 pt-5 pe-5">
                            <div class="formItm static_lable order_list pt-0">
                                <label for="new-video"><span class="counts">{{countForlouderBrand + 2}}.</span>Name your video?</label><br>
                                <input id="new-video" type="text" placeholder="" name="textfeild" style="max-width: 440px;"
                                    formControlName="title" required>

                                <span
                                    *ngIf="fOverviewForm?.controls['title']?.hasError('required') && fOverviewForm?.controls['title']?.touched"
                                    class="error ms-3">This field is required</span>
                            </div>
                        </div>



                    </form>

                </div>

            </div>
        </div>
    </div>
    <div class="wtermarklogo">
        <img src="../assets/images/logo-bottom.svg" alt="logo-mobile" width="120">
    </div>
</div>


<!-- Media Library Modal pop-up Start-->
<nz-modal [(nzVisible)]="visible" nzCentered [nzWidth]="850" [nzFooter]=null [nzClosable]=true
    (nzOnCancel)="closeMediaModal()">
    <app-media-library-popup *ngIf="visible" [videoOnly]="true" (lEmittedFilesToUpload)="mediaSelected($event)"
        [mediaPopUpLoc]="'mediaPopUpLoc'" [isSingleSelected]="true"></app-media-library-popup>
</nz-modal>
<!-- Media Library Modal pop-up End-->


<!-- Uploadmodal pop-up Start-->
<!-- <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    style="z-index: 10000;">
    <div class="modal-dialog modal-dialog-centered modal-lg drag-dropfile">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload a Videoaaaaaa</h5>
                <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group files">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-device" role="tabpanel"
                            aria-labelledby="pills-device-tab">

                            <div class="file-drop-area">
                                <figure>
                                    <svg width="23" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M8.47616 9.28091C8.47609 9.55343 8.25483 9.77429 7.98195 9.77421C7.70907 9.77412 7.48792 9.55313 7.48799 9.2806L7.48988 1.91053L4.38487 5.32164C4.20131 5.52329 3.90371 5.52329 3.72015 5.32164C3.5366 5.11999 3.5366 4.79304 3.72015 4.59139L7.65835 0.264948C7.84191 0.0632953 8.13951 0.0632953 8.32307 0.264948L12.2613 4.59139C12.4448 4.79304 12.4448 5.11999 12.2613 5.32164C12.0777 5.52329 11.7801 5.52329 11.5965 5.32164L8.47805 1.89571L8.47616 9.28091ZM0.59826 6.72675C0.871136 6.72675 1.09235 6.94767 1.09235 7.2202V8.45374C1.09235 11.9966 3.96807 14.8687 7.51545 14.8687H8.50559C12.053 14.8687 14.9287 11.9966 14.9287 8.45374V7.2202C14.9287 6.94767 15.1499 6.72675 15.4228 6.72675C15.6957 6.72675 15.9169 6.94767 15.9169 7.2202V8.45374C15.9169 12.5417 12.5987 15.8556 8.50559 15.8556H7.51545C3.42232 15.8556 0.104176 12.5417 0.104176 8.45374V7.2202C0.104176 6.94767 0.325385 6.72675 0.59826 6.72675Z"
                                                fill="#7A7A7A" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="16.0013" height="15.9809" fill="white"
                                                    transform="translate(0 0.00958252)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </figure>
                                <span class="choose-file-button">Upload a File (Device)</span>
                                <span class="text-line">Click to <font class="text-primary-3">browse</font>, or<br>
                                    drag & drop your file here</span>
                                <input #file class="file-input" type="file" accept="video/mp4, video/avi"
                                    (change)="UploadVideo($event)">
                            </div>
                        </div>
                        <ul class="d-flex mb-3" id="pills-tab" role="tablist">
                            <li class="col" role="presentation">
                                <button (click)="file.click()" id="pills-device-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-device" type="button" role="tab" aria-controls="pills-device"
                                    aria-selected="true">
                                    <img src="../assets/images/business_Profile/device.png" alt="tab-icon">
                                    <span>My Device</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Uploadmodal pop-up End-->

<!-- error modal -->
<div *ngIf="this.errorModal" class="modal fade" id="error-popup" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog width-483 modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 class="mb-4" *ngIf="!bAuthorizationError">{{sDisplayResponseErrorMessage}}</h5>
                <h5 class="mb-4" *ngIf="bAuthorizationError">Authorization Error</h5>
                <div class="alert alert-danger p-3 mb-4 g-0 row rounded-1">
                    <div class="col-1 p-0">
                        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path
                                    d="M13.9993 3.47998C6.92399 3.47998 1.16602 9.20176 1.16602 16.2326C1.16602 23.2635 6.92399 29 13.9993 29C21.0747 29 26.8327 23.2782 26.8327 16.2473C26.8327 9.21646 21.0747 3.47998 13.9993 3.47998ZM15.1095 15.7619C15.1095 16.365 14.6062 16.8651 13.9993 16.8651C13.3925 16.8651 12.8892 16.365 12.8892 15.7619V8.40747C12.8892 7.80441 13.3925 7.3043 13.9993 7.3043C14.6062 7.3043 15.1095 7.80441 15.1095 8.40747V15.7619Z"
                                    fill="#FF8369"></path>
                                <path
                                    d="M17.7366 2.32H10.2647C9.74765 2.32 9.33398 1.808 9.33398 1.168C9.33398 0.528 9.74765 0 10.2647 0H17.7366C18.2537 0 18.6673 0.512 18.6673 1.152C18.6673 1.792 18.2537 2.32 17.7366 2.32Z"
                                    fill="#FF8369"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="28" height="29" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>


                    </div>
                    <div class="col-11 p-0">

                        <p class="text-sm text-gray-3 mb-0 ms-2">{{sDisplayResponseErrorMessage}}</p>
                    </div>
                </div>

                <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal"
                    routerLink="/dashboard/myvideos">Go Back</a>

            </div>
        </div>
    </div>
</div>
<!-- error modal end -->


<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isModalVisible" nzCentered [nzWidth]="566" [nzFooter]=null [nzClosable]="false"
    [nzKeyboard]="false">

    <div class="modal-dialog modal-dialog-centered" style="    padding-bottom: 0px;  margin-bottom: 0px;">
        <div class="modal-content">

            <div class="modal-body">
                <h5>Video Uploading</h5>
                <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">Your video is uploading, Please wait.
                </p>
            </div>
        </div>
    </div>
</nz-modal>